import styled, { css } from "styled-components"
import { Flex } from "utils/components/style/flex"

export const StyledImage = styled.img<{ $selected: boolean }>`
    width: 45px;
    height: 45px;
    padding: 0.25rem;
    border-radius: 0.5rem;
    margin: 0.25rem;
    ${({ $selected }) =>
			$selected &&
			css`
            border: 2px solid var(--primary500);
        `}
    &:hover {
        border: 2px solid var(--primary500);
        padding: calc(0.25rem - 2px);
        cursor: pointer;
        transform: scale(1.05);
    }
`
export const SFlex = styled(Flex)`
    max-width: 600px;
    max-height: 600px;
    overflow: auto;
`
