import AddCircleOutline from "@mui/icons-material/AddCircleOutline"
import ClearIcon from "@mui/icons-material/Clear"
import { IconButton, TextField } from "@mui/material"
import React from "react"
import { DragDropContext } from "react-beautiful-dnd"
import { useController, useFormContext, useWatch } from "react-hook-form"
import styled from "styled-components"
import { Flex, FlexHeader } from "utils/components/style/flex"
import { H4 } from "utils/components/style/header"
import SuperDraggable from "utils/dnd/SuperDraggable"
import SuperDragHandle from "utils/dnd/SuperDragHandle"
import SuperDroppable from "utils/dnd/SuperDroppable"

const SFlex = styled(Flex)`
    width: 100%;
    margin-bottom: 0.5rem;
`

const DroppableComponent = styled(Flex)`
    margin-top: -0.5rem !important ;
    width: 100%;
`

const SFlexHeader = styled(FlexHeader)`
    justify-content: flex-start;
    align-items: center;
`

const SIconButton = styled(IconButton)`
    margin-top: -0.5rem !important;
    margin-left: 1rem !important;
`

const SH4 = styled(H4)`
    margin-right: -1rem !important;
`

const FDraggableList = ({ name, header = undefined }) => {
	const { control } = useFormContext()

	const {
		field: { value: items, onChange: onItemsChange },
	} = useController({
		name: name,
		control,
	})

	// useWatch car sinon les éléments ne se met pas à jour correctement
	const watchedItems = useWatch({ control, name: `${name}` })

	const onDragEnd = (result) => {
		if (!result.destination) return

		const reordered = [...items]
		const [removed] = reordered.splice(result.source.index, 1)
		reordered.splice(result.destination.index, 0, removed)

		onItemsChange(reordered)
	}

	const onFieldChange = (event, fieldIndex) => {
		items[fieldIndex] = event.target.value

		onItemsChange(items)
	}

	const onClickAddItem = () => {
		onItemsChange([...items, ""])
	}

	const onClickDelete = (toDeleteIndex) => {
		onItemsChange(items.filter((item, index) => index !== toDeleteIndex))
	}

	return (
		<>
			<SFlexHeader>
				{header && <SH4>{header}</SH4>}

				<SIconButton onClick={onClickAddItem}>
					<AddCircleOutline />
				</SIconButton>
			</SFlexHeader>
			<DragDropContext onDragEnd={onDragEnd}>
				<SuperDroppable component={DroppableComponent} directionColumn>
					{watchedItems.map((item, index) => {
						return (
							<SuperDraggable
								component={SFlex}
								key={index}
								index={index}
								id={`${index}`}
							>
								<SuperDragHandle />
								<TextField
									multiline
									value={item}
									onChange={(event) => {
										onFieldChange(event, index)
									}}
									fullWidth
									maxRows={4}
								/>
								<IconButton
									onClick={() => {
										onClickDelete(index)
									}}
								>
									<ClearIcon />
								</IconButton>
							</SuperDraggable>
						)
					})}
				</SuperDroppable>
			</DragDropContext>
		</>
	)
}

export default FDraggableList
