import { Autocomplete, Chip, TextField } from "@mui/material"
import React, { useMemo } from "react"
import { useController, useFormContext } from "react-hook-form"
import _ from "lodash"
import useAdminCommunes from "@/hooks/admin/useAdminCommunes"
import useAdminRestrictions from "@/hooks/useAdminRestrictions"
import ICommune from "../types/ICommune"
import styled from "styled-components"
import { useSelector } from "react-redux"

const SChip = styled(Chip)`
	margin: 2px !important;
`

const FSelectCommune = ({
	name,
	label,
	isJsonSchemaEdit = false,
	multiple = true,
	disabled = false,
}) => {
	const { control } = useFormContext()
	const communes = useSelector(
		(state: any) => state.admin?.users?.communes,
	) as Record<string, ICommune>
	const restrictedCommunes = useAdminCommunes()
	const adminRestrictions = useAdminRestrictions()

	const {
		field: { value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
		defaultValue: [],
	})

	return (
		<Autocomplete
			multiple={multiple}
			disableClearable={adminRestrictions.isRestricted}
			selectOnFocus
			clearOnBlur
			handleHomeEndKeys
			freeSolo
			fullWidth
			value={isJsonSchemaEdit ? Object.keys(value) : value ?? []}
			options={_.uniq(Object.keys(restrictedCommunes))}
			onChange={(_event, newValue) => {
				if (adminRestrictions.isRestricted) {
					// Prevent backspace key remove disabled elements
					// Added el ?? Removed el
					const concernedCommId =
						_.difference(newValue, Object.keys(value))[0] ??
						_.difference(Object.keys(value), newValue)[0]
					if (!adminRestrictions.allowedCommunes.includes(concernedCommId))
						return
				}
				const newJsonSchemaCommunes = {}
				if (isJsonSchemaEdit) {
					newValue.forEach(
						(communeId) =>
						(newJsonSchemaCommunes[`${communeId}`] = {
							isPublic: false,
							isShowOnPicrimMap: false,
							allowPublicMapView: false,
						}),
					)
					onChange(newJsonSchemaCommunes)
				} else {
					onChange(newValue)
				}
			}}
			getOptionLabel={(option) => communes[option]?.name ?? option}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					error={Boolean(error)}
					helperText={error?.message}
				/>
			)}
			renderTags={(value, getTagProps) =>
				value.map((option, index) => (
					<SChip
						key={option}
						label={communes[option]?.name ?? option}
						disabled={
							adminRestrictions.isRestricted &&
							!adminRestrictions.allowedCommunes.includes(option)
						}
						onDelete={getTagProps({ index }).onDelete}
					/>
				))
			}
			disabled={disabled}
		/>
	)
}

export default FSelectCommune
