import { yupResolver } from "@hookform/resolvers/yup"
import Axios from "axios"
import { getCdcs } from "@/redux-toolkit/admin/accounts/resources"
import React, { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { connect } from "react-redux"
import { Flex } from "utils/components/style/flex"
import FBoolean from "utils/form/FBoolean"
import FCheckboxes from "utils/form/FCheckboxes"
import FFreeTags from "utils/form/FFreeTags/FFreeTags"
import SuperModal from "utils/modals/SuperModal"
import ErrorService from "@/services/ErrorService"
import StaticFormsService, {
	StaticFormType,
} from "utils/services/StaticFormsService"
import Yup from "utils/yup"
import { useDispatch } from "react-redux"
import JsonSchemaApi from "@/redux-toolkit/data/jsonSchema/JsonSchemaApi"
import FAdminJsonSchemas from "utils/form/FAdminJsonSchemas"
const schema = Yup.object().shape({
	twitter_tags: Yup.array(Yup.string()),
	share_orga: Yup.boolean(),
	shared_between_communes: Yup.array(Yup.string())
		.ensure()
		.test(
			"stock",
			"Les stocks et les véhicules doivent être partagés quand les CARE ou les lieux de stockage le sont",
			(arr) => {
				if (
					arr.includes(StaticFormType.CARE) ||
					arr.includes(StaticFormType.STOCK_PLACE)
				) {
					return arr.includes(StaticFormType.STOCK && StaticFormType.VEHICULES)
				}
				return true
			},
		),
})

const ModalCDC = ({
	isOpen,
	setIsOpen,
	currentCdc,
	setCurrentCdc,
	getCdcs,
}) => {
	const dispatch = useDispatch()
	const [formsOptions, setFormsOptions] = useState([])
	const [additionalFormsName, setAdditionalFormsName] = useState({})
	const closeModal = () => {
		setIsOpen(false)
		setCurrentCdc(undefined)
	}

	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			twitter_tags: [],
			shared_between_communes: [],
			share_orga: false,
		},
	})

	useEffect(() => {
		if (isOpen) {
			methods.reset({
				twitter_tags: currentCdc.twitter_tags,
				shared_between_communes: currentCdc.shared_between_communes,
				share_orga: currentCdc.share_orga,
			})

			const getSharedFormsWithStatics = async () => {
				const staticForms = StaticFormsService.getStaticForms()
				const cdcSharedJsonSchemas = await JsonSchemaApi.getCdcJsonSchemas(
					currentCdc.id,
				)

				let tempAdditionalFormsName = {}
				const sharedForms = Object.values(
					cdcSharedJsonSchemas.jsonSchemas,
				) as any

				sharedForms.forEach(
					(item) => (tempAdditionalFormsName[item.id] = item.title),
				)

				const forms = [...staticForms, ...sharedForms]
				const formsOptions = forms.map((item) => item.id)

				setAdditionalFormsName(tempAdditionalFormsName)
				setFormsOptions(formsOptions)
			}

			getSharedFormsWithStatics()
		}
	}, [isOpen])

	const onSubmit = async (values) => {
		try {
			await Axios.put(`/api/cdcs/${currentCdc.id}`, {
				data: {
					twitter_tags: values.twitter_tags ?? [],
					shared_between_communes: values.shared_between_communes ?? [],
					share_orga: values.share_orga,
				},
			})

			getCdcs()
			setIsOpen(!isOpen)
		} catch (error) {
			const errorMessage = `Echec de la tentative de modification des données : ${error}`
			ErrorService.error({
				component: "ModalCDC:onSubmit",
				message: errorMessage,
				dispatch,
			})
		}
	}

	return (
		<FormProvider {...methods}>
			<SuperModal
				onClick={methods.handleSubmit(onSubmit)}
				isNew={!currentCdc}
				isOpen={isOpen}
				onClose={closeModal}
				title="Paramètres de la CDC"
			>
				<Flex directionColumn gap="1rem">
					<FFreeTags name="twitter_tags" label="Affiliations Twitter" />
					<FBoolean
						name="share_orga"
						label="Partage de l'ogranigramme"
						trueLabel="Partagé"
						falseLabel="Non partagé"
					/>
					<FAdminJsonSchemas
						name="shared_between_communes"
						label="Formulaires partagés entre les communes"
						optionsOverride={formsOptions}
						multiple
					/>
				</Flex>
			</SuperModal>
		</FormProvider>
	)
}

const mapDispatchToProps = (dispatch) => ({
	getCdcs: () => dispatch(getCdcs()),
})
export default connect(null, mapDispatchToProps)(ModalCDC)
