import React, { useMemo, useState } from "react"
import { connect } from "react-redux"
import SuperReactTable from "@/utils/components/tables/SuperReactTable/SuperReactTable"
import ModalCDC from "./ModalCDC"
import useAdminCdcs from "@/hooks/admin/useAdminCdcs"

const TableCdcs = () => {
	const cdcs = useAdminCdcs()
	const [isOpen, setIsOpen] = useState(false)
	const [currentCdc, setCurrentCdc] = useState(undefined)

	const cdcsList = useMemo(() => {
		const cdcs_formated = cdcs ?? {}
		return Object.keys(cdcs_formated).map((id) => cdcs[id])
	}, [cdcs])

	const columns = useMemo(
		() => [
			{
				header: "Nom",
				accessorKey: "name",
			},
		],
		[],
	)

	return (
		<>
			<SuperReactTable
				data={cdcsList}
				columns={columns}
				onEditClick={(original) => {
					setCurrentCdc(original)
					setIsOpen(true)
				}}
				isDeletable={false}
				selectable={false}
				disabledSearchProperties={["geojson"]}
			/>
			<ModalCDC
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				currentCdc={currentCdc}
				setCurrentCdc={setCurrentCdc}
			/>
		</>
	)
}

export default TableCdcs
