import DicrimApi from "./DicrimApi"
import ErrorService from "@/services/ErrorService"
import { addDicrim, setDicrims } from "./reducer"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const getDicrims = async (dispatch) => {
	try {
		const { dicrims } = await DicrimApi.getDicrims()
		dispatch(setDicrims(dicrims))
	} catch (error) {
		console.error(error.message)
	}
}

export const createDicrim = createAsyncThunk(
	"data/dicrim/createDicrim",
	async (dicrim: any, { dispatch }) => {
		try {
			const createdDicrim = await DicrimApi.createDicrim(dicrim)
			dispatch(addDicrim(createdDicrim))
		} catch (error) {
			const errorMessage = `Erreur : ${error}`
			ErrorService.error({
				component: "Dicrim:createDicrim",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const updateDicrim = createAsyncThunk(
	"data/dicrim/updateDicrim",
	async (dicrim: any, { dispatch }) => {
		try {
			const updatedDicrim = await DicrimApi.updateDicrim(dicrim)
			dispatch(addDicrim(JSON.parse(updatedDicrim.dicrim)))
		} catch (error) {
			const errorMessage = `Erreur : ${error}`
			ErrorService.error({
				component: "Dicrim:updateDicrim",
				message: errorMessage,
				dispatch,
			})
		}
	},
)
