import { Autocomplete, Box, TextField } from "@mui/material"
import useAdminCommunes from "@/hooks/admin/useAdminCommunes"
import React, { useMemo } from "react"
import { useController, useFormContext } from "react-hook-form"

const FAdminCommunes = ({ name, label, disabled = false }) => {
	const communes = useAdminCommunes()
	const { control } = useFormContext()

	const {
		field: { value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
	})

	return (
		<Autocomplete
			disabled={disabled}
			disableClearable
			fullWidth
			value={value ?? null}
			options={Object.keys(communes)}
			onChange={(event, newValue) => onChange(newValue)}
			getOptionLabel={(option) => communes[option].name}
			renderOption={(props, option) => {
				const commune = communes[option]
				return (
					<li {...props} key={commune.id}>
						{commune.name}
					</li>
				)
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					error={Boolean(error?.message)}
					helperText={error?.message}
				/>
			)}
		/>
	)
}

export default FAdminCommunes
