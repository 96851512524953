import { Checkbox } from "@mui/material"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import _ from "lodash"
import React, { useMemo } from "react"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import { ColumnDef } from "@tanstack/react-table"

const WhitelistTable = ({
	currentWhitelist,
	currentSettings,
	setCurrentSettings,
}) => {
	const jsonSchemas = useJsonSchemasWithStatics()

	const checkboxCell: ColumnDef<any, any>["cell"] = ({
		getValue,
		row,
		column,
	}) => {
		return (
			<Checkbox
				checked={getValue()}
				onClick={() => handleClick(row.original.id, column.id)}
			/>
		)
	}

	const whitelist = useMemo(() => {
		return currentWhitelist
			.map((entry) => ({
				name: jsonSchemas[entry.id]?.title ?? "notFound",
				id: entry.id,
				read: entry.values.read,
				write: entry.values.write,
			}))
			.filter((entry) => entry.name !== "notFound")
	}, [currentWhitelist, jsonSchemas])

	const columns = useMemo(
		() => [
			{
				header: "ID du fomulaire",
				accessorKey: "id",
			},
			{
				header: "Nom du formulaire",
				accessorKey: "name",
			},
			{
				header: "Droit de lecture",
				accessorKey: "read",
				enableSorting: false,
				isAction: true,
				width: "auto",
				cell: checkboxCell,
			},
			{
				header: "Droit d'écriture",
				accessorKey: "write",
				enableSorting: false,
				isAction: true,
				width: "auto",
				cell: checkboxCell,
			},
		],
		[currentSettings],
	)

	const handleClick = (formId, permission) => {
		const path = `accessRights.tools.JSONSCHEMAS.WHITELIST.${formId}.${permission}`
		const newSettings = _.cloneDeep(currentSettings)
		const previousValue = _.get(newSettings, path)

		_.set(newSettings, path, !previousValue)
		setCurrentSettings(newSettings)
	}

	return (
		<SuperReactTable
			data={whitelist}
			columns={columns}
			isEditable={false}
			isDeletable={false}
			selectable={false}
		/>
	)
}

export default WhitelistTable
