import {
	TextField,
} from "@mui/material"
import Axios from "axios"
import React, { useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { useBoolean } from "react-use"
import CustomTabs from "@/utils/components/customTabs/CustomTabs"
import { Flex } from "@/utils/components/style/flex"
import SuperModal from "@/utils/modals/SuperModal"
import TableCdcs from "./TableCdcs"
import TableCommunes from "./TableCommunes"
import TableUsers from "./TableUsers"
import SearchCommune from "@/utils/components/SearchCommune"
import styled from "styled-components"
import { createCommune } from "@/redux-toolkit/admin/accounts/resources"
import useAdminRestrictions from "@/hooks/useAdminRestrictions"
import AdminTopBar from "../../AdminTopBar"
import Button from "@/styles/atoms/Button"
import useCommune from "@/hooks/useCommune"
import _ from "lodash"
import ICommune from "@/utils/types/ICommune"
import useAdminCommunes from "@/hooks/admin/useAdminCommunes"

const SFlex = styled(Flex)`
	padding: 0 2em;
`
const STextField = styled(TextField)`
	width: 500px;
`

const Accounts = () => {
	const dispatch = useDispatch()
	const adminRestrictions = useAdminRestrictions()
	const communes = useAdminCommunes()
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [codeInsee, setCodeInsee] = useState("")
	const [communeSelected, setCommuneSelected] = useState(undefined)
	const [isOpenModalCreateUser, toggleIsOpenModalCreateUser] = useBoolean(false)
	const [isOpenModalCreateCommune, toggleIsOpenModalCreateCommune] =
		useBoolean(false)

	return (
		<div>
			<AdminTopBar title="Comptes">
				{!adminRestrictions.isRestricted && (
					<Button $backgroundColor="var(--purple500)" $textColor="white" onClick={toggleIsOpenModalCreateCommune}>
						Créer une commune
					</Button>
				)}
				<Button color="primary" onClick={toggleIsOpenModalCreateUser}>
					Créer un compte utilisateur
				</Button>
			</AdminTopBar>
			<CustomTabs
				tabChildrens={[
					{
						key: "Utilisateurs",
						render: <TableUsers />,
					},
					{
						key: "Communes",
						render: <TableCommunes communes={communes} />,
					},
					{
						key: "Cdcs",
						render: <TableCdcs />,
					},
				]}
			/>
			<SuperModal
				isOpen={isOpenModalCreateUser}
				onClose={toggleIsOpenModalCreateUser}
				title="Création d'un compte utilisateur"
				onClick={() => {
					if (!communeSelected) {
						alert("Veuillez sélectionner une commune")
						return
					}
					Axios.post("/api/users_create", {
						communeId: communeSelected,
						email,
						password,
					})
					toggleIsOpenModalCreateUser()
					alert("utilisateur créé")
				}}
				modalProps={{ $baseHeight: 670 }}
			>
				<SFlex alignItemsStart directionColumn gap="1em">
					<STextField
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						label="Email"
					/>
					<STextField
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						label="Mot de passe"
					/>
					<SearchCommune
						options={Object.keys(communes)}
						communes={Object.values(communes)}
						setFilter={(commune) => setCommuneSelected(commune)}
						placeholder="Rechercher une commune"
						variant="standard"
						multiple={false}
					/>
				</SFlex>
			</SuperModal>
			<SuperModal
				isOpen={isOpenModalCreateCommune}
				onClose={toggleIsOpenModalCreateCommune}
				title="Création d'une commune"
				onClick={async () => {
					const pattern = /^[0-9]{5}$/
					if (!codeInsee.match(pattern)) {
						alert("Code Insee invalide")
						return
					}
					dispatch(createCommune({ codeInsee: codeInsee }))
					alert("commune créée")
					toggleIsOpenModalCreateCommune()
				}}
				modalProps={{ $baseHeight: 670 }}
			>
				<SFlex alignItemsStart directionColumn gap="1em">
					<STextField
						value={codeInsee}
						onChange={(e) => setCodeInsee(e.target.value)}
						label="Code Insee"
					/>
				</SFlex>
			</SuperModal>
		</div>
	)
}

export default Accounts
