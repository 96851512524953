import { getCommunes } from "@/redux-toolkit/admin/accounts/resources"
import Modal from "@/styles/organisms/Modal"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import { yupResolver } from "@hookform/resolvers/yup"
import { ModalContent } from "@nextui-org/react"
import Axios from "axios"
import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { PrimaryButton } from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import { H4 } from "@/utils/components/style/header"
import FSelect from "@/utils/form/FSelect"
import FText from "@/utils/form/FText"
import ErrorService from "@/services/ErrorService"
import Yup from "@/utils/yup"

const schema = Yup.object().shape({
	contactInfos: Yup.object({
		email: Yup.array(Yup.string()).notRequired().default(["", ""]),
		telephone: Yup.array(Yup.string()).notRequired().default(["", ""]),
		address: Yup.array(Yup.string()).notRequired().default(["", ""]),
	}),
	alertInfos: Yup.object({
		facebook: Yup.string().notRequired().default(""),
		communeWebsite: Yup.string().notRequired().default(""),
		prefectureWebsite: Yup.string().notRequired().default(""),
		radioStation: Yup.string().notRequired().default(""),
	}),
	sismicClass: Yup.string().notRequired().default(""),
})

const PicrimInfosModal = ({ commune, isOpen, onClose, setCurrentCommune }) => {
	const dispatch = useDispatch()

	const methods = useForm({
		defaultValues: commune?.picrimInfos
			? commune?.picrimInfos
			: schema.getDefault(),
		resolver: yupResolver(schema),
	})

	useEffect(() => {
		methods.reset(
			commune?.picrimInfos ? commune?.picrimInfos : schema.getDefault(),
		)
	}, [commune])

	const onSubmit = async (values) => {
		const clonedCommune = { ...commune, picrimInfos: values }
		delete clonedCommune.cdc
		delete clonedCommune.cdc_geojson
		delete clonedCommune.twitter_tags

		try {
			await Axios.put(`/api/update_communes_admin/${clonedCommune.id}`, {
				data: clonedCommune,
			})
			onClose()
			setCurrentCommune(undefined)
			dispatch(getCommunes())
			window.location.reload()
		} catch (error) {
			const errorMessage = `Echec de la mise à jour des infos : ${error}`
			ErrorService.error({
				component: "AdminPicrim:picrimInfos",
				message: errorMessage,
				dispatch,
			})
		}
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="3xl">
			<ModalContent>
				<ModalHeader>
					PICRIM Infos contact et alerte - {commune?.name}
				</ModalHeader>
				<FormProvider {...methods}>
					<ModalBody>
						<Flex alignItemsStretch directionColumn gap={"0.5rem"}>
							<H4>Infos contact</H4>
							{["telephone", "email", "address"].map((infoKey) => (
								<Flex key={infoKey} gap={"0.5rem"}>
									<FText
										name={`contactInfos.${infoKey}[0]`}
										label={`${infoKey} de la commune`}
									/>
									<FText
										name={`contactInfos.${infoKey}[1]`}
										label={"Sous-texte"}
									/>
								</Flex>
							))}

							<H4>Infos alerte</H4>
							<Flex gap={"0.5rem"}>
								<FText name={"alertInfos.radio"} label={"Station radio"} />
								<FText name={"alertInfos.facebook"} label={"Facebook"} />
							</Flex>
							<Flex gap={"0.5rem"}>
								<FText
									name={"alertInfos.communeWebsite"}
									label={"Site web de la commune"}
								/>
								<FText
									name={"alertInfos.prefectureWebsite"}
									label={"Site web de la préfecture"}
								/>
							</Flex>
							<FSelect
								name="sismicClass"
								label="Classification Séismique"
								options={["0", "1", "2", "3", "4", "5"]}
							/>
						</Flex>
					</ModalBody>
				</FormProvider>
				<ModalFooter>
					<PrimaryButton onClick={methods.handleSubmit(onSubmit)}>
						Enregistrer
					</PrimaryButton>
				</ModalFooter>
			</ModalContent>
		</Modal >
	)
}

export default PicrimInfosModal
