import useJsonSchemas from "@/hooks/useJsonSchemas"
import {
	deleteJsonSchema,
	getAdminJsonSchemas,
} from "@/redux-toolkit/data/jsonSchema/resources"
import React, { useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { PrimaryButton, RedButton } from "@/utils/components/style/button"
import SuperReactTable from "@/utils/components/tables/SuperReactTable/SuperReactTable"
import getImage from "@/utils/getImage"
import { Flex, FlexButtons, FlexHeader } from "@/utils/components/style/flex"
import EditeurTemplate from "./EditeurTemplate"
import { H2, H6 } from "@/utils/components/style/header"
import { useSelector } from "react-redux"
import { ColumnDef } from "@tanstack/react-table"
import ICommune from "@/utils/types/ICommune"
import formatNfdLowerCase from "@/utils/formatNfdLowerCase"
import ContentCopy from "@mui/icons-material/ContentCopy"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import { FormProvider, useForm } from "react-hook-form"
import FSelectCommune from "@/utils/form/FSelectCommune"
import Button from "@/styles/atoms/Button"
import AdminTopBar from "../AdminTopBar"
import useAdminRestrictions from "@/hooks/useAdminRestrictions"
import _ from "lodash"
import Tooltip from "@/styles/atoms/Tooltip"
import { IconButton } from "@mui/material"
import { width50 } from "@/utils/components/tables/widthProps"
import ConfirmationModal from "@/utils/components/ConfirmationModal"
import { useBoolean } from "react-use"

const JsonSchemas = ({ getJsonSchemas, history, deleteJsonSchema }) => {
	const jsonSchemas = useJsonSchemas()
	const communes = useSelector(
		(state: any) => state.admin?.users?.communes,
	) as Record<string, ICommune>
	const adminRestrictions = useAdminRestrictions()
	const [isEditeurOpen, setIsEditeurOpen] = useState(false)
	const [isOpenModal, setIsOpenModal] = useBoolean(false)
	const [selectedSchemaId, setSelectedSchemaId] = useState(undefined)
	const [initialValues, setInitialValues] = useState(undefined)

	useEffect(() => {
		getJsonSchemas()
	}, [])

	const jsonSchemasArray = useMemo(
		() => Object.values(jsonSchemas),
		[jsonSchemas],
	)

	const toggleEditeur = () => {
		setIsEditeurOpen(!isEditeurOpen)
	}

	const CustomFilter = ({ setGlobalFilter, state }) => {
		const methods = useForm({
			defaultValues: {
				commune: "",
			},
		})
		const commune = methods.watch("commune")

		useEffect(() => {
			setGlobalFilter(communes[commune]?.name)
		}, [commune])

		return (
			<FormProvider {...methods}>
				<FSelectCommune
					label="Filtrer par Commune"
					name="commune"
					multiple={false}
				/>
			</FormProvider>
		)
	}

	const columns = useMemo(
		() =>
			[
				{
					header: "Image",
					size: 70,
					accessorKey: "image",
					cell: (props) => (
						<>
							<img width={40} src={getImage(props.row.original.imgId)} />
						</>
					),
				},
				{
					header: "Id",
					accessorKey: "id",
				},
				{
					header: "Titre",
					accessorKey: "title",
				},
				{
					header: "Catégorie",
					accessorKey: "main_category",
				},
				{
					header: "Sous-catégorie",
					accessorKey: "sub_category",
				},
				{
					header: "Communes",
					accessorKey: "communes",
					meta: {
						canBeFiltered: true,
						Filter: CustomFilter,
					},
					sortingFn: (a, b) => {
						const aData = a.original;
						const bData = b.original;
						return bData.communes.length - aData.communes.length;
					},
					cell: (props) => {
						return props.row.original.communes.length;
					},
				},
				{
					header: "",
					enableSorting: false,
					accessorKey: "duplicate",
					meta: {
						isAction: true,
						canBeFiltered: false,
					},
					cell: (props) => {
						return (
							<Button
								variant="light"
								isIconOnly={true}
								startContent={<ContentCopy />}
								onClick={() => {
									setSelectedSchemaId(null)
									toggleEditeur()
									const { id, ...rest } = props.row.original
									setInitialValues({ ...rest, communes: [] })
								}}
							/>
						)
					},
				},
				{
					header: "",
					enableSorting: false,
					accessorKey: "edit",
					cell: (props) => {
						return (
							<Tooltip content="Editer">
								<IconButton onClick={() => {
									setSelectedSchemaId(props.row.original.id)
									toggleEditeur()
								}}>
									<EditIcon />
								</IconButton>
							</Tooltip>
						)
					},
					...width50
				},
				{
					header: "",
					enableSorting: false,
					accessorKey: "delete",
					cell: (props) => {
						const disabled = adminRestrictions.isRestricted && !!
							props.row.original.communes.filter((comm_name) => {
								const allowedCommNames = adminRestrictions.allowedCommunes.map((comm_id) => communes[comm_id].name)
								return !allowedCommNames.includes(comm_name)
							}
							).length

						return (
							<Tooltip content="Supprimer">
								<IconButton onClick={() => {
									setIsOpenModal(true)
									setSelectedSchemaId(props.row.original.id)
								}} disabled={disabled}>
									<DeleteIcon />
								</IconButton>
							</Tooltip>
						)
					},
					...width50
				},
			] as ColumnDef<any, any>[],
		[communes],
	)

	const data = useMemo(() => (
		jsonSchemasArray.map((schema) => ({
			...schema,
			communes: Object.values(communes)
				.filter((commune: ICommune) =>
					commune.jsonSchemaIds.includes(schema.id),
				)
				.map((commune: ICommune) => commune.name),
		}))
	), [jsonSchemasArray, communes])

	return (
		<>
			<AdminTopBar title="JsonSchemas">
				<Button color="primary"
					onClick={() => {
						setSelectedSchemaId(null)
						toggleEditeur()
					}}
				>
					Créer un template
				</Button>
			</AdminTopBar>
			<SuperReactTable
				data={data}
				columns={columns}
				isEditable={false}
				isDeletable={false}
			/>
			<EditeurTemplate
				isEditeurOpen={isEditeurOpen}
				toggleEditeur={toggleEditeur}
				selectedSchemaId={selectedSchemaId}
				initialValues={initialValues}
			/>
			<ConfirmationModal
				modalShown={isOpenModal}
				onClose={() => setIsOpenModal(false)}
				message="Etes vous certain de vouloir copier les fiches ?"
				onConfirm={() => {
					deleteJsonSchema(selectedSchemaId)
					setSelectedSchemaId(undefined)
					setIsOpenModal(false)
				}}
			/>
		</>
	)
}

const mapDispatchToProps = (dispatch) => {
	return {
		getJsonSchemas: () => {
			dispatch(getAdminJsonSchemas())
		},
		deleteJsonSchema: (id) => {
			dispatch(deleteJsonSchema(id))
		},
	}
}

export default withRouter(connect(null, mapDispatchToProps)(JsonSchemas))
