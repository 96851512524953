import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Debug from "debug"
import styled, { css } from "styled-components"
import Moment from "moment"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import FOptionsWithImageModal from "./FOptionsWithImageModal"
import { useBoolean } from "react-use"
import { useController, useFormContext } from "react-hook-form"
import getImage from "@/utils/getImage"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import { OptionWithImage } from "./FOptionsWithImageModal.type"
import { PrimaryButton } from "utils/components/style/button"
import { ColumnDef } from "@tanstack/react-table"

// app:javascript:utils:form:FSelectWithImage:admin:FOptionsWithImage.tsx
const debug = Debug(
	"app:javascript:utils:form:FSelectWithImage:admin:FOptionsWithImage",
)
debug.log = console.log.bind(console)

const FOptionsWithImage = ({ name, label }) => {
	const [isOpen, toggleOpen] = useBoolean(false)
	const [editingData, setEditingData] = useState(null)
	const { control } = useFormContext()

	const {
		field: { ref, value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
		defaultValue: [],
	})

	const columns = useMemo(
		() =>
			[
				{
					header: "Image",
					accessorKey: "imgId",
					cell: ({ getValue }) => {
						const value = getValue() as string
						return <img src={getImage(value)} width={40} />
					},
				},
				{
					header: "Label",
					accessorKey: "label",
				},
			] as ColumnDef<any>[],
		[],
	)

	return (
		<>
			<SuperReactTable
				columns={columns}
				data={value}
				onEditClick={(data) => {
					setEditingData(data)
					toggleOpen()
				}}
				onDeleteClick={(data: OptionWithImage) => {
					onChange(value.filter((v: OptionWithImage) => v.label !== data.label))
				}}
				actionsButtons={
					<PrimaryButton onClick={toggleOpen}>Ajouter une option</PrimaryButton>
				}
			/>

			<FOptionsWithImageModal
				isOpen={isOpen}
				onClose={toggleOpen}
				onSubmit={(data) => {
					if (editingData) {
						onChange(
							value.map((v) => {
								if (v.id === editingData.id) {
									return {
										...v,
										...data,
									}
								}
								return v
							}),
						)
					} else {
						onChange([
							...value,
							{
								...data,
								id: Moment().valueOf(),
							},
						])
					}
					toggleOpen()
				}}
				editingData={editingData}
			/>
		</>
	)
}

export default FOptionsWithImage
