import BuildIcon from "@mui/icons-material/Build"
import ContactPhoneIcon from "@mui/icons-material/ContactPhone"
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert"
import DescriptionIcon from "@mui/icons-material/Description"
import PanToolIcon from "@mui/icons-material/PanTool"
import SchemaIcon from "@mui/icons-material/Schema"
import SettingsIcon from "@mui/icons-material/Settings"
import { Alert, List } from "@mui/material"
import Axios from "axios"
import { getUsers } from "@/redux-toolkit/admin/accounts/resources"
import Debug from "debug"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { PrimaryButton, PrimaryTextButton } from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import ErrorService from "@/services/ErrorService"
import BlacklistForm from "@/pages/admin/BlacklistForm"
import CollapsePermissionsList from "./CollapsePermissionsList"
import {
	IPermissionAdminElement,
	PERMISSION_CATEGORY,
} from "./configPermissionsAdmin"
import JSONInputForm from "./JSONInputForm"
import WhitelistForm from "./WhitelistForm"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import Modal from "@/styles/organisms/Modal"
import { ModalContent } from "@nextui-org/react"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import useAdminRestrictions from "@/hooks/useAdminRestrictions"
import useAdminCommunes from "@/hooks/admin/useAdminCommunes"
import useAdminCdcs from "@/hooks/admin/useAdminCdcs"
import { FormProvider, useForm } from "react-hook-form"
import Yup from "@/utils/yup"
import { yupResolver } from "@hookform/resolvers/yup"
import FAdminSelect from "@/utils/form/FAdminSelect"

// app:javascript:components:admin:EditPermissionsModal.tsx
const debug = Debug("app:javascript:components:admin:EditPermissionsModal")
debug.log = console.log.bind(console)

const SFlex = styled(Flex)`
    margin-bottom: 1rem;
`

const categories = [
	{
		name: "Configuration",
		type: PERMISSION_CATEGORY.ACCOUNT_CONFIG,
		icon: () => <SettingsIcon />,
	},
	{
		name: "Gestion de crise",
		type: PERMISSION_CATEGORY.GESTION_CRISE,
		icon: () => <PanToolIcon />,
	},
	{
		name: "Main courante",
		type: PERMISSION_CATEGORY.SUIVI_CRISE,
		icon: () => <CrisisAlertIcon />,
	},
	{
		name: "Coopération",
		type: PERMISSION_CATEGORY.COOPERATION,
		icon: () => <BuildIcon />,
	},
	{
		name: "Données PCS",
		type: PERMISSION_CATEGORY.CUSTOM_DATA,
		icon: () => <ContactPhoneIcon />,
	},
	{
		name: "Documents",
		type: PERMISSION_CATEGORY.DOCUMENTS,
		icon: () => <DescriptionIcon />,
	},
	{
		name: "Fomulaires dynamiques",
		type: PERMISSION_CATEGORY.JSONSCHEMAS,
		icon: () => <SchemaIcon />,
	},
]

const schema = Yup.object({
	selected_commune_ids: Yup.array(Yup.string()).default([]),
	selected_cdc_ids: Yup.array(Yup.string()).default([])
})

const EditPermissionsModal = ({ isOpen, onClose, currentUser }) => {
	const dispatch = useDispatch()
	const communes = useAdminCommunes()
	const cdcs = useAdminCdcs()
	const adminRestrictions = useAdminRestrictions()
	const [currentSettings, setCurrentSettings] = useState(undefined)

	const orderedCommunes = Object.values(communes).sort((a, b) => {
		const nameA = a.name.toUpperCase()
		const nameB = b.name.toUpperCase()
		return nameA.localeCompare(nameB)
	})
	const orderedCdcs = Object.values(cdcs).sort((a, b) => {
		const nameA = a.name.toUpperCase()
		const nameB = b.name.toUpperCase()
		return nameA.localeCompare(nameB)
	})

	const methods = useForm({
		defaultValues: schema.getDefault(),
		resolver: yupResolver(schema),
	})

	const editionPpts = {
		isDisabled: adminRestrictions.isRestricted && ['super-admin', 'admin'].includes(currentUser?.role),
		disabledMessage: _.isEqual(adminRestrictions.selfId, currentUser?.id)
			? "Contacter un super-admin pour modifier vos permissions."
			: "Impossible d'accéder aux permissions d'une personne de grade équivalent ou supérieur."
	}

	useEffect(() => {
		setCurrentSettings(currentUser?.settings)
		methods.reset({
			selected_commune_ids: currentUser?.settings?.accessRights?.communes,
			selected_cdc_ids: currentUser?.settings?.accessRights?.cdcs
		})
	}, [currentUser])

	const changePermission = async (id, newPermission) => {
		try {
			await Axios.put(`/api/users/${id}`, { data: { settings: newPermission } })
			dispatch(getUsers())
		} catch (error) {
			const errorMessage = `Erreur lors de la récupération des données utilisateurs : ${error}`
			ErrorService.error({
				component: "AccessRights:getValue",
				message: errorMessage,
				dispatch,
			})
		}
	}

	const handleChange = (permission: IPermissionAdminElement) => {
		const newSettings = _.cloneDeep(currentSettings)

		const permissionPaths = permission?.path ?? []
		const readPermissionPath = permission?.readPermissionPath ?? false
		const writePermissionPath = permission?.writePermissionPath ?? false
		const childrenPaths = permission?.childrenPaths ?? false

		permissionPaths.forEach((permissionPath) => {
			const previousValue = _.get(newSettings, permissionPath)
			if (childrenPaths) {
				childrenPaths.forEach((childrenPath) => {
					const previousChildValue = _.get(newSettings, childrenPath)
					if (previousChildValue === previousValue) {
						_.set(newSettings, childrenPath, !previousChildValue)
					}
				})
			}
			if (readPermissionPath && !previousValue) {
				const previousReadValue = _.get(newSettings, readPermissionPath)
				if (!previousReadValue) {
					_.set(newSettings, readPermissionPath, !previousReadValue)
				}
			}
			if (writePermissionPath && previousValue) {
				writePermissionPath.forEach((writePath) => {
					const previousWriteValue = _.get(newSettings, writePath)
					if (previousWriteValue === true) {
						_.set(newSettings, writePath, !previousWriteValue)
					}
				})
			}
			_.set(newSettings, permissionPath, !previousValue)
		})

		setCurrentSettings(newSettings)
	}

	if (editionPpts.isDisabled) {
		return (
			<Modal isOpen={isOpen} onClose={onClose} size="5xl">
				<ModalContent>
					<ModalHeader>Edition permission -- {currentUser?.email}</ModalHeader>
					<ModalBody>
						<Alert severity="error">
							{editionPpts.disabledMessage}
						</Alert>
					</ModalBody>
					<ModalFooter>
						<PrimaryTextButton onClick={onClose}>Annuler</PrimaryTextButton>
					</ModalFooter>
				</ModalContent>
			</Modal>
		)
	}

	return (
		<Modal isOpen={isOpen} onClose={() => onClose()} size="5xl">
			<ModalContent>
				<ModalHeader>Edition permission -- {currentUser?.email}</ModalHeader>
				<ModalBody>
					<SFlex spaceBetween alignItemsStart>
						<List>
							{categories.map((category) => (
								<React.Fragment key={category.name}>
									<CollapsePermissionsList
										buttonText={category.name}
										category={category.type}
										handleChange={handleChange}
										currentSettings={currentSettings}
										ListButtonIcon={category.icon}
									/>
								</React.Fragment>
							))}
						</List>
						<JSONInputForm
							currentSettings={currentSettings}
							setCurrentSettings={setCurrentSettings}
						/>{" "}
					</SFlex>
					<FormProvider {...methods}>
						<Flex gap=".5em">
							<FAdminSelect
								name="selected_commune_ids"
								options={orderedCommunes.map((comm) => comm.id)}
								placeholder="Communes"
								getOptionLabel={(comm_id) => communes[comm_id].name}
								onItemClickCallback={() => {
									let newSettings = _.cloneDeep(currentSettings)
									newSettings.accessRights.communes = methods.getValues("selected_commune_ids")
									setCurrentSettings(newSettings)
								}}
								disabled={currentUser?.role != 'admin'}
							/>
							<FAdminSelect
								name="selected_cdc_ids"
								options={orderedCdcs.map((c) => c.id)}
								placeholder="Cdcs"
								getOptionLabel={(cdc_id) => cdcs[cdc_id].name}
								onItemClickCallback={() => {
									let newSettings = _.cloneDeep(currentSettings)
									newSettings.accessRights.cdcs = methods.getValues("selected_cdc_ids")
									setCurrentSettings(newSettings)
								}}
								disabled={currentUser?.role != 'admin'}
							/>
						</Flex>
					</FormProvider>
					<WhitelistForm
						currentSettings={currentSettings}
						setCurrentSettings={setCurrentSettings}
						currentUser={currentUser}
					/>
					<BlacklistForm
						currentSettings={currentSettings}
						setCurrentSettings={setCurrentSettings}
						currentUser={currentUser}
					/>
				</ModalBody>
				<ModalFooter>
					<PrimaryTextButton onClick={onClose}>Annuler</PrimaryTextButton>
					<PrimaryButton
						onClick={() => {
							changePermission(currentUser.id, currentSettings)
							onClose()
						}}
					>
						Enregistrer
					</PrimaryButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default EditPermissionsModal
