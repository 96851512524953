import BlockIcon from "@mui/icons-material/Block"
import { Autocomplete, TextFieldVariants } from "@mui/material"
import React from "react"
import { isMobile } from "react-device-detect"
import ICommune from "@/utils/types/ICommune"
import SearchBarTextField from "../components/SearchBarTextField"
import { useController, useFormContext } from "react-hook-form"

const FAdminSelect = ({
    name,
    options,
    placeholder,
    multiple = true,
    variant = "outlined",
    disabled = false,
    getOptionLabel = (el) => el,
    onItemClickCallback = () => null,
}: {
    name: string
    options: string[]
    placeholder: string
    multiple?: boolean
    variant?: TextFieldVariants
    disabled?: boolean
    getOptionLabel?: (el: any) => string
    onItemClickCallback?: () => void
}) => {
    const { control } = useFormContext()
    const {
        field: { value, onChange },
        fieldState: { error },
    } = useController({
        name,
        control,
        rules: { required: true },
        defaultValue: multiple ? [] : undefined,
    })
    return (
        <Autocomplete
            options={options}
            value={value ?? null}
            onChange={(event, newValue) => {
                onChange(newValue)
                onItemClickCallback()
            }}
            multiple={multiple}
            // getOptionLabel={(option) => communes.find((c) => c.id === option).name}
            getOptionLabel={getOptionLabel}
            fullWidth
            autoHighlight
            // ChipProps={{
            //     icon: exclude ? <BlockIcon /> : undefined,
            // }}
            disableCloseOnSelect
            renderInput={(params) => (
                <SearchBarTextField
                    variant={variant}
                    {...params}
                    placeholder={placeholder}
                />
            )}
            disabled={disabled}
        />
    )
}
export default FAdminSelect
