import { resetRedux } from "@/redux-toolkit/rootActions"
import Axios from "axios"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { ReactTableContainerContext } from "@/utils/hooks/useAutoPageSize"
import useReactTableContainer from "@/utils/hooks/useReactTableContainer"
import { useHistory, useLocation } from "react-router-dom"
import axios from "axios"
const Wrapper = styled.div`
    height: 100vh;
    padding: 2em;
    overflow: auto;
`

function deleteAllCookies() {
	const cookies = document.cookie.split(";")

	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i]
		const eqPos = cookie.indexOf("=")
		const name = eqPos > -1 ? cookie.substr(0, eqPos).trim() : cookie.trim()

		// Create a regex to find all cookies with the same name
		const nameRegex = new RegExp(`(^|;\\s*)${name}=`, "g")

		// Delete all cookies with the same name
		document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/"

		let retryCount = 0
		while (document.cookie.match(nameRegex) && retryCount < 5) {
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/"
			retryCount++
		}
	}
}

function AdminContainer({ children }) {
	const { value, callbackRef } = useReactTableContainer()
	const [hasVisitedImport, setHasVisitedImport] = useState(false)
	const dispatch = useDispatch()

	const location = useLocation()

	useEffect(() => {
		if (location?.pathname === "/admin/import" && !hasVisitedImport) {
			setHasVisitedImport(true)
		}
	}, [location])

	useEffect(() => {
		const handleBeforeUnload = (event) => {
			// Cancel the event as a safeguard to prevent the browser from closing immediately
			if (!hasVisitedImport) {
				return
			}
			event.preventDefault()
			console.log("User is leaving the page")
			const deconnexion = async () => {
				const auth = Axios.defaults.headers.common["Authorization"]
				console.log(Axios.defaults.headers.common)
				console.log(auth)
				await Axios.get("/users/sign_out", {})
				dispatch(resetRedux())
				window.DatabaseService.deleteDB()
				caches.delete(":s3-cache")
				caches.delete(":map-cache")
				Axios.defaults.headers.common["access-token"] = ""
				Axios.defaults.headers.common["client"] = ""
				Axios.defaults.headers.common["uid"] = ""
				localStorage.clear()
				deleteAllCookies()
				window.location.href = "/"
			}
			// deconnexion only if user has visited url /admin
			deconnexion()
			event.returnValue = ""
		}

		window.addEventListener("beforeunload", handleBeforeUnload)

		return () => {
			// Clean up the event listener when the component is unmounted
			window.removeEventListener("beforeunload", handleBeforeUnload)
		}
	}, [])

	return (
		<Wrapper ref={callbackRef}>
			<ReactTableContainerContext.Provider value={value}>
				{children}
			</ReactTableContainerContext.Provider>
		</Wrapper>
	)
}

export default AdminContainer
