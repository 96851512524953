import React, { useEffect } from "react"
import Axios from "axios"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormProvider, useForm } from "react-hook-form"
import { Flex } from "utils/components/style/flex"
import FSelect from "utils/form/FSelect"
import FText from "utils/form/FText"
import SuperModal from "utils/modals/SuperModal"
import Yup from "utils/yup"
import ErrorService from "@/services/ErrorService"
import { useDispatch } from "react-redux"
const schema = Yup.object().shape({})

const EditeurTag = ({
	isOpen,
	setIsOpen,
	toggleEditeur,
	selectedTag,
	fetchTags,
	isNew,
}) => {
	const dispatch = useDispatch()
	const defaultValues = {
		category: selectedTag?.category ?? null,
		label: selectedTag?.label ?? "",
	}
	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues,
	})

	useEffect(() => {
		if (isOpen) {
			methods.reset(defaultValues ?? schema.getDefault())
		}
	}, [isOpen])

	const onSubmit = async (values) => {
		try {
			if (isNew) {
				await Axios.post("/api/tag", {
					datas: {
						category: values.category,
						label: values.label,
					},
				})
			} else {
				await Axios.put(`/api/tag/${selectedTag.id}`, {
					datas: {
						category: values.category,
						label: values.label,
					},
				})
			}
			fetchTags()
			setIsOpen(!isOpen)
		} catch (error) {
			const errorMessage = `Echec de la création du tag : ${error}`
			ErrorService.error({
				component: "AdminTags:createTag",
				message: errorMessage,
				dispatch,
			})
		}
	}

	return (
		<FormProvider {...methods}>
			<SuperModal
				isOpen={isOpen}
				onClose={toggleEditeur}
				isNew={selectedTag?.id === null}
				title={selectedTag?.id === null ? "Ajout d'un tag" : "Edition du tag"}
				onClick={methods.handleSubmit(onSubmit)}
			>
				<Flex directionColumn gap="1rem">
					<FText name="label" label="Nom du tag" />
					<FSelect
						name="category"
						label="Type de tag"
						options={["stocks", "vehicules"]}
					/>
				</Flex>
			</SuperModal>
		</FormProvider>
	)
}

export default EditeurTag
