import Axios from "axios"
import { addMultiPolygons, setMultipolygons } from "./reducer"

const buildFormData = (multiPolygon) => {
	const formData = new FormData()
	formData.append("file", multiPolygon.file)
	delete multiPolygon.file
	formData.append("data", JSON.stringify(multiPolygon))
	const config = {
		headers: {
			"content-type": "multipart/form-data",
		},
	}
	return { formData, config }
}

const getAdminMultiPolygonGeojson = (id: string) => {
	return Axios.get("/api/multiPolygonsAdmin/" + id)
}

const getAdminMultiPolygons = async (dispatch, page = 1, perPage = 100) => {

	try {
		const result = await Axios.get(`/api/multiPolygonsAdmin?page=${page}&per_page=${perPage}`);
		const fetchedMultiPolygons = result.data.multiPolygons;
		const totalPages = result.data.total_pages;
		dispatch(addMultiPolygons(fetchedMultiPolygons));
		if (page < totalPages) {
			// If there are more pages, recursively call getAdminMultiPolygons for the next page

			return getAdminMultiPolygons(dispatch, page + 1, perPage);
		} else {
			// If all pages have been fetched, return the combined list of multiPolygons
			return null;
		}
	} catch (error) {
		// Handle error here if needed
		console.error(`Error fetching multiPolygons: ${error}`);
		throw error;
	}
};

const deleteAdminMultiPolygons = (id: string) => {
	return Axios.delete("/api/multiPolygonsAdmin/" + id)
}

const updateAdminMultiPolygons = (multiPolygon) => {
	const buildedMultiPolygon = buildFormData(multiPolygon)
	return Axios.put(
		"/api/multiPolygonsAdmin/" + multiPolygon.id,
		buildedMultiPolygon.formData,
		buildedMultiPolygon.config,
	)
}

const createAdminMultiPolygon = (multiPolygon) => {
	const buildedMultiPolygon = buildFormData(multiPolygon)
	return Axios.post(
		"/api/multiPolygonsAdmin",
		buildedMultiPolygon.formData,
		buildedMultiPolygon.config,
	)
}

export default {
	getAdminMultiPolygonGeojson,
	getAdminMultiPolygons,
	deleteAdminMultiPolygons,
	updateAdminMultiPolygons,
	createAdminMultiPolygon,
}
