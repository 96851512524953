import { updateUsers } from "@/redux-toolkit/admin/accounts/resources"
import { Formik } from "formik"
import Moment from "moment"
import React from "react"
import { useDispatch } from "react-redux"
import { Flex } from "@/utils/components/style/flex"
import FormikText from "@/utils/formik/FormikText"
import SuperModal from "@/utils/modals/SuperModal"
import useAdminRestrictions from "@/hooks/useAdminRestrictions"
import _ from "lodash"

const ModalDetailsUsers = (props) => {
	const dispatch = useDispatch()
	const adminRestrictions = useAdminRestrictions()
	const { user, isOpen, onClose } = props

	/* No rendering if user does not exist */
	if (!user) {
		return null
	}

	/* We Modifiy user in to format the dates */
	const modifiedUser = {
		...user,
		created_at: Moment(user.created_at).format("DD/MM/YYYY HH:mm"),
		current_sign_in_at: Moment(user.current_sign_in_at).format(
			"DD/MM/YYYY HH:mm",
		),
		last_sign_in_at: Moment(user.last_sign_in_at).format("DD/MM/YYYY HH:mm"),
		password: "",
	}

	const isDisabled = adminRestrictions.isRestricted &&
		['super-admin', 'admin'].includes(user.role) &&
		!_.isEqual(adminRestrictions.selfId, user.id)

	return (
		<Formik
			initialValues={modifiedUser ?? {}}
			enableReinitialize
			onSubmit={(values) => {
				dispatch(
					updateUsers({
						id: values.id,
						data: {
							email: values.email,
							password: values.password,
						},
					}),
				)
				onClose()
			}}
		>
			{(formik) => (
				<SuperModal
					isOpen={isOpen}
					onClose={onClose}
					isNew={!modifiedUser}
					title={"Modification User"}
					onClick={formik.handleSubmit}
					disableSave={isDisabled}
				>
					<Flex directionColumn gap="1rem">
						<FormikText name="email" label="Email" disabled={isDisabled} />
						<FormikText disabled name="role" label="Role" />
						<FormikText
							name="commune_name"
							disabled
							label="Nom de la commune"
						/>
						<FormikText name="cdc" disabled label="EPCI" />
						<FormikText name="commune_id" disabled label="Commune id" />
						<FormikText name="created_at" disabled label="Date de création" />
						<FormikText
							disabled
							name="current_sign_in_at"
							label="Actuel login date"
						/>
						<FormikText
							disabled
							name="last_sign_in_at"
							label="Dernier login date"
						/>
						<FormikText name="password" label="Mot de passe" disabled={isDisabled} />
					</Flex>
				</SuperModal>
			)}
		</Formik>
	)
}

export default ModalDetailsUsers
