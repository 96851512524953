import AddCircleOutline from "@mui/icons-material/AddCircleOutline"
import ClearIcon from "@mui/icons-material/Clear"
import { Card, Divider, IconButton, TextField } from "@mui/material"
import React from "react"
import { DragDropContext } from "react-beautiful-dnd"
import { useController, useFormContext, useWatch } from "react-hook-form"
import styled from "styled-components"
import { NeutralOutlinedButton } from "utils/components/style/button"
import { Flex, FlexHeader } from "utils/components/style/flex"
import { H4 } from "utils/components/style/header"
import SuperDraggable from "utils/dnd/SuperDraggable"
import SuperDragHandle from "utils/dnd/SuperDragHandle"
import SuperDroppable from "utils/dnd/SuperDroppable"
import SubsectionDraggable from "./SubsectionDraggable"

const SFlex = styled(Flex)`
    width: 100%;
    margin-bottom: 0.5rem;
`

const SCard = styled(Card)`
    width: 33%;
    padding: 1rem;
`

const DroppableComponent = styled(Flex)`
    margin-top: -0.5rem !important ;
    width: 100%;
`

const SFlexHeader = styled(FlexHeader)`
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
`

const SH4 = styled(H4)`
    margin-top: 0.5rem;
`

const DraggableListWithSubsections = ({ name, header = undefined }) => {
	const { control } = useFormContext()

	const {
		field: { value: items, onChange: onItemsChange },
	} = useController({
		name: name, //name of array in conseils (eg. Avant:[])
		control,
	})

	const watchedItems = useWatch({ control, name: `${name}` })

	const onDragEnd = (result) => {
		if (!result.destination) return

		const reordered = [...items]
		const [removed] = reordered.splice(result.source.index, 1)
		reordered.splice(result.destination.index, 0, removed)

		onItemsChange(reordered)
	}

	const onFieldChange = (event, fieldIndex) => {
		items[fieldIndex] = event.target.value

		const newItems = [...items]
		newItems[fieldIndex] = event.target.value

		onItemsChange(newItems)
	}

	const onClickAddItem = (isSubSection) => {
		isSubSection
			? onItemsChange([...items, { header: "", content: [] }])
			: onItemsChange([...items, ""])
	}

	const onClickDelete = (toDeleteIndex) => {
		onItemsChange(items.filter((item, index) => index !== toDeleteIndex))
	}

	return (
		<SCard>
			<SFlexHeader>
				<SH4>{header}</SH4>
				<NeutralOutlinedButton
					onClick={() => onClickAddItem(true)}
					endIcon={<AddCircleOutline />}
				>
					Sous-section
				</NeutralOutlinedButton>
				<NeutralOutlinedButton
					onClick={() => onClickAddItem(false)}
					endIcon={<AddCircleOutline />}
				>
					Point
				</NeutralOutlinedButton>
			</SFlexHeader>
			<DragDropContext onDragEnd={onDragEnd}>
				<SuperDroppable component={DroppableComponent} directionColumn>
					{watchedItems.map((item, index) => {
						if (typeof item === "string") {
							return (
								<SuperDraggable
									component={SFlex}
									key={index}
									index={index}
									id={`${index}`}
									fullHandle={false}
								>
									<SuperDragHandle />
									<TextField
										multiline
										value={item}
										onChange={(event) => {
											onFieldChange(event, index)
										}}
										maxRows={4}
										fullWidth
									/>
									<IconButton
										onClick={() => {
											onClickDelete(index)
										}}
									>
										<ClearIcon />
									</IconButton>
									<Divider draggable />
								</SuperDraggable>
							)
						} else {
							return (
								<SubsectionDraggable
									counselsPeriod={name}
									index={index}
									onClickDelete={onClickDelete}
								/>
							)
						}
					})}
				</SuperDroppable>
			</DragDropContext>
		</SCard>
	)
}

export default DraggableListWithSubsections
