import Axios from "axios"

const getDicrims = async () => {
	const res = await Axios.get("/api/picrim/dicrims")
	return res.data
}

const createDicrim = async (dicrim) => {
	const res = await Axios.post("/api/picrim/create_dicrim", {
		data: dicrim,
	})
	return res.data
}

const updateDicrim = async (dicrim) => {
	const res = await Axios.put("/api/picrim/update_dicrim", {
		data: dicrim,
	})
	return res.data
}

export default { getDicrims, createDicrim, updateDicrim }
