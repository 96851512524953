import DataObjectIcon from "@mui/icons-material/DataObject"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
	Accordion,
	AccordionActions,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from "@mui/material"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import JSONInput from "react-json-editor-ajrm"
import locale from "react-json-editor-ajrm/locale/en"
import styled from "styled-components"
import { PrimaryButton } from "utils/components/style/button"
import configPermissionsAdmin from "./configPermissionsAdmin"

const SAccordionDetails = styled(AccordionDetails)`
    display: flex;
    justify-content: center;
    overflow: auto;
`

const JSONInputForm = ({ currentSettings, setCurrentSettings }) => {
	const [JSONData, setJSONData] = useState(undefined)
	const [JSONError, setJSONError] = useState(false)

	useEffect(() => setJSONData(currentSettings), [currentSettings])

	const handleChange = (e) => {
		setJSONData(e.jsObject)
		setJSONError(e.error)
	}

	const handleValidateClick = () => {
		const newJSONData = _.cloneDeep(JSONData)

		configPermissionsAdmin.forEach((permission) => {
			const permissionPaths = permission?.path ?? []
			const readPermissionPath = permission?.readPermissionPath ?? false
			const writePermissionPath = permission?.writePermissionPath ?? false
			const childrenPaths = permission?.childrenPaths ?? false
			const parentPaths = permission?.parentPath ?? false

			const changedValue = permissionPaths.every((path) => {
				return _.get(newJSONData, path, false)
			})
			const currentValue = permissionPaths.every((path) => {
				return _.get(currentSettings, path, false)
			})

			if (permissionPaths.length > 1) {
				const valuesNotSame =
					!permissionPaths.every((path) => {
						return _.get(newJSONData, path, false)
					}) &&
					permissionPaths.some((path) => {
						return _.get(newJSONData, path, false)
					})
				let changedValue

				if (valuesNotSame) {
					permissionPaths.forEach((path) => {
						const currentValue = _.get(currentSettings, path)
						const newValue = _.get(newJSONData, path)
						const isChanged = currentValue !== newValue
						if (isChanged) {
							changedValue = newValue
						}
					})
					permissionPaths.forEach((path) =>
						_.set(newJSONData, path, changedValue),
					)
				}
			}

			if (childrenPaths) {
				if (!changedValue && changedValue !== currentValue) {
					childrenPaths.forEach((childrenPath) => {
						_.set(newJSONData, childrenPath, false)
					})
				}
			}

			if (parentPaths) {
				const parentValue = parentPaths.every((path) => {
					return _.get(newJSONData, path, false)
				})
				if (parentValue === false) {
					permissionPaths.forEach((permissionPath) =>
						_.set(newJSONData, permissionPath, false),
					)
				}
			}

			if (readPermissionPath && changedValue && changedValue !== currentValue) {
				if (parentPaths) {
					const parentValue = parentPaths.every((path) => {
						return _.get(newJSONData, path, false)
					})
					if (parentValue) {
						_.set(newJSONData, readPermissionPath, true)
					}
				} else {
					_.set(newJSONData, readPermissionPath, true)
				}
			}

			if (
				writePermissionPath &&
				!changedValue &&
				changedValue !== currentValue
			) {
				if (parentPaths) {
					const parentValue = parentPaths.every((path) => {
						return _.get(newJSONData, path, false)
					})
					if (parentValue) {
						writePermissionPath.forEach((path) =>
							_.set(newJSONData, path, false),
						)
					}
				} else {
					writePermissionPath.forEach((path) => _.set(newJSONData, path, false))
				}
			}
		})
		setCurrentSettings(newJSONData)
	}

	return (
		<Accordion defaultExpanded>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<DataObjectIcon sx={{ mr: 1 }} />
				<Typography>Aperçu des permissions au format .JSON</Typography>
			</AccordionSummary>
			<SAccordionDetails>
				<JSONInput
					id="a_unique_id"
					placeholder={JSONData}
					locale={locale}
					height="450px"
					onChange={(e) => handleChange(e)}
					waitAfterKeyPress={500}
				/>
			</SAccordionDetails>
			<AccordionActions>
				<PrimaryButton
					disabled={JSONData === currentSettings || JSONError}
					onClick={() => handleValidateClick()}
				>
					Valider JSON
				</PrimaryButton>
			</AccordionActions>
		</Accordion>
	)
}

export default JSONInputForm
