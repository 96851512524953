import React, { useState, useEffect, useMemo } from "react"
import Axios from "axios"
import SuperReactTable from "@/utils/components/tables/SuperReactTable/SuperReactTable"
import {
	GreenButton,
	PrimaryButton,
	RedButton,
} from "@/utils/components/style/button"
import Moment from "moment"
import styled from "styled-components"
import { Link } from "react-router-dom"
import useHumans from "@/hooks/useHumans"
import useAdminRestrictions from "@/hooks/useAdminRestrictions"
import AdminTopBar from "./AdminTopBar"
import Button from "@/styles/atoms/Button"

const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
`

const AdminError = () => {
	const [errors, setErrors] = useState([])
	const adminRestrictions = useAdminRestrictions()

	useEffect(() => {
		fetchErrors()
	}, [])

	const _refresh = () => {
		fetchErrors()
	}

	const fetchErrors = async () => {
		const res = await Axios.get("/api/clientErrors")
		setErrors(res.data.clientErrors.reverse())
	}

	const onDeleteClick = (original) => {
		const deleteErrors = async () => {
			await Axios.delete(`/api/clientErrors/${original.id}`)
			const res = await Axios.get("/api/clientErrors")
			setErrors(res.data.clientErrors)
		}

		deleteErrors()
	}

	const deleteAll = async () => {
		errors.forEach((error) => {
			Axios.delete(`/api/clientErrors/${error.id}`)
		})
		const res = await Axios.get("/api/clientErrors")
		setErrors(res.data.clientErrors)
	}

	const columns = useMemo(
		() => [
			{
				header: "Message",
				accessorKey: "message",
			},
			{
				header: "Params",
				accessorKey: "params",
				size: 800,
			},
			{
				header: "Composant",
				accessorKey: "component",
			},
			{
				header: "Mis à jour le",
				accessorKey: "created_at",
				cell: ({ row }) => {
					return Moment(row.original.created_at).format("DD/MM/YYYY HH:mm")
				},
			},
			{
				header: "Identifiant de l'utilisateur",
				accessorKey: "user_id",
			},
			{
				header: "Commune de l'utilisateur",
				accessorKey: "commune_name",
			},
		],
		[],
	)
	console.log(errors)
	return (
		<div>
			<AdminTopBar title="Journal des erreurs">
				<Button $backgroundColor="var(--green500)" $textColor="white" onClick={_refresh}>Actualiser</Button>
				{!adminRestrictions.isRestricted && (
					<Button color="primary" onClick={deleteAll}>Tout supprimer</Button>
				)}
			</AdminTopBar>
			<SuperReactTable
				data={errors}
				columns={columns}
				isEditable={false}
				isDeletable={!adminRestrictions.isRestricted}
				onDeleteClick={onDeleteClick}
				initialSortBy={[
					{
						id: "created_at",
						desc: true,
					},
				]}
			/>
		</div>
	)
}

export default AdminError
