import { Autocomplete, TextField } from "@mui/material"
import _ from "lodash"
import React from "react"
import { useController, useFormContext } from "react-hook-form"
import DraggableFreeTagsChips from "./DraggableFreeTagsChips"

function FFreeTags({
	name,
	label,
	highlight = [],
	disabled = false,
	readOnly = false,
}) {
	const { control } = useFormContext()

	const {
		field: { value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
		defaultValue: [],
	})

	const count = _.countBy(highlight)

	const onDragEnd = ({ source, destination }) => {
		if (!destination) return

		const reordered = [...value]
		const [removed] = reordered.splice(source.index, 1)
		reordered.splice(destination.index, 0, removed)

		onChange(reordered)
	}

	return (
		<Autocomplete
			disableClearable={readOnly}
			disabled={disabled}
			multiple
			selectOnFocus
			clearOnBlur
			handleHomeEndKeys
			freeSolo
			fullWidth
			value={value}
			options={[]}
			onChange={(event, newValue) => {
				onChange(
					newValue.map((val) => {
						if (typeof val === "string") return val
						return val.add
					}),
				)
			}}
			filterOptions={(options, params) => {
				if (params.inputValue !== "") {
					return [{ add: params.inputValue }]
				}

				return []
			}}
			getOptionLabel={(option) => {
				if (typeof option === "string") {
					return option
				}
				return `Ajouter "${option.add}"`
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					error={Boolean(error?.message)}
					helperText={error?.message}
					InputProps={{
						...params.InputProps,
						readOnly: readOnly,
					}}
				/>
			)}
			renderTags={(value, getTagProps) => (
				<DraggableFreeTagsChips
					count={count}
					value={value}
					getTagProps={getTagProps}
					onDragEnd={onDragEnd}
					readOnly={readOnly}
				/>
			)}
		/>
	)
}

export default FFreeTags
