import { FormGroup, TextField } from "@mui/material"
import { connect, Field, getIn, ErrorMessage } from "formik"
import React from "react"
import styled, { css } from "styled-components"
import { StyledFormGroup } from "utils/components/style/form"

const SStyledFormGroup = styled(StyledFormGroup)`
    width: 100% !important;
    ${(props: any) =>
			props.noPadding &&
			css`
            padding: 0px !important;
            padding-top: 16px !important;
        `};
` as any

const STextField = styled(TextField)`
    height: inherit !important;
`

/**
 * # FormikText
 * A formik component used insinde a <Formik /> object
 *
 * **name** : name used in the values
 *
 * **label** : label used to display the field
 *
 * **fulWidth** : boolean to make the field full width
 *
 * **type** : the type of field it must be, usually text
 */
const FormikText = ({
	name,
	type,
	label,
	multiline,
	disabled,
	customStyle,
	fullWidth = true,
	formik,
	autoFocus = false,
	submitFormOnChange = false,
	sanitize = false,
	minRows = 2,
	noPadding = false,
}) => {
	const error = getIn(formik.errors, name)
	const touch = getIn(formik.touched, name)

	return (
		<SStyledFormGroup fullwidth={fullWidth} noPadding={noPadding}>
			<Field type={type} name={name}>
				{({ field, form, meta }) => (
					<STextField
						style={customStyle}
						error={touch && error}
						helperText={touch && error}
						fullWidth={fullWidth}
						variant="outlined"
						type={type}
						autoFocus={autoFocus}
						name={name}
						value={field.value ?? ""}
						label={label}
						disabled={disabled}
						multiline={multiline}
						minRows={minRows}
						onChange={(e) => {
							let value = e.target.value
							form.setFieldValue(field.name, value)
							if (submitFormOnChange) {
								form.submitForm()
							}
						}}
					/>
				)}
			</Field>
		</SStyledFormGroup>
	)
}

export default connect(FormikText) as any
