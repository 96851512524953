import { ColumnDef } from "@tanstack/react-table"
import { getDicrims } from "@/redux-toolkit/data/dicrim/resources"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { PrimaryButton, RedButton } from "utils/components/style/button"
import { Flex, FlexHeader } from "utils/components/style/flex"
import { H2 } from "utils/components/style/header"
import SuperReactTable from "utils/components/tables/SuperReactTable"
import CreateDicrimModal from "./CreateDicrimModal"

const AdminDicrim = () => {
	const history = useHistory()
	const dispatch = useDispatch()
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [selectedDicrimId, setSelectedDicrimId] = useState(undefined)

	const dicrims = useSelector((state: any) => state.data.dicrims)

	useEffect(() => {
		dispatch(getDicrims)
	}, [])

	const toggleIsModalOpen = () => setIsModalOpen(!isModalOpen)

	const data = useMemo(() => Object.values(dicrims), [dicrims])

	const columns = useMemo(
		() =>
			[
				{
					header: "Id",
					accessorKey: "id",
				},
				{
					header: "Type de crise",
					accessorKey: "title",
				},
			] as ColumnDef<any>[],
		[],
	)

	return (
		<>
			<FlexHeader>
				<H2>Edition DICRIM Numérique</H2>
				<Flex gap="1rem">
					<RedButton onClick={history.goBack}>Fermer</RedButton>
					<PrimaryButton
						onClick={() => {
							setSelectedDicrimId(undefined)
							setIsModalOpen(true)
						}}
					>
						Créer
					</PrimaryButton>
				</Flex>
			</FlexHeader>
			<SuperReactTable
				data={data}
				columns={columns}
				onEditClick={(original: any) => {
					setSelectedDicrimId(original.id)
					setIsModalOpen(true)
				}}
			/>
			<CreateDicrimModal
				isOpen={isModalOpen}
				onClose={toggleIsModalOpen}
				selectedDicrimId={selectedDicrimId}
			/>
		</>
	)
}

// -- Todo
/* 
    -> What information to display
    -> toggle concerned communes ?
    -> Map data to view in PICRIM
    -> Seismic classification
    -> Sections

*/

export default AdminDicrim
