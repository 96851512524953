import { toast } from "react-toastify"
import Axios from "axios"
import { addUsers, addCommunes, addCdcs } from "./reducer"
import ErrorService from "@/services/ErrorService"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const getUsers = createAsyncThunk(
	"admin/accounts/getUsers",
	async (args, { dispatch }) => {
		try {
			const res = await Axios.get("/api/users")
			if (res.data.status !== 200) {
				throw new Error(`Request failed with status : ${res.data.status}`)
			}
			const users = JSON.parse(res.data.users)
			dispatch(addUsers(users))
		} catch (error) {
			const errorMessage = `Erreur, les utilisateurs n'ont pas pu être récupéré, message d'erreur: ${error}`
			ErrorService.error({
				component: "resources:getUsers",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const getCommunes = createAsyncThunk(
	"admin/accounts/getCommunes",
	async (args, { dispatch }) => {
		try {
			const res = await Axios.get("/api/communes")
			if (res.data.status !== 200) {
				throw new Error(`Request failed with status : ${res.data.status}`)
			}
			const communes = JSON.parse(res.data.communes)
			dispatch(addCommunes(communes))
		} catch (error) {
			const errorMessage = `Erreur, les utilisateurs n'ont pas pu être récupéré, message d'erreur: ${error}`
			ErrorService.error({
				component: "resources:getCommunes",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const createCommune = createAsyncThunk(
	"admin/accounts/createCommune",
	async ({ codeInsee }: { codeInsee: string }, { dispatch }) => {
		try {
			const res = await Axios.post("/api/create_communes_admin", { codeInsee })
			dispatch(addCommunes([res.data.commune]))
			dispatch(addCdcs([res.data.cdc]))
			dispatch(addUsers([res.data.user]))
		} catch (error) {
			const errorMessage = `Erreur, la commune n'a pas pu être créée, message d'erreur: ${error}`
			ErrorService.error({
				component: "resources:createCommune",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const getCdcs = createAsyncThunk(
	"admin/accounts/getCdcs",
	async (args, { dispatch }) => {
		try {
			const res = await Axios.get("/api/cdcs")
			dispatch(addCdcs(res.data))
		} catch (error) {
			ErrorService.error({
				component: "resources:getCdcs",
				message: `Erreur les cdcs n'ont pas pu être récupéré, message d'erreur: ${error}`,
				dispatch,
			})
		}
	},
)

export const updateUsers = createAsyncThunk(
	"admin/accounts/updateUsers",
	async ({ id, data }: any, { dispatch }) => {
		try {
			const res = await Axios.post(`/api/users/${id}`, {
				email: data.email,
				password: data.password,
			})
			if (res.data.status !== 200) {
				throw new Error(
					`Request failed with status : ${res.data.status} and msg: ${res.data.msg}`,
				)
			}
			dispatch(getUsers())
		} catch (error) {
			const errorMessage = `Erreur lors de la mise a jour de l'utilisateur, message d'erreur : ${error}`
			ErrorService.error({
				component: "resources:updateUsers",
				message: errorMessage,
				dispatch,
			})
		}
	},
)
