import AddCircleOutline from "@mui/icons-material/AddCircleOutline"
import { Card, IconButton, MenuItem, Select, TextField } from "@mui/material"
import { Box } from "@mui/system"
import { ColumnDef } from "@tanstack/react-table"
import _ from "lodash"
import React, { useMemo } from "react"
import { useController, useFormContext } from "react-hook-form"
import styled from "styled-components"
import { FlexHeader } from "utils/components/style/flex"
import { H4 } from "utils/components/style/header"
import SuperReactTable from "utils/components/tables/SuperReactTable"
import { width30 } from "utils/components/tables/widthProps"
import FSelect from "utils/form/FSelect"
import { Vigilance, VigilanceColor } from "utils/types/IFiche"
import FDraggableList from "./FDraggableList"

const SDiv = styled.div`
    margin-top: 1rem;
`

const SCard = styled(Card)`
    padding: 1rem;
`

const SFlexHeader = styled(FlexHeader)`
    justify-content: flex-start;
    align-items: center;
`

const ColorBox = styled(Box)<{ color: string }>`
    background-color: ${(props) => props.color};
    min-height: 16px !important;
    width: 100% !important;
`

const SMenuItem = styled(MenuItem)`
    display: flex !important;
`

const SIconButton = styled(IconButton)`
    margin-top: -0.5rem !important;
`

const colors = [
	VigilanceColor[Vigilance.BLUE],
	VigilanceColor[Vigilance.GREEN],
	VigilanceColor[Vigilance.YELLOW],
	VigilanceColor[Vigilance.ORANGE],
	VigilanceColor[Vigilance.RED],
]

// probably not default values, maybe try similar to DraggableList (without drag, + button adds new vigilance level, max 4 levels ?)

const VigilanceCard = () => {
	const { control } = useFormContext()
	const {
		field: { value: vigilanceLevels, onChange: onChangeLevels },
	} = useController({ name: "vigilances.levels", control })

	const handleChangeText = (event, index) => {
		vigilanceLevels[index].text = event.target.value
	}

	const onClickAddLevel = () => {
		const levelToAdd = {
			level: vigilanceLevels.length + 1,
			color: VigilanceColor[Vigilance.GREEN],
			text: "",
		}

		onChangeLevels([...vigilanceLevels, levelToAdd])
	}

	const onClickDeleteLevel = (original) => {
		const indexToDelete = original.level - 1

		const newLevels = vigilanceLevels.filter(
			(level, index) => index !== indexToDelete,
		)

		newLevels.forEach((level) => {
			level.level--
		})

		onChangeLevels(newLevels)
	}

	const onChangeColor = (event, props) => {
		const newVigilanceLevels = _.cloneDeep(vigilanceLevels)

		newVigilanceLevels[props.row.index].color = event.target.value

		onChangeLevels(newVigilanceLevels)
	}

	const data = useMemo(() => [...vigilanceLevels], [vigilanceLevels])

	const columns = useMemo(
		() =>
			[
				{
					header: "Niveau",
					...width30,
					cell: (props) => {
						return <>{props.row.index + 1}</>
					},
					disableSortBy: true,
				},
				{
					header: "Couleur",
					accessorKey: "color",
					...width30,
					cell: (props) => {
						return (
							<Select
								value={props.row.original.color}
								onChange={(event) => onChangeColor(event, props)}
							>
								{colors.map((color) => (
									<SMenuItem key={color} value={color}>
										<ColorBox color={color} />
									</SMenuItem>
								))}
							</Select>
						)
					},
					enableSorting: false,
				},
				{
					header: "Texte",
					accessorKey: "text",
					cell: (props) => {
						return (
							<TextField
								defaultValue={props.row.original.text}
								onChange={(event) => {
									handleChangeText(event, props.row.index)
								}}
							/>
						)
					},
					enableSorting: false,
				},
			] as ColumnDef<any>[],
		[vigilanceLevels],
	)

	return (
		<>
			<SCard>
				<SFlexHeader>
					<H4>Niveaux de vigilances</H4>
					<SIconButton
						onClick={onClickAddLevel}
						disabled={vigilanceLevels.length > 3}
					>
						<AddCircleOutline />
					</SIconButton>
				</SFlexHeader>
				<SuperReactTable
					simpleTable
					data={data}
					columns={columns}
					showBottom={false}
					selectable={false}
					isEditable={false}
					onDeleteClick={onClickDeleteLevel}
				/>
				<FDraggableList name={"vigilances.links"} header={"Liens"} />
				<SDiv>
					<FSelect
						size="small"
						name={"classification"}
						label="Classification sismique (facultatif)"
						options={["1", "2", "3", "4", "5"]}
					/>
				</SDiv>
			</SCard>
		</>
	)
}

export default VigilanceCard
