import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import { Autocomplete, Chip, TextField } from "@mui/material"
import React, { useMemo } from "react"
import { DragDropContext } from "react-beautiful-dnd"
import { useController, useFormContext } from "react-hook-form"
import { Flex } from "@/utils/components/style/flex"
import SuperDraggable from "@/utils/dnd/SuperDraggable"
import SuperDroppable from "@/utils/dnd/SuperDroppable"
import { ISortBy } from "@/utils/types/IJsonSchema"

const EditeurTemplateSortBy = ({
	name = "sort_by",
	dependencie = "template.properties",
	disabled = false
}) => {
	const { control, watch } = useFormContext()

	const {
		field: { value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required: true },
		defaultValue: [],
	})

	const properties = watch(dependencie)

	const options = useMemo(() => {
		return properties.map((prop) => ({
			property: prop.label,
			desc: false,
		}))
	}, [properties])

	const getOptionLabel = (option) => {
		return option.property
	}
	const isOptionEqualToValue = (option: ISortBy, value: ISortBy) => {
		return option.property === value.property
	}

	const changeSortDir = (option) => {
		onChange(
			value.map((item) =>
				isOptionEqualToValue(item, option)
					? { ...item, desc: !item.desc }
					: item,
			),
		)
	}

	const onDragEnd = (result) => {
		if (!result.destination) return

		const reordered = [...value]
		const [removed] = reordered.splice(result.source.index, 1)
		reordered.splice(result.destination.index, 0, removed)

		onChange(reordered)
	}

	return (
		<Autocomplete
			fullWidth
			multiple
			renderInput={(params) => (
				<TextField
					{...params}
					label="Trier par"
					error={Boolean(error)}
					helperText={error?.message}
				/>
			)}
			value={value}
			onChange={(e, newValue) => {
				if (disabled) return
				onChange(newValue)
			}}
			isOptionEqualToValue={isOptionEqualToValue}
			options={options}
			getOptionLabel={getOptionLabel}
			renderTags={(value: readonly string[], getTagProps) => (
				<DragDropContext onDragEnd={onDragEnd}>
					<SuperDroppable
						component={Flex}
						droppableProps={{ direction: "horizontal" }}
						gap="5px"
					>
						{(value ?? []).map((option: any, index: number) => {
							const label = getOptionLabel(option)
							const Icon = option.desc ? ArrowDownwardIcon : ArrowUpwardIcon

							return (
								<SuperDraggable key={label} id={label} index={index}>
									<Chip
										label={label}
										icon={
											<Icon
												style={{
													cursor: "pointer",
												}}
												onClick={() => changeSortDir(option)}
											/>
										}
										{...getTagProps({ index })}
										className={undefined}
										style={{
											cursor: "grab",
										}}
									/>
								</SuperDraggable>
							)
						})}
					</SuperDroppable>
				</DragDropContext>
			)}
			disabled={disabled}
		/>
	)
}

export default EditeurTemplateSortBy
