import React, { useState, useMemo } from "react"
import Debug from "debug"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import Axios from "axios"
import RiskService from "@/utils/services/RiskService"
import {
	List,
	ListItemText,
	ListSubheader,
	Checkbox,
	ListItemButton,
} from "@mui/material"
import { Flex } from "@/utils/components/style/flex"
import ConfirmationModal from "@/utils/components/ConfirmationModal"
import AdminTopBar from "./AdminTopBar"
import Button from "@/styles/atoms/Button"
import useAdminCommunes from "@/hooks/admin/useAdminCommunes"

// app:javascript:components:admin:AdminArretes.tsx
const debug = Debug("app:javascript:components:admin:AdminArretes")
debug.log = console.log.bind(console)

const AdminArretes = () => {
	const history = useHistory()
	const communes = useAdminCommunes()
	const risks = RiskService.getRisques()
	const [riskList, setRiskList] = useState([])
	const [communeList, setCommuneList] = useState([])
	const [isOpenModal, setIsOpenModal] = useState(false)

	const ordonnedRisks = useMemo(() => {
		const res = {}
		Object.keys(risks).forEach((category) =>
			res[category] = risks[category].sort((a: string, b: string) => {
				const nameA = a.toUpperCase()
				const nameB = b.toUpperCase()
				return nameA.localeCompare(nameB)
			})
		)
		return res
	}, [risks])

	return (
		<>
			<AdminTopBar title="Transfert de fiche scénario">
				<Button color="primary" onClick={() => setIsOpenModal(true)} disabled={!riskList.length || !communeList.length}>
					Copier la sélection vers les communes choisies
				</Button>
			</AdminTopBar>
			<Flex spaceBetween alignItemsInitial>
				<List>
					<Flex alignItemsStart>
						{Object.keys(ordonnedRisks).map((riskCategory) => (
							<Flex directionColumn alignItemsStart>
								<ListSubheader>{riskCategory}</ListSubheader>
								{ordonnedRisks[riskCategory].map((subRisk) => (
									<ListItemButton
										key={subRisk}
										onClick={() => {
											if (riskList.includes(subRisk)) {
												setRiskList(
													riskList.filter(
														(currentRisk) => currentRisk !== subRisk,
													),
												)
											} else {
												setRiskList([...riskList, subRisk])
											}
										}}
									>
										<Checkbox checked={riskList.includes(subRisk)} />
										<ListItemText primary={subRisk} />
									</ListItemButton>
								))}
							</Flex>
						))}
					</Flex>
				</List>
				<List style={{ borderLeft: "2px dashed #bbb" }}>
					<ListSubheader>Communes</ListSubheader>
					{Object.keys(communes).map((commune) => (
						<ListItemButton
							key={commune}
							onClick={() => {
								if (communeList.includes(commune)) {
									setCommuneList(
										communeList.filter(
											(currentCommune) => currentCommune !== commune,
										),
									)
								} else {
									setCommuneList([...communeList, commune])
								}
							}}
						>
							<Checkbox checked={communeList.includes(commune)} />
							<ListItemText primary={communes[commune].name} />
						</ListItemButton>
					))}
				</List>
			</Flex>
			<ConfirmationModal
				modalShown={isOpenModal}
				onClose={() => setIsOpenModal(false)}
				message="Etes vous certain de vouloir copier les fiches ?"
				onConfirm={() => {
					Axios.post("/api/fiches/admin_duplicate", {
						risks: riskList,
						communeIds: communeList,
					})
					history.push("/admin")
				}}
			/>
		</>
	)
}

export default AdminArretes
