import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Debug from "debug"
import styled, { css } from "styled-components"
import Moment from "moment"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import { Flex } from "utils/components/style/flex"
import getImage from "@/utils/getImage"
import { SFlex, StyledImage } from "./FImageMarkerPickerPopover.styled"
import { SectionTitle } from "@/pages/maincourante/care/CareResumeCard"
import Axios from "axios"
import { useEffectOnce } from "react-use"

// app:javascript:utils:form:admin:FImageMarkerPicker:FImageMarkerPickerPopover:FImageMarkerPickerPopover.tsx
const debug = Debug(
	"app:javascript:utils:form:admin:FImageMarkerPicker:FImageMarkerPickerPopover:FImageMarkerPickerPopover",
)
debug.log = console.log.bind(console)

interface Props {
	handleClose: () => void
	onChange: (value: string) => void
	value: string
}

const FImageMarkerPickerPopover = ({ handleClose, onChange, value }: Props) => {
	const jsonSchemaWithStatics = useJsonSchemasWithStatics()
	const [imageMarkers, setImageMarkers] = useState([])

	useEffectOnce(() => {
		const fetch = async () => {
			const response = await Axios.get("/api/image_marker")
			setImageMarkers(response.data)
		}
		fetch()
	})

	const groupedImgIds = _.groupBy(imageMarkers, "category")

	return (
		<SFlex directionColumn alignItemsStart gap="2rem">
			{Object.keys(groupedImgIds).map((subCategory) => {
				return (
					<Flex key={subCategory} directionColumn alignItemsStart fullWidth>
						<SectionTitle>{subCategory}</SectionTitle>
						<Flex $wrap={true}>
							{groupedImgIds[subCategory].map((element) => {
								return (
									<Flex
										key={element.imgId}
										onClick={() => {
											onChange(element.id)
											handleClose()
										}}
										directionColumn
									>
										<StyledImage
											$selected={value === element.id}
											src={getImage(element.id)}
										/>
									</Flex>
								)
							})}
						</Flex>
					</Flex>
				)
			})}
		</SFlex>
	)
}

export default FImageMarkerPickerPopover
