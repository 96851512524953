import React, { useMemo, useState } from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import { H1, H2 } from "utils/components/style/header"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import EditPermissionsModal from "./EditPermissionsModal"
import { Flex, FlexSpaced } from "utils/components/style/flex"
import { RedButton } from "utils/components/style/button"
import { useHistory } from "react-router-dom"
import AdminTopBar from "./AdminTopBar"

const StyledSuperReactTable = styled.div`
    flex-grow: 1;
`
const Options = styled.div`
    display: flex;
`
const RadioContainer = styled.div`
    margin-right: 2em;
`

const AccessRights = ({ users, communes }) => {
	if (!users) {
		return null
	}
	const [isOpenEditPermissionModal, setIsOpenEditPermissionModal] =
		useState(false)
	const [currentUser, setCurrentUser] = useState(undefined)
	const history = useHistory()

	// const [data, setData] = useState([])

	const usersList = useMemo(
		() =>
			Object.keys(users).map((id) => ({
				...users[id],
				commune: communes[users[id].commune_id],
			})),
		[users],
	)

	const columns = useMemo(
		() => [
			{
				header: "Nom commune",
				accessorKey: "commune_name",
			},
			{
				header: "Nom de la cdc",
				accessorKey: "cdc",
			},
			{
				header: "Prénom",
				accessorKey: "first_name",
			},
			{
				header: "Email",
				accessorKey: "email",
			},
		],
		[],
	)

	return (
		<div>
			<AdminTopBar title="Permissions" />
			<StyledSuperReactTable>
				<SuperReactTable
					data={usersList}
					columns={columns}
					isDeletable={false}
					onEditClick={(user) => {
						setCurrentUser(user)
						setIsOpenEditPermissionModal(true)
					}}
					disabledSearchProperties={[
						"commune",
						"commune_id",
						"created_at",
						"current_sign_in_at",
						"id",
						"last_name",
						"last_sign_in_at",
						"permission",
						"role",
						"settings",
					]}
				/>
			</StyledSuperReactTable>
			<EditPermissionsModal
				isOpen={isOpenEditPermissionModal}
				onClose={() => setIsOpenEditPermissionModal(!isOpenEditPermissionModal)}
				currentUser={currentUser}
			/>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		users: state.admin?.users?.users,
		communes: state.admin?.users?.communes,
	}
}

export default connect(mapStateToProps, null)(AccessRights)
