import SettingsIcon from "@mui/icons-material/Settings"
import { Autocomplete, IconButton, Popover, TextField } from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import { useController, useFormContext } from "react-hook-form"
import styled from "styled-components"
import { Flex } from "utils/components/style/flex"
import VerticalAutocomplete from "utils/components/VerticalAutocomplete"
import { IJsonSchemaProperty } from "@/utils/types/IJsonSchema"
import IMappingSchema from "utils/types/IMapping/IMappingSchema"
import CheckIcon from "@mui/icons-material/Check"
import KeyIcon from "@mui/icons-material/Key"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import mappingTools, {
	mappingToolInstanceValidator,
} from "./mappingTools/mappingTools"
import { MAPPING_TOOL_KEY } from "./mappingTools/text/key"

const SFlex = styled(Flex)`
    min-width: 400px;
`

const FMappingFieldEditor = ({
	name,
	property,
	fields,
	withKey,
}: {
	name: string
	property: IJsonSchemaProperty
	fields: string[]
	withKey: boolean
}) => {
	const { control } = useFormContext<IMappingSchema>()

	const {
		field: { value, onChange },
		fieldState: { error },
	} = useController({
		name,
		control,
	})

	const availableMappingTools = useMemo(
		() =>
			Object.values(mappingTools).filter(
				(mapping) =>
					mapping.outputs.includes(property.type) &&
					(withKey || mapping.id !== MAPPING_TOOL_KEY),
			),
		[property.type],
	)

	const selectedMappingTool = mappingTools[value?.converter]

	const [anchorEl, setAnchorEl] = useState(null)
	const handleClick = (event) => setAnchorEl(event.currentTarget)
	const handleClose = () => setAnchorEl(null)
	const open = Boolean(anchorEl)

	useEffect(() => {
		if (!value) return

		const singleInputs = value?.singleInputs
			? Object.fromEntries(
					Object.entries(value.singleInputs).filter(([, val]) =>
						fields.includes(val),
					),
			  )
			: undefined

		const arrayInputs = value?.arrayInputs
			? Object.fromEntries(
					Object.entries(value.arrayInputs)
						.map(([key, val]) => [
							key,
							val.filter((item) => fields.includes(item)),
						])
						.filter(([, val]) => val.length),
			  )
			: undefined

		onChange({
			...value,
			...(singleInputs && { singleInputs }),
			...(arrayInputs && { arrayInputs }),
		})
	}, [fields])

	const [status, setStatus] = useState<"valid" | "incomplete">()
	useEffect(() => {
		if (!value?.converter) return setStatus(undefined)

		mappingToolInstanceValidator
			.validate(value)
			.then(() => setStatus("valid"))
			.catch(() => setStatus("incomplete"))
	}, [value])

	useEffect(() => {
		if (!value) return
		if (!mappingTools[value.converter]?.outputs?.includes(property.type))
			onChange({ converter: undefined })
	}, [property])

	return (
		<>
			<Flex gap={5}>
				{status === "valid" && <CheckIcon htmlColor="var(--success)" />}
				{status === "incomplete" && <MoreHorizIcon htmlColor="var(--info)" />}
				{status === "valid" && value?.converter === MAPPING_TOOL_KEY && (
					<KeyIcon htmlColor="var(--purple)" />
				)}
				<IconButton onClick={handleClick} color={error ? "error" : undefined}>
					<SettingsIcon />
				</IconButton>
			</Flex>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<SFlex directionColumn alignItemsStretch gap>
					<Autocomplete
						options={availableMappingTools.map((item) => item.id)}
						getOptionLabel={(option) => mappingTools[option].name}
						value={value?.converter ?? null}
						onChange={(event, newValue) => onChange({ converter: newValue })}
						renderInput={(params) => (
							<TextField {...params} label={"Outil de conversion"} />
						)}
					/>
					{selectedMappingTool &&
						Object.keys(selectedMappingTool.config).length > 0 && (
							<Flex gap={8} directionColumn alignItemsStretch>
								<div>Configuration</div>
								{Object.entries(selectedMappingTool.config).map(
									([id, label]) => (
										<TextField
											size="small"
											key={id}
											label={label}
											value={value?.config?.[id] ?? ""}
											onChange={(event) =>
												onChange({
													...value,
													config: {
														...value.config,
														[id]: event.target.value
															? event.target.value
															: undefined,
													},
												})
											}
										/>
									),
								)}
							</Flex>
						)}
					{selectedMappingTool &&
						Boolean(
							Object.keys(selectedMappingTool.singleInputs) ||
								Object.keys(selectedMappingTool.arrayInputs),
						) && (
							<Flex gap={8} directionColumn alignItemsStretch>
								<div>Paramètres</div>
								{Object.entries(selectedMappingTool.singleInputs).map(
									([id, label]) => (
										<Autocomplete
											size="small"
											key={id}
											options={fields}
											value={value?.singleInputs?.[id] ?? null}
											onChange={(event, newValue) =>
												onChange({
													...value,
													singleInputs: {
														...value.singleInputs,
														[id]: newValue,
													},
												})
											}
											renderInput={(params) => (
												<TextField {...params} label={label} />
											)}
										/>
									),
								)}
								{Object.entries(selectedMappingTool.arrayInputs).map(
									([id, label]) => (
										<VerticalAutocomplete
											disableCloseOnSelect
											size="small"
											key={id}
											options={fields}
											multiple={true}
											value={value?.arrayInputs?.[id] ?? []}
											onChange={(event, newValue) =>
												onChange({
													...value,
													arrayInputs: {
														...value.arrayInputs,
														[id]: newValue,
													},
												})
											}
											renderInput={(params) => (
												<TextField {...params} label={label} />
											)}
										/>
									),
								)}
							</Flex>
						)}
				</SFlex>
			</Popover>
		</>
	)
}

export default FMappingFieldEditor
