import Axios from "axios"
import React, { useEffect, useMemo, useState } from "react"
import { PrimaryButton, RedButton } from "utils/components/style/button"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import ErrorService from "@/services/ErrorService"
import ModalUpdates from "@/pages/admin/ModalUpdates"
import { useDispatch } from "react-redux"
import { Flex, FlexSpaced } from "utils/components/style/flex"
import { useHistory } from "react-router-dom"
import { H2 } from "utils/components/style/header"
import { FormProvider, useForm } from "react-hook-form"
import Yup from "utils/yup"
import { yupResolver } from "@hookform/resolvers/yup"
import moment from "moment"
import { width180 } from "utils/components/tables/widthProps"
import ReactHtmlParser from "react-html-parser"
import styled from "styled-components"
import Moment from "moment"
import useAdminRestrictions from "@/hooks/useAdminRestrictions"
import AdminTopBar from "./AdminTopBar"
import Button from "@/styles/atoms/Button"

const SDiv = styled.div`
	max-height: 96px;
	overflow: hidden;
`
const schema = Yup.object().shape({
	datetime: Yup.string()
		.required("Veuillez renseigner une date")
		.default(Moment().format("YYYY-MM-DD HH:mm")),
	description: Yup.string()
		.required("Veuillez ajouter une description")
		.default(""),
})

const AdminUpdate = () => {
	const [datas, setDatas] = useState([])
	const [isOpen, setIsOpen] = useState(false)
	const [currentMaj, setCurrentMaj] = useState(undefined)
	const [isNew, setIsNew] = useState(true)
	const history = useHistory()
	const dispatch = useDispatch()
	const adminRestrictions = useAdminRestrictions()

	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: schema.getDefault(),
	})

	const fetchMajs = async () => {
		try {
			const response = await Axios.get("/api/maj")
			setDatas(response.data)
		} catch (error) {
			const errorMessage = `Echec de l'actualisation des données : ${error}`
			ErrorService.error({
				component: "AdminUpdates:fetchMajs",
				message: errorMessage,
				dispatch,
			})
		}
	}
	useEffect(() => {
		if (currentMaj) {
			methods.reset(currentMaj)
		}
	}, [currentMaj])

	const deleteLine = async (id) => {
		try {
			await Axios.delete(`/api/maj/${id}`)
			const res = await Axios.get("/api/maj")
			setDatas(res.data)
		} catch (error) {
			const errorMessage = `Echec de la tentative de suppréssion des données : ${error}`
			ErrorService.error({
				component: "AdminUpdates:deleteLine",
				message: errorMessage,
				dispatch,
			})
		}
	}

	const onClose = (maj = undefined) => {
		setIsOpen(!isOpen)
		setIsNew(!maj)
		setCurrentMaj(maj)
	}

	useEffect(() => {
		fetchMajs()
	}, [])

	const columns = useMemo(
		() => [
			{
				header: "Date",
				accessorKey: "datetime",
				cell: ({ row }) =>
					moment(row.original.datetime).format("DD/MM/YYYY HH:mm"),
				...width180,
			},
			{
				header: "Contenu de la note de mise-à-jour",
				accessorKey: "description",
				cell: ({ row }) => (
					<SDiv>{ReactHtmlParser(row.original.description)}</SDiv>
				),
			},
		],
		[],
	)

	return (
		<FormProvider {...methods}>
			<AdminTopBar title="Annonces de Mises-à-jour">
				{!adminRestrictions.isRestricted && (
					<Button color="primary" onClick={() => onClose()}>Publier une nouvelle note</Button>
				)}
			</AdminTopBar>
			<SuperReactTable
				data={datas}
				columns={columns}
				isEditable={!adminRestrictions.isRestricted}
				onEditClick={(original) => onClose(original)}
				isDeletable={!adminRestrictions.isRestricted}
				onDeleteClick={(original: any) => {
					deleteLine(original.id)
				}}
			/>
			<ModalUpdates
				isOpen={isOpen}
				currentMaj={currentMaj}
				isNew={isNew}
				onClose={onClose}
				fetchMajs={fetchMajs}
			/>
		</FormProvider>
	)
}

export default AdminUpdate
