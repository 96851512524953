import { yupResolver } from "@hookform/resolvers/yup"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck"
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import styled from "styled-components"
import { Flex } from "utils/components/style/flex"
import FAdminJsonSchemas from "utils/form/FAdminJsonSchemas"
import Yup from "utils/yup"
import WhitelistTable from "./WhitelistTable"

const SPlaylistAddCheckIcon = styled(PlaylistAddCheckIcon)`
    margin-right: 0.5rem;
`
const SFlex = styled(Flex)`
    margin-bottom: 0.75rem !important;
`

const WhitelistForm = ({
	currentSettings,
	setCurrentSettings,
	currentUser,
}) => {
	const [currentWhitelist, setCurrentWhitelist] = useState([])

	const whitelistPath = "accessRights.tools.JSONSCHEMAS.WHITELIST"
	const whitelistJson = _.get(currentSettings, whitelistPath, {})

	const schema = Yup.object({
		whitelisted_jsonschema_id: Yup.array(Yup.string()).required(),
	})

	useEffect(() => {
		if (currentWhitelist) {
			const defaultIds = currentWhitelist.map((entry) => entry.id)
			methods.reset({
				whitelisted_jsonschema_id: defaultIds,
			})
		}
	}, [currentWhitelist])

	useEffect(() => {
		const whitelistArray = []
		Object.entries(whitelistJson).forEach(([key, value]) => {
			whitelistArray.push({ id: key, values: value })
		})
		setCurrentWhitelist(whitelistArray)
	}, [whitelistJson])

	const methods = useForm({
		defaultValues: schema.getDefault(),
		resolver: yupResolver(schema),
	})

	const onSubmit = () => {
		const selectedIds = methods.getValues("whitelisted_jsonschema_id")
		const previousWhitelist = _.get(currentSettings, whitelistPath, undefined)

		if (previousWhitelist) {
			const newWhitelist = _.cloneDeep(previousWhitelist)
			const newSettings = _.cloneDeep(currentSettings)
			const concernedId =
				_.difference(selectedIds, Object.keys(previousWhitelist))[0] ??
				_.difference(Object.keys(previousWhitelist), selectedIds)[0]

			if (Object.keys(previousWhitelist).length < selectedIds.length) {
				// ? Adding
				newWhitelist[concernedId] = { read: true, write: true }
			} else {
				// ? Removing
				delete newWhitelist[concernedId]
			}

			_.set(newSettings, whitelistPath, newWhitelist)
			setCurrentSettings(newSettings)
		}
	}

	return (
		<Accordion>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<SPlaylistAddCheckIcon />
				<div>
					Liste blanche [ {`${currentWhitelist.length}`} formulaire(s) ]
				</div>
			</AccordionSummary>
			<AccordionDetails>
				<SFlex alignItemsStart>
					<FormProvider {...methods}>
						<FAdminJsonSchemas
							name="whitelisted_jsonschema_id"
							label="Formulaires en liste blanche"
							commune_id={currentUser?.commune_id}
							multiple
							onItemClickCallback={onSubmit}
						/>
					</FormProvider>
				</SFlex>
				<WhitelistTable
					currentWhitelist={currentWhitelist}
					currentSettings={currentSettings}
					setCurrentSettings={setCurrentSettings}
				/>
			</AccordionDetails>
		</Accordion>
	)
}

export default WhitelistForm
