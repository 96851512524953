import { yupResolver } from "@hookform/resolvers/yup"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove"
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import styled from "styled-components"
import { Flex } from "utils/components/style/flex"
import FAdminJsonSchemas from "utils/form/FAdminJsonSchemas"
import Yup from "utils/yup"
import BlacklistTable from "./BlacklistTable"

const SPlaylistRemoveIcon = styled(PlaylistRemoveIcon)`
    margin-right: 0.5rem;
`
const SFlex = styled(Flex)`
    margin-bottom: 0.75rem !important;
`

const BlacklistForm = ({
	currentSettings,
	setCurrentSettings,
	currentUser,
}) => {
	const [currentBlacklist, setCurrentBlacklist] = useState([])

	const blacklistPath = "accessRights.tools.JSONSCHEMAS.BLACKLIST"
	const schema = Yup.object({
		blacklisted_jsonschema_id: Yup.array(Yup.string()),
	})
	useEffect(() => {
		setCurrentBlacklist(_.get(currentSettings, blacklistPath, []))
	}, [currentSettings])

	const methods = useForm({
		defaultValues: schema.getDefault(),
		resolver: yupResolver(schema),
	})

	useEffect(() => {
		if (currentBlacklist) {
			methods.reset({
				blacklisted_jsonschema_id: currentBlacklist,
			})
		}
	}, [currentBlacklist])

	const onSubmit = () => {
		const selectedIds = methods.getValues("blacklisted_jsonschema_id")
		let newBlacklist = _.cloneDeep(currentBlacklist)
		const newSettings = _.cloneDeep(currentSettings)
		const concernedId =
			_.difference(selectedIds, currentBlacklist)[0] ??
			_.difference(currentBlacklist, selectedIds)[0]

		if (currentBlacklist.length < selectedIds.length) {
			// ? Adding
			newBlacklist.push(concernedId)
		} else {
			// ? Removing
			newBlacklist = newBlacklist.filter((id) => id !== concernedId)
		}

		_.set(newSettings, blacklistPath, newBlacklist)
		setCurrentSettings(newSettings)
	}

	return (
		<Accordion>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<SPlaylistRemoveIcon />
				<div>Liste noire [ {`${currentBlacklist.length}`} formulaire(s) ]</div>
			</AccordionSummary>
			<AccordionDetails>
				<SFlex alignItemsStart>
					<FormProvider {...methods}>
						<FAdminJsonSchemas
							name="blacklisted_jsonschema_id"
							label="Formulaires en liste noire"
							commune_id={currentUser?.commune_id}
							multiple
							onItemClickCallback={onSubmit}
						/>
					</FormProvider>
				</SFlex>
				<BlacklistTable currentBlacklist={currentBlacklist} />
			</AccordionDetails>
		</Accordion>
	)
}

export default BlacklistForm
