import { useSelector } from "react-redux"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"
import IMultiPolygon from "utils/types/IMultiPolygon"

const selectAdminMultiPolygons = createDeepEqualSelector(
	(state: any) => state.admin.multiPolygons,
	(multiPolygons: IMultiPolygon[]) => multiPolygons,
)

const useAdminMultiPolygons: () => IMultiPolygon[] = () => {
	return useSelector(selectAdminMultiPolygons)
}

export default useAdminMultiPolygons