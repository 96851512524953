import useAdminCommunes from "@/hooks/admin/useAdminCommunes"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import useMappings from "@/hooks/useMappings"
import React, { useMemo, useState } from "react"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import MappingFormModal from "./mapping/MappingFormModal"
import { useDispatch } from "react-redux"
import { deleteMapping } from "@/redux-toolkit/data/mappings/ressources"
import { PrimaryButton } from "utils/components/style/button"
import useAdminRestrictions from "@/hooks/useAdminRestrictions"
import Button from "@/styles/atoms/Button"
import AdminTopBar from "./AdminTopBar"

const AdminMapping = () => {
	const mappings = useMappings()
	const communes = useAdminCommunes()
	const jsonSchemas = useJsonSchemasWithStatics()
	const dispatch = useDispatch()
	const adminRestrictions = useAdminRestrictions()

	const [modal, setModal] = useState({
		open: false,
		id: undefined,
	})

	const columns = useMemo(
		() => [
			{
				accessorFn: (row) => communes[row.commune_id]?.name,
				header: "Communes",
			},
			{
				accessorFn: (row) => jsonSchemas[row.jsonschema_id]?.title,
				header: "Formulaire",
			},
		],
		[],
	)

	const data = useMemo(() =>
		adminRestrictions.isRestricted
			? Object.values(mappings).filter((mapping) => adminRestrictions.allowedCommunes.includes(mapping.commune_id))
			: Object.values(mappings)
		, [mappings])

	return (
		<>
			<AdminTopBar title="Mapping de données">
				<Button color="primary" onClick={() =>
					setModal({
						open: true,
						id: undefined,
					})
				}>
					Créer un mapping
				</Button>
			</AdminTopBar>
			<SuperReactTable
				data={data}
				columns={columns}
				onEditClick={(item) =>
					setModal({
						open: true,
						id: item.id,
					})
				}
				onDeleteClick={(item) => dispatch(deleteMapping(item.id))}
			/>
			<MappingFormModal
				open={modal.open}
				onClose={() => setModal({ ...modal, open: false })}
				id={modal.id}
			/>
		</>
	)
}

export default AdminMapping
