import EditeurTemplateFieldEdit from "@/pages/admin/formulaire_dynamique/EditeurTemplateFieldEdit"
import React, { useState } from "react"
import { useController, useFormContext } from "react-hook-form"
import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"

const PropertiesEdition = ({
	name,
	onRenameField = undefined,
	onDeleteField = undefined,
	children,
}) => {
	const { control } = useFormContext()

	const {
		field: { value: properties, onChange: onPropertiesChange },
	} = useController({
		name,
		control,
	})

	const [fieldEdit, setFieldEdit] = useState({
		target: undefined,
		open: false,
	})

	const onDragEnd = (result) => {
		if (!result.destination) return

		const reordered = [...properties]
		const [removed] = reordered.splice(result.source.index, 1)
		reordered.splice(result.destination.index, 0, removed)

		onPropertiesChange(reordered)
	}

	const onFieldChange = (field) => {
		const errors = []
		// check duplicate label or name
		const duplicatedLabel = properties.find((otherField) => {
			if (otherField.label === fieldEdit.target?.label) {
				return false
			}
			return otherField.label === field.label || otherField.name === field.label
		})
		if (duplicatedLabel)
			errors.push({
				field: "label",
				message: "Ce label est déja utilisé",
			})
		// check duplivate special field
		const uniqueTypes = [
			JsonSchemaPropertiesType.GEOLOC,
			JsonSchemaPropertiesType.STOCKPLACE,
		]
		if (uniqueTypes.includes(field.type)) {
			const duplicateType = properties.find((otherField) => {
				if (otherField.label === fieldEdit.target?.label) {
					return false
				}
				return otherField.type === field.type
			})
			if (duplicateType)
				errors.push({
					field: "type",
					message: "Il ne peut y avoir qu'un seul champ de ce type",
				})
		}

		// return errors
		if (errors.length) return errors

		if (fieldEdit.target) {
			// update existing field
			onPropertiesChange(
				properties.map((otherField) => {
					if (otherField.label === fieldEdit.target.label) {
						// detect renaming
						const oldLabel = otherField.label
						const newLabel = field.label
						if (oldLabel !== newLabel) {
							onRenameField?.({ oldLabel, newLabel })
						}
						// ---
						return field
					}
					return otherField
				}),
			)
		} else {
			// create new field
			onPropertiesChange([...properties, field])
		}
	}

	const deleteField = (label) => {
		onDeleteField?.(label)
		onPropertiesChange(properties.filter((el) => el.label !== label))
	}

	const editField = (label) => {
		setFieldEdit({ open: true, target: label })
	}

	return (
		<>
			{children({ onDragEnd, deleteField, editField, properties })}
			<EditeurTemplateFieldEdit
				isOpen={fieldEdit.open}
				toggleOpen={() => setFieldEdit({ ...fieldEdit, open: false })}
				field={fieldEdit.target}
				onChange={onFieldChange}
			/>
		</>
	)
}

export default PropertiesEdition
