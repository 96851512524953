import CheckIcon from "@mui/icons-material/Check"
import { Alert, List, ListItem } from "@mui/material"
import { setAdminRisks } from "@/redux-toolkit/commune/ressources"
import React, { useMemo } from "react"
import { useFormContext } from "react-hook-form"
import { connect } from "react-redux"
import styled from "styled-components"
import { PrimaryButton } from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import { RISQUES } from "utils/menuTab"
import RiskService from "utils/services/RiskService"
import ICommune from "utils/types/ICommune"

const SAlert = styled(Alert)`
    width: 100%;
`

const EditeurTemplateCheckRisks = ({
	communes,
	setAdminRisks,
	dependencies = ["commune", "main_category", "sub_category"],
	disabled = false
}: {
	communes: Array<ICommune>
	setAdminRisks: ({
		risks,
		commune_id,
	}: { risks: string[]; commune_id: string }) => void
	dependencies?: string[]
	disabled?: boolean
}) => {
	const methods = useFormContext()

	const [jsonSchemaCommunes, mainCat, subCat] = methods.watch(dependencies)

	const communeIds = useMemo(
		() => jsonSchemaCommunes && Object.keys(jsonSchemaCommunes),
		[jsonSchemaCommunes],
	)

	const riskWarning = useMemo(() => {
		if (mainCat !== RISQUES || !RiskService.getRisquesList().includes(subCat))
			return []

		return (communeIds as string[])?.filter(
			(id) => !communes[id].risks.includes(subCat),
		)
	}, [communeIds, mainCat, subCat])

	if (!riskWarning?.length) return null

	return (
		<SAlert severity="warning">
			<div>Les communes suivantes n&apos;ont pas ce risque configuré :</div>
			<List>
				{riskWarning?.map((id) => (
					<ListItem key={id}>
						<Flex gap="10px">
							{communes[id].name}
							{communes[id].risks.includes(subCat) ? (
								<PrimaryButton size="small" endIcon={<CheckIcon />} disabled>
									Risque ajouté
								</PrimaryButton>
							) : (
								<PrimaryButton
									size="small"
									onClick={() => {
										setAdminRisks({
											risks: [subCat, ...communes[id].risks],
											commune_id: id,
										})
									}}
									disabled={disabled}
								>
									Ajouter le risque {subCat}
								</PrimaryButton>
							)}
						</Flex>
					</ListItem>
				))}
			</List>
		</SAlert>
	)
}
const mapStateToProps = (state) => ({
	communes: state.admin?.users?.communes,
})
const mapDispatchToProps = (dispatch) => ({
	setAdminRisks: ({ risks, commune_id }) =>
		dispatch(setAdminRisks({ risks, commune_id })),
})
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(EditeurTemplateCheckRisks)
