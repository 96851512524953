import { Alert, TextField } from "@mui/material"
import Papa from "papaparse"
import React, { useState } from "react"
import FileDropzone from "@/pages/telechargement/Upload/component/FileDropzone"
import { Flex } from "@/utils/components/style/flex"

const CsvLoader = ({ setCsvData, setCurrentStep, currentStep }) => {
	const [emptyError, setEmptyError] = useState(false)
	const [parsingError, setParsingError] = useState(false)

	const commonDelimiters = [",", ";", "\t", "|"]

	const processImport = (file) => {
		setParsingError(false) // Reset parsing error state

		const tryParseWithDelimiter = (file, delimiterIndex = 0) => {
			if (delimiterIndex >= commonDelimiters.length) {
				setParsingError(true)
				alert(
					"Unable to correctly parse the file with common delimiters. Please check the file format.",
				)
				return
			}

			const delimiter = commonDelimiters[delimiterIndex]
			Papa.parse(file, {
				encoding: 'ISO-8859-1',
				header: true,
				delimiter: delimiter,
				transform: (value) => value.replace(/^="((.|\n)*)"$/m, "$1"),
				complete: async (results) => {
					if (
						results.data.length > 0 &&
						Object.keys(results.data[0]).length > 1
					) {
						// Assuming correctly parsed if more than one header or field is found
						const erroredRows = results.errors.map((err) => err.row)
						const validData = results.data.filter(
							(val, index) => !erroredRows.includes(index),
						)

						if (!validData.length) {
							setEmptyError(true)
						} else {
							setEmptyError(false)
							const emptyRowsFiltered = validData.filter(
								(row) => !Object.values(row).every((val) => !val),
							)
							setCsvData(emptyRowsFiltered)
							setCurrentStep(currentStep + 1)
						}
					} else {
						// Try next delimiter if parsing not correct
						tryParseWithDelimiter(file, delimiterIndex + 1)
					}
				},
			})
		}

		tryParseWithDelimiter(file)
	}

	return (
		<Flex directionColumn alignItemsStretch gap zeroMinHeight>
			{emptyError && (
				<Alert severity="error">Le fichier ne contient aucune donnée.</Alert>
			)}
			{parsingError && (
				<Alert severity="error">
					Erreur de formatage du fichier. Vérifiez le délimiteur et le format du
					fichier.
				</Alert>
			)}
			<FileDropzone allowedExtension={[".csv"]} onFileUpload={processImport} />
		</Flex>
	)
}

export default CsvLoader
