import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { IconButton, Radio } from "@mui/material"
import React from "react"
import { Flex } from "utils/components/style/flex"

const PreviewController = ({
	example,
	rowNumber,
	setRowNumber,
	mode,
	setMode,
}) => {
	return (
		<Flex justifyCenter gap={5}>
			<IconButton
				size="small"
				disabled={rowNumber <= 0}
				onClick={() => setRowNumber(rowNumber - 1)}
			>
				<NavigateBeforeIcon />
			</IconButton>
			<div>
				Prévisualisation{" "}
				{example ? `(${rowNumber + 1}/${example.length})` : `indisponible`}
			</div>
			<IconButton
				size="small"
				disabled={rowNumber >= (example?.length ?? 0) - 1}
				onClick={() => setRowNumber(rowNumber + 1)}
			>
				<NavigateNextIcon />
			</IconButton>
			<Flex>
				{["form", "normal", "raw"].map((item) => (
					<Radio
						key={item}
						checked={mode === item}
						onChange={() => setMode(item)}
					/>
				))}
			</Flex>
		</Flex>
	)
}

export default PreviewController
