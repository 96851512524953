import { Checkbox, IconButton } from "@mui/material"
import { ColumnDef } from "@tanstack/react-table"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import React, { useMemo } from "react"
import { UseFormReturn } from "react-hook-form"
import { width50 } from "@/utils/components/tables/widthProps"
import getImage from "@/utils/getImage"
import ICommune from "@/utils/types/ICommune"
import _ from "lodash"
import CustomTooltip from "@/utils/components/style/CustomTooltip"
import DeleteIcon from "@mui/icons-material/Delete"

export const useInternalColumns = ({
	commune,
	methods,
}: {
	commune: ICommune
	methods: UseFormReturn<any, any>
}) => {
	const jsonSchemaWithStatics = useJsonSchemasWithStatics()
	const publicJsonSchemaIds = methods.watch("publicJsonSchemaIds")
	const columns = useMemo(
		() =>
			[
				{
					header: "Icone",
					accessorKey: "id_imgId",
					...width50,
					cell: (props) => {
						return (
							<img
								src={getImage(jsonSchemaWithStatics[props.row.original]?.imgId)}
								width="40px"
								height="40px"
							/>
						)
					},
				},
				{
					header: "Nom",
					accessorKey: "id_title",
					cell: (props) => {
						return jsonSchemaWithStatics[props.row.original]?.title
					},
				},
				{
					header: "Inscription publique",
					accessorKey: "isPublicRegistration",
					cell: (props) => {
						//* check if form have at least one public field to be public !
						const havePublicField = !!jsonSchemaWithStatics[props.row.original]?.template?.properties?.filter((field) =>
							field.isPublic
						)?.length
						//* disabled if form have field who're required but not public !
						const isDisabled = !!jsonSchemaWithStatics[props.row.original]?.template?.properties?.filter((field) =>
							field.isRequired && !field.isPublic
						)?.length
						const Morph = isDisabled || !havePublicField ? CustomTooltip : React.Fragment
						const tooltipTitle = !havePublicField
							? "Le formulaire doit comporter au moins un champ publique !"
							: isDisabled
								? "Le formulaire comporte des champs requis qui ne sont pas publique !"
								: null

						return (
							<Morph title={tooltipTitle}>
								<div>
									<Checkbox
										checked={
											publicJsonSchemaIds[props.row.original]?.allowPublicRegistration
										}
										onChange={(e) => {
											const currentPublicJsonSchemaIds =
												_.cloneDeep(publicJsonSchemaIds)
											if (
												publicJsonSchemaIds[props.row.original]?.allowPublicRegistration
											) {
												currentPublicJsonSchemaIds[
													props.row.original
												].allowPublicRegistration = false
												methods.setValue(
													"publicJsonSchemaIds",
													currentPublicJsonSchemaIds,
												)
											} else {
												currentPublicJsonSchemaIds[
													props.row.original
												].allowPublicRegistration = true
												methods.setValue(
													"publicJsonSchemaIds",
													currentPublicJsonSchemaIds,
												)
											}
										}}
										disabled={isDisabled || !havePublicField}
									/>
								</div>
							</Morph>
						)
					},
				},
				{
					header: "Cartographie publique",
					accessorKey: "isPublicMapView",
					cell: (props) => {
						return (
							<CustomTooltip title={"Indisponible pour le moment..."}>
								<div>
									<Checkbox
										checked={
											publicJsonSchemaIds[props.row.original]?.allowPublicMapView
										}
										onChange={(e) => {
											const currentPublicJsonSchemaIds =
												_.cloneDeep(publicJsonSchemaIds)
											if (
												publicJsonSchemaIds[props.row.original]?.allowPublicMapView
											) {
												currentPublicJsonSchemaIds[
													props.row.original
												].allowPublicMapView = false
												methods.setValue(
													"publicJsonSchemaIds",
													currentPublicJsonSchemaIds,
												)
											} else {
												currentPublicJsonSchemaIds[
													props.row.original
												].allowPublicMapView = true
												methods.setValue(
													"publicJsonSchemaIds",
													currentPublicJsonSchemaIds,
												)
											}
										}}
										disabled // forced for now until picrim map will be realesed
									/>
								</div>
							</CustomTooltip>
						)
					},
				},
				{
					header: "",
					accessorKey: "deleteFn",
					enableSorting: false,
					...width50,
					cell: (props) => {
						if (jsonSchemaWithStatics[props.row.original]) return
						return (
							<CustomTooltip title="Retirer le formulaire fantôme">
								<IconButton
									onClick={() => {
										const res = _.omit(publicJsonSchemaIds, props.row.original)
										methods.setValue('publicJsonSchemaIds', res)
										methods.setValue('tempPublicIds', Object.keys(res))
									}}
								>
									<DeleteIcon />
								</IconButton>
							</CustomTooltip>
						)
					},
				},
			] as ColumnDef<any>[],
		[commune, methods, publicJsonSchemaIds, jsonSchemaWithStatics],
	)

	return columns
}
