import Axios from "axios"
import React, { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useEffectOnce } from "react-use"
import styled from "styled-components"
import { PrimaryButton } from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import FText from "@/utils/form/FText"
import FUploadAdmin2 from "@/utils/form/upload/FUploadAdmin2"
import _ from "lodash"
import FSelect from "../../utils/form/FSelect"
import AdminTopBar from "./AdminTopBar"

const Container = styled.div`
    padding: 0 8rem;
`

const AdminAddImage = () => {
	const methods = useForm()
	const [imageMarkers, setImageMarkers] = useState([])

	useEffectOnce(() => {
		const fetch = async () => {
			const response = await Axios.get("/api/image_marker")
			setImageMarkers(response.data)
		}
		fetch()
	})

	const onSubmit = async (data) => {
		const formData = new FormData()

		formData.append("file", data.image)
		formData.append("title", data.name)
		formData.append("category", data.category)

		const res = await Axios.post("/api/image_marker", formData, {
			headers: {
				"content-type": "multipart/form-data",
			},
		})

		return res.data
	}
	const groupedImgIds = _.groupBy(imageMarkers, "category")

	return (
		<>
			<AdminTopBar title="Importer un nouvel icône" />
			<FormProvider {...methods}>
				<Container>
					<Flex gap="1rem" directionColumn>
						<FUploadAdmin2 name="image" label="Image" />
						<FText name="name" label="Nom de l'image" />
						<FSelect
							name="category"
							label="Catégorie de l'image"
							options={Object.keys(groupedImgIds)}
						/>
						<PrimaryButton onClick={methods.handleSubmit(onSubmit)}>
							Importer
						</PrimaryButton>
					</Flex>
				</Container>
			</FormProvider>
		</>
	)
}

export default AdminAddImage
