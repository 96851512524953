import AddCircleOutline from "@mui/icons-material/AddCircleOutline"
import DeleteIcon from "@mui/icons-material/Delete"
import { Card, Divider, IconButton, TextField } from "@mui/material"
import React from "react"
import { DragDropContext } from "react-beautiful-dnd"
import { useController, useFormContext } from "react-hook-form"
import styled from "styled-components"
import { NeutralOutlinedButton } from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import { H4 } from "utils/components/style/header"
import SuperDraggable from "utils/dnd/SuperDraggable"
import SuperDragHandle from "utils/dnd/SuperDragHandle"
import SuperDroppable from "utils/dnd/SuperDroppable"
import { FormDependencies } from "utils/form/FormDependencies"
import FTextArea from "utils/form/FTextArea"
import FDraggableList from "./FDraggableList"

const SCard = styled(Card)`
    padding: 1rem;
`

const SDiv = styled.div`
    padding: 1rem;
`

const SDivider = styled(Divider)`
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
`

const DraggableComponent = styled.div`
    padding: 0rem;
`

const SectionsCard = () => {
	const { control } = useFormContext()
	const {
		field: { value: sections, onChange: onSectionsChange },
	} = useController({ name: "sections", control })

	const onClickAddSection = (isPoints) =>
		onSectionsChange([
			...sections,
			{
				title: "",
				isPoints,
				content: [""],
			},
		])

	const onDragEnd = (result) => {
		const reordered = [...sections]
		const [removed] = reordered.splice(result.source.index, 1)
		reordered.splice(result.destination.index, 0, removed)

		onSectionsChange(reordered)
	}

	const onTitleChange = (event, index) => {
		sections[index].title = event.target.value
		onSectionsChange(sections)
	}

	const onDeleteClick = (toDeleteIndex) => {
		onSectionsChange(
			sections.filter((section, index) => index !== toDeleteIndex),
		)
	}

	return (
		<>
			<SCard>
				<Flex gap={"0.5rem"}>
					<H4>Sections</H4>
					<NeutralOutlinedButton
						onClick={() => onClickAddSection(true)}
						endIcon={<AddCircleOutline />}
					>
						Liste
					</NeutralOutlinedButton>
					<NeutralOutlinedButton
						onClick={() => onClickAddSection(false)}
						endIcon={<AddCircleOutline />}
					>
						Texte
					</NeutralOutlinedButton>
				</Flex>
				<DragDropContext onDragEnd={onDragEnd}>
					<SuperDroppable>
						{sections.map((section, index) => (
							<SuperDraggable
								id={`${index}`}
								index={index}
								component={DraggableComponent}
								key={index}
								fullHandle={false}
							>
								<SDivider variant="middle" />
								<Flex gap={"0.5rem"}>
									<SuperDragHandle />
									<Divider orientation="vertical" variant="middle" flexItem />
									<Flex directionColumn alignItemsStretch flexGrow={3}>
										<TextField
											size="small"
											value={sections[index].title}
											onChange={(event) => onTitleChange(event, index)}
											fullWidth
											label="Titre"
										/>
										<SDiv>
											<FormDependencies targets={["sections"]}>
												{({ sections }) =>
													sections[index].isPoints ? (
														<FDraggableList
															name={`sections[${index}].content`}
														/>
													) : (
														<FTextArea
															name={`sections[${index}].content[0]`}
															label={"Contenu"}
														/>
													)
												}
											</FormDependencies>
										</SDiv>
									</Flex>
									<Divider orientation="vertical" variant="middle" flexItem />
									<IconButton onClick={() => onDeleteClick(index)}>
										<DeleteIcon />
									</IconButton>
								</Flex>
							</SuperDraggable>
						))}
					</SuperDroppable>
				</DragDropContext>
			</SCard>
		</>
	)
}

export default SectionsCard
