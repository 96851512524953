import React, { useEffect, useState } from "react"
import { useBoolean } from "react-use"
import { OrangeButton } from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import { H4 } from "@/utils/components/style/header"
import ErrorsSolver from "../formulaire_dynamique/MyForms/components/components/ErrorsSolver"
import MappingRunner from "../formulaire_dynamique/MyForms/components/components/MappingRunner"
import CommuneAndJsonSchemaSelector from "./components/CommuneAndJsonSchemaSelector"
import CsvLoader from "./components/CsvLoader"
import DownloadIcon from "@mui/icons-material/Download"
import DataDisplayAndSubmit from "@/pages/admin/adminimport/components/DataDisplayAndSubmit"
import Papa from "papaparse"
import MappingAndJsonSchemaCreator from "./components/MappingAndJsonSchemaCreator"
import MappingCreator from "./components/MappingCreator"
import useAdminCommunes from "@/hooks/admin/useAdminCommunes"
import { useDispatch, useSelector } from "react-redux"
import Axios from "axios"
import { addPoints } from "@/redux-toolkit/data/points/reducer"
import { setUserSettings } from "@/redux-toolkit/userSettings/reducer"
import AutoErrorSolver from "../formulaire_dynamique/MyForms/components/components/AutoErrorSolver"
import AdminTopBar from "../AdminTopBar"
import Button from "@/styles/atoms/Button"

const AdminImport = () => {
	const [communeSelectedId, setCommuneSelectedId] = useState(undefined)
	const [jsonSchemaSelected, setJsonSchemaSelected] = useState(undefined)
	const [mappingSchema, setMappingSchema] = useState(undefined)
	const [csvData, setCsvData] = useState(undefined)
	const [currentStep, setCurrentStep] = useState(0)
	const [newJsonSchema, toggleNewJsonSchema] = useBoolean(false)
	const [mappingResults, setMappingResults] = useState(undefined)
	const [ignorredRowsIndex, setIgnorredRowsIndex] = useState([])
	const userSettings = useSelector((state: any) => state.userSettings)
	const communes = useAdminCommunes()
	const dispatch = useDispatch()

	useEffect(() => {
		const fetch = async () => {
			const communePoints = await Axios.get(
				"/api/admin_points/" + communeSelectedId,
			)
			dispatch(addPoints(communePoints.data.points))
			dispatch(
				setUserSettings({ ...userSettings, commune_id: communeSelectedId }),
			)
		}
		if (communeSelectedId) fetch()
	}, [communeSelectedId])

	const downloadCsv = () => {
		const ignoredData = csvData.filter((row, index) =>
			ignorredRowsIndex.includes(index),
		)
		const csv = Papa.unparse(ignoredData)
		const blob = new Blob([csv], { type: "text/csv;charset=utf-8" })
		const url = URL.createObjectURL(blob)
		const a = document.createElement("a")
		a.href = url
		a.download = "data.csv"
		a.click()
	}

	return (
		<>
			<Flex directionColumn alignItemsStretch gap>
				<AdminTopBar title="Import de données">
					{ignorredRowsIndex.length > 0 && (
						<Button $backgroundColor="var(--orange400)" $textColor="white" onClick={downloadCsv} startContent={<DownloadIcon />}>
							Télécharger les données ignorées ({ignorredRowsIndex.length})
						</Button>
					)}
				</AdminTopBar>
				{
					{
						0: (
							<CommuneAndJsonSchemaSelector
								setCommuneSelectedId={setCommuneSelectedId}
								setJsonSchemaSelected={setJsonSchemaSelected}
								currentStep={currentStep}
								setCurrentStep={setCurrentStep}
							/>
						),
						1: (
							<CsvLoader
								setCsvData={setCsvData}
								currentStep={currentStep}
								setCurrentStep={setCurrentStep}
							/>
						),
						2: jsonSchemaSelected ? (
							<MappingCreator
								setCurrentStep={setCurrentStep}
								currentStep={currentStep}
								csvData={csvData}
								jsonSchemaSelected={jsonSchemaSelected}
								setMappingSchema={setMappingSchema}
							/>
						) : (
							<MappingAndJsonSchemaCreator
								communeSelectedId={communeSelectedId}
								csvData={csvData}
								setJsonSchemaSelected={setJsonSchemaSelected}
								setCurrentStep={setCurrentStep}
								currentStep={currentStep}
								setMappingSchema={setMappingSchema}
								toggleNewJsonSchema={toggleNewJsonSchema}
							/>
						),
						3: (
							<MappingRunner
								mappingSchema={mappingSchema}
								csvData={csvData}
								setMappingResults={setMappingResults}
								setCurrentStep={setCurrentStep}
								currentStep={currentStep}
								jsonSchema={jsonSchemaSelected}
							/>
						),
						4: (
							<AutoErrorSolver
								mapping={{ schema: mappingSchema }}
								mappingResults={mappingResults}
								jsonSchema={jsonSchemaSelected}
								setMappingResults={setMappingResults}
								setCurrentStep={setCurrentStep}
								currentStep={currentStep}
							/>
						),
						5: (
							<ErrorsSolver
								ignorable
								setIgnorredRowsIndex={setIgnorredRowsIndex}
								ignorredRowsIndex={ignorredRowsIndex}
								mappingResults={mappingResults}
								setMappingResults={setMappingResults}
								setCurrentStep={setCurrentStep}
								currentStep={currentStep}
								jsonSchema={jsonSchemaSelected}
							/>
						),
						6: (
							<DataDisplayAndSubmit
								mappingResults={mappingResults}
								jsonSchema={jsonSchemaSelected}
								newJsonSchema={newJsonSchema}
								setCurrentStep={setCurrentStep}
								currentStep={currentStep}
								communeSelectedId={communeSelectedId}
							/>
						),
						7: <>Import terminé !</>,
					}[currentStep]
				}
			</Flex>
		</>
	)
}

export default AdminImport
