import { useSelector } from "react-redux"
import createDeepEqualSelector from "@/utils/createDeepEqualSelector"

interface ICdc {
    geojson: any,
    name: string,
    deleted: boolean,
    twitter_tags: string[],
    id: string,
    shared_between_communes: string[],
    share_orga: boolean
}

const selectAdminCdcs = createDeepEqualSelector(
	(state: any) => state.admin.users.cdcs,
	(cdcs: {[key: string]: ICdc}) => cdcs,
)

const useAdminCdcs = () : { [key: string]: ICdc } => {
	return useSelector(selectAdminCdcs)
}

export default useAdminCdcs