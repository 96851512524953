import { yupResolver } from "@hookform/resolvers/yup"
import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { Flex } from "utils/components/style/flex"
import FBoolean from "utils/form/FBoolean"
import FFreeTags from "utils/form/FFreeTags/FFreeTags"
import FInlineSwitch from "@/utils/form/FInlineSwitch"
import FSelect from "utils/form/FSelect"
import FText from "utils/form/FText"
import SuperModal from "utils/modals/SuperModal"
import Yup from "utils/yup"
import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"
import { FormDependencies } from "utils/form/FormDependencies"
import FOptionsWithImage from "utils/form/FSelectWithImage/admin/FOptionsWithImage"
import FSwitch from "utils/form/FSwitch"
export const availableTypes = {
	text: "Texte simple",
	textarea: "Zone de texte",
	"creatable-select": "Selecteur de valeurs",
	geoloc: "Géolocalisation",
	stockplace: "Lieu de stockage",
	responsables: "Responsables",
	//tags: 'Tags', => only in static forms
	date: "Date",
	time: "Heure",
	checkbox: "Cases à cocher",
	radio: "Bouton radio",
	select: "Sélection",
	phone: "Téléphone",
	selectionWithImage: "Sélection avec image",
}

const typesWithItems = [
	JsonSchemaPropertiesType.CHECKBOX,
	JsonSchemaPropertiesType.RADIO,
	JsonSchemaPropertiesType.SELECT,
]

const schema = Yup.object().shape({
	name: Yup.string().default(""),
	type: Yup.string().default("text").required(),
	items: Yup.array(Yup.string())
		.default([])
		.when("type", (type, sch) =>
			typesWithItems.includes(type) ? sch.required().min(1) : sch,
		),
	itemsImage: Yup.array(
		Yup.object({ label: Yup.string(), imgId: Yup.string() }),
	),
	label: Yup.string()
		.default("")
		.test(
			"no-points",
			"Le label ne peut pas contenir de points",
			(value) => !value.includes("."),
		)
		.required(),
	isColumn: Yup.boolean().default(false).required(),
	isColumnSectorOnly: Yup.boolean().default(false).required(),
	isRequired: Yup.boolean().default(false).required(),
	isPcs: Yup.boolean().default(false).required(),
	isIdentifiant: Yup.boolean().default(false).required(),
	isPublic: Yup.boolean().default(false).required(),
	defaultValue: Yup.string().default(""),
})

function EditeurTemplateFieldEdit({ isOpen, toggleOpen, field, onChange }) {
	const methods = useForm({
		defaultValues: schema.getDefault(),
		resolver: yupResolver(schema),
	})
	const onSumbit = (values) => {
		if (!typesWithItems.includes(values.type)) {
			delete values.items
		}
		const errors = onChange(values)
		if (!errors) return toggleOpen()

		errors.forEach((error) =>
			// @ts-ignore
			methods.setError(error.field, {
				type: "manual",
				message: error.message,
			}),
		)
	}

	useEffect(() => {
		if (isOpen) {
			if (field) methods.reset({ ...schema.getDefault(), ...field })
			else methods.reset(schema.getDefault())
		}
	}, [isOpen])
	methods.watch()
	// @ts-ignore
	const type = methods.watch("type") as JsonSchemaPropertiesType
	// @ts-ignore
	let DefaultValueComponent = null
	switch (type) {
		case JsonSchemaPropertiesType.TEXT:
		case JsonSchemaPropertiesType.TEXTAREA:
		case JsonSchemaPropertiesType.RADIO:
		case JsonSchemaPropertiesType.SELECT:
		case JsonSchemaPropertiesType.SELECTION_WITH_IMAGE:
			DefaultValueComponent = (
				<FText name="defaultValue" label="Valeur par défaut" />
			)
			break
		case JsonSchemaPropertiesType.DATE:
			DefaultValueComponent = (
				<FBoolean name="defaultValue" label="Date du jour par défaut" />
			)
			break
		case JsonSchemaPropertiesType.TIME:
			DefaultValueComponent = (
				<FBoolean name="defaultValue" label="Heure actuelle par défaut" />
			)
			break
	}

	const isDisabledPublic = ["responsables", "stockplace"].includes(methods.getValues("type"))

	useEffect(() => {
		if (!isDisabledPublic) return
		methods.setValue("isPublic", false)
	}, [isDisabledPublic])

	return (
		<FormProvider {...methods}>
			<SuperModal
				onClose={toggleOpen}
				isOpen={isOpen}
				title={field === undefined ? "Ajouter un champ" : "Édition du champ"}
				isNew={field === undefined}
				onClick={methods.handleSubmit(onSumbit)}
			>
				<Flex directionColumn gap="1rem">
					<FSelect
						options={Object.keys(availableTypes)}
						getOptionLabel={(option) => availableTypes[option]}
						name="type"
						label="Type du champ"
					/>

					{typesWithItems.includes(type) && (
						<FFreeTags name="items" label="Items" />
					)}
					{type === JsonSchemaPropertiesType.SELECTION_WITH_IMAGE && (
						<>
							<FOptionsWithImage name="itemsImage" label="Items" />
							<FSwitch name="options.isMulti" label="Sélection multiple" />
						</>
					)}
					<FText name="label" label="Label du champ" />

					<FInlineSwitch name="isColumn" label="Champ colonne" />
					<FormDependencies targets={["type"]}>
						{(type: any) => {
							return (
								type.type === JsonSchemaPropertiesType.GEOLOC && (
									<>
										<FInlineSwitch
											name="isColumnSectorOnly"
											label="Remplacer la colonne adresse avec secteur par secteur seulement"
										/>
									</>
								)
							)
						}}
					</FormDependencies>
					<FInlineSwitch name="isPcs" label="Champ pcs" />
					<FInlineSwitch name="isIdentifiant" label="Champ identifiant" />
					<FInlineSwitch name="isRequired" label="Champ obligatoire" />
					<FInlineSwitch name="isPublic" label="Champ public" disabled={isDisabledPublic} />
					{DefaultValueComponent}
				</Flex>
			</SuperModal>
		</FormProvider>
	)
}

export default EditeurTemplateFieldEdit
