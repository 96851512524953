import { yupResolver } from "@hookform/resolvers/yup"
import {
	createDicrim,
	updateDicrim,
} from "@/redux-toolkit/data/dicrim/resources"
import { convertToRaw, Editor, EditorState } from "draft-js"
import { draftToMarkdown } from "markdown-draft-js"
import React, { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { ReactMarkdown } from "react-markdown/lib/react-markdown"
import { useDispatch, useSelector } from "react-redux"
import remarkBreaks from "remark-breaks"
import styled from "styled-components"
import { PrimaryButton } from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import FText from "utils/form/FText"
import FTextArea from "utils/form/FTextArea"
import { toggleInlineStyle } from "utils/form/specific/fiche/draftExtend/inline"
import ModalBodyForTable from "utils/modals/ModalBodyForTable"
import Yup from "utils/yup"
import ColourPreview from "./ColourPreview"
import DraggableListWithSubsections from "./DraggableListWithSubsections"
import FDicrimColorPicker from "./FDicrimColorPicker"
import FInfoLinks from "./FInfoLinks"
import SectionsCard from "./SectionsCard"
import VigilanceCard from "./VigilanceCard"
import Modal from "@/styles/organisms/Modal"
import { ModalContent } from "@nextui-org/react"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"

const primaryColorPresets = [
	"#339ABE",
	"#DB2E05",
	"#ED9600",
	"#720051",
	"#175828",
	"#256193",
	"#61BCBE",
]

const secondaryColorPresets = [
	"#E8F5F8",
	"#FAEAE8",
	"#FCF4E7",
	"#F0E6ED",
	"#E7EEEA",
	"#E8EFF4",
	"#EEF8F8",
]

const schema = Yup.object().shape({
	id: Yup.string().uuid().default(undefined),
	title: Yup.string().required().default(""),
	counsels: Yup.object({
		customTitle: Yup.string().default(""),
		before: Yup.array().default([]),
		during: Yup.array().default([]),
		after: Yup.array().default([]),
	}),
	infoLinks: Yup.array().of(Yup.string().url()).default([]),
	colors: Yup.object({
		primary: Yup.string().required().default("#339ABE"),
		secondary: Yup.string().required().default("#E8F5F8"),
	}),
	sections: Yup.array()
		.of(
			Yup.object({
				title: Yup.string().required(),
				isPoints: Yup.boolean().required().default(true),
				content: Yup.array().of(Yup.string().required()),
			}),
		)
		.default([]),
	infoLinksMessage: Yup.string().default(""),
	types: Yup.array().default([]),
	effects: Yup.array().default([]),
	definition: Yup.string().required().default(""),
	vigilances: Yup.object({
		levels: Yup.array().default([]),
		links: Yup.array().of(Yup.string().url()),
	}).default({
		levels: [],
		links: [],
	}),
	classification: Yup.string().default(""),
})

const CreateDicrimModal = ({ isOpen, onClose, selectedDicrimId }) => {
	const dicrims = useSelector((state: any) => state.data.dicrims)
	const dispatch = useDispatch()
	const methods = useForm({
		defaultValues: schema.getDefault(),
		resolver: yupResolver(schema),
	})

	const [editorState, setEditorState] = useState(() =>
		EditorState.createEmpty(),
	)

	const currentStyle = editorState.getCurrentInlineStyle()

	useEffect(() => {
		if (isOpen) {
			if (selectedDicrimId) {
				methods.reset(dicrims[selectedDicrimId])
			} else {
				methods.reset(schema.getDefault())
			}
		}
	}, [isOpen])

	const onSubmit = async (values) => {
		console.log("values", values)

		selectedDicrimId
			? dispatch(updateDicrim(values))
			: dispatch(createDicrim(values))

		onClose()
	}

	return (
		<>
			<FormProvider {...methods}>
				<Modal isOpen={isOpen} onClose={onClose}>
					<ModalContent>
						<ModalHeader>Create DICRIM</ModalHeader>
						<ModalBodyForTable>
							<Flex directionColumn gap={"1rem"} alignItemsStretch>
								<Flex alignItemsStart fullWidth gap={"1rem"}>
									<Flex directionColumn gap={"1rem"} fullWidth>
										<FText name="title" label="Nom de crise" />
										<FTextArea
											name="definition"
											label="Définition"
											minRows={9}
										/>
									</Flex>
									<ColourPreview />
									<FDicrimColorPicker
										name="colors.primary"
										presetColours={primaryColorPresets}
									/>
									<FDicrimColorPicker
										name="colors.secondary"
										presetColours={secondaryColorPresets}
									/>
								</Flex>
								<FText
									name="counsels.customTitle"
									label='Titre personnalisé pour les conseils (titre par défaut : "En cas de [nom de crise], que dois-je faire ?")'
								/>
								<Flex alignItemsStart gap={"1rem"}>
									<DraggableListWithSubsections
										name={"counsels.before"}
										header={"Avant"}
									/>
									<DraggableListWithSubsections
										name={"counsels.during"}
										header={"Pendant"}
									/>
									<DraggableListWithSubsections
										name={"counsels.after"}
										header={"Après"}
									/>
								</Flex>
								<Flex alignItemsStart gap={"1rem"}>
									<DraggableListWithSubsections
										name={"types"}
										header={"Types"}
									/>
									<DraggableListWithSubsections
										name={"effects"}
										header={"Effets"}
									/>
									<FInfoLinks
										linksName={"infoLinks"}
										linksMessageName={"infoLinksMessage"}
									/>
								</Flex>
								<VigilanceCard />
								<SectionsCard />
								<PrimaryButton
									onClick={() => {
										toggleInlineStyle("BOLD")({
											editorState,
											setEditorState,
										})
									}}
								>
									Bold
								</PrimaryButton>
								<Editor editorState={editorState} onChange={setEditorState} />
								<ReactMarkdown remarkPlugins={[remarkBreaks]}>
									{draftToMarkdown(
										convertToRaw(editorState.getCurrentContent()),
										{ preserveNewlines: true },
									)}
								</ReactMarkdown>
								{console.log(
									draftToMarkdown(
										convertToRaw(editorState.getCurrentContent()),
										{
											preserveNewlines: true,
										},
									),
								)}
							</Flex>
						</ModalBodyForTable>
						<ModalFooter>
							<PrimaryButton onClick={methods.handleSubmit(onSubmit)}>
								Submit
							</PrimaryButton>
						</ModalFooter>
					</ModalContent>
				</Modal>
			</FormProvider>
		</>
	)
}

export default CreateDicrimModal
