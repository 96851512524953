import Publish from "@mui/icons-material/Publish"
import { LinearProgress } from "@mui/material"
import { usePropertiesColumns } from "@/hooks/useJsonSchemaColumns"
import { createJsonSchema } from "@/redux-toolkit/data/jsonSchema/resources"
import PointsApi from "@/redux-toolkit/data/points/PointsApi"
import Moment from "moment"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { wait } from "utils/wait"
import { PrimaryLoadingButton } from "utils/components/style/button"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import { RUBY_DATE_FORMAT } from "@/utils/dateFormat"
import IPoint from "utils/types/IPoint"
import { v4 as uuidv4 } from "uuid"

const TIME_BTW_REQ = 50

const DataDisplayAndSubmit = ({
	mappingResults,
	jsonSchema,
	communeSelectedId,
	newJsonSchema,
	setCurrentStep,
	currentStep,
}) => {
	const dataPoints = mappingResults.map(
		(item): IPoint => ({
			jsonschema_id: jsonSchema.id,
			commune_id: communeSelectedId,
			id: uuidv4(),
			geojson: { properties: item },
			deleted: false,
			created_at: Moment().format(RUBY_DATE_FORMAT),
			updated_at: Moment().format(RUBY_DATE_FORMAT),
		}),
	)
	const dispatch = useDispatch()
	const columns = usePropertiesColumns(jsonSchema.template.properties)
	const [loading, setLoading] = useState<number>()

	const processImport = async () => {
		setLoading(0)
		if (newJsonSchema) {
			await dispatch(createJsonSchema(jsonSchema))
		}

		await Promise.all(
			dataPoints.map((point, index) =>
				wait(index * TIME_BTW_REQ)
					.then(() => PointsApi.createPoint(point))
					.then(() => setLoading((i) => i + 1)),
			),
		)
		setCurrentStep(currentStep + 1)
	}

	return (
		<>
			<LinearProgress
				variant="determinate"
				value={loading ?? 0}
			></LinearProgress>
			<SuperReactTable
				actionsButtons={
					<PrimaryLoadingButton
						variant="contained"
						onClick={processImport}
						endIcon={<Publish />}
						loading={loading !== undefined}
						loadingPosition="end"
					>
						Procéder à l&apos;import
					</PrimaryLoadingButton>
				}
				data={dataPoints}
				columns={columns}
				isDeletable={false}
				isEditable={false}
				selectable={false}
			/>
		</>
	)
}

export default DataDisplayAndSubmit
