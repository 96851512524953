import React from "react"
import { SketchPicker } from "react-color"
import { useController, useFormContext } from "react-hook-form"

const FDicrimColorPicker = ({ name, presetColours }) => {
	const { control } = useFormContext()
	const {
		field: { value, onChange },
	} = useController({
		name,
		control,
	})

	return (
		<>
			<SketchPicker
				color={value}
				onChange={(color) => onChange(color["hex"])}
				presetColors={presetColours}
			/>
		</>
	)
}

export default FDicrimColorPicker
