import { ColumnDef } from "@tanstack/react-table"
import React, { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import SuperReactTable from "utils/components/tables/SuperReactTable"
import PicrimJsonSchemasModal from "./PicrimJsonSchemasModal"

const AdminPicrimCarto = () => {
	const [currentCommune, setCurrentCommune] = useState(undefined)
	const [isJsonSchemasModalOpen, setIsJsonSchemasModalOpen] = useState(false)

	const communes = useSelector((state: any) => state.admin?.users?.communes)

	const communesList = useMemo(
		() => Object.keys(communes).map((id) => communes[id]),
		[communes],
	)

	const toggleJsonSchemasModal = () =>
		setIsJsonSchemasModalOpen(!isJsonSchemasModalOpen)

	const columns = useMemo(
		() =>
			[
				{
					header: "Nom de commune",
					accessorKey: "name",
				},
				{
					header: "ID",
					accessorKey: "id",
				},
			] as ColumnDef<any>[],
		[],
	)

	return (
		<>
			<h1>Affichage éléments cartographie Picrim</h1>
			<SuperReactTable
				data={communesList}
				columns={columns}
				isDeletable={false}
				selectable={false}
				onEditClick={(commune) => {
					setCurrentCommune(commune)
					toggleJsonSchemasModal()
				}}
			/>
			<PicrimJsonSchemasModal
				commune={currentCommune}
				isOpen={isJsonSchemasModalOpen}
				onClose={toggleJsonSchemasModal}
				setCurrentCommune={setCurrentCommune}
			/>
		</>
	)
}

export default AdminPicrimCarto
