import Axios from "axios"
import React from "react"
import SuperModal from "utils/modals/SuperModal"
import ErrorService from "@/services/ErrorService"
import { useDispatch } from "react-redux"
import { Flex } from "utils/components/style/flex"
import { useFormContext } from "react-hook-form"
import FDateTime from "utils/form/FDateTime"
import FDraft from "utils/form/specific/fiche/FDraft"

const ModalUpdates = ({ isOpen, currentMaj, isNew, onClose, fetchMajs }) => {
	const methods = useFormContext()
	const dispatch = useDispatch()

	const onSubmit = async (values) => {
		try {
			if (isNew) {
				await Axios.post("/api/maj", {
					data: {
						datetime: values.datetime,
						description: values.description,
					},
				})
			} else {
				await Axios.put(`/api/maj/${currentMaj.id}`, {
					data: {
						datetime: values.datetime,
						description: values.description,
					},
				})
			}
			fetchMajs()
			onClose()
		} catch (error) {
			const errorMessage = `Echec de la tentative de modification des données : ${error}`
			ErrorService.error({
				component: "ModalUpdates:onSubmit",
				message: errorMessage,
				dispatch,
			})
		}
	}

	return (
		<SuperModal
			isOpen={isOpen}
			onClose={onClose}
			onClick={methods.handleSubmit(onSubmit)}
			isNew={isNew}
			title={
				isNew
					? "Publier une note de mise-à-jour"
					: "Modifier une note de mise-à-jour"
			}
		>
			<Flex directionColumn gap="1rem">
				<FDateTime name="datetime" label="Date de la mise-à-jour" />
				<FDraft
					name="description"
					label={`Note de mise-à-jour`}
					isImportable={false}
				/>
			</Flex>
		</SuperModal>
	)
}

export default ModalUpdates
