import { yupResolver } from "@hookform/resolvers/yup"
import NavigateNext from "@mui/icons-material/NavigateNext"
import FMapping from "@/pages/admin/mapping/FMapping"
import { mappingToolInstanceValidator } from "@/pages/admin/mapping/mappingTools/mappingTools"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import React, { useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { PrimaryButton } from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import FakeForm from "utils/form/FakeForm"
import FFreeTags from "utils/form/FFreeTags/FFreeTags"
import { FormDependencies } from "utils/form/FormDependencies"
import IMappingSchema from "utils/types/IMapping/IMappingSchema"
import Yup from "utils/yup"
import _ from "lodash"
const MappingCreator = ({
	csvData,
	jsonSchemaSelected,
	setCurrentStep,
	currentStep,
	setMappingSchema,
}) => {
	const jsonSchemas = useJsonSchemasWithStatics()

	const schema = useMemo(
		() =>
			Yup.object().shape({
				schema: Yup.object(
					Object.fromEntries(
						jsonSchemaSelected.template.properties.map((prop) => [
							prop.name,
							prop.isRequired
								? mappingToolInstanceValidator.required()
								: mappingToolInstanceValidator,
						]),
					),
				)
					.noUnknown()
					.required(),
			}),
		[],
	)

	const methods = useForm({
		defaultValues: schema.getDefault(),
		resolver: yupResolver(schema),
	})

	const fields = useMemo(() => {
		if (_.isEmpty(csvData)) return []
		return Object.keys(csvData[0])
	}, [csvData])

	return (
		<FormProvider {...methods}>
			<Flex directionColumn alignItemsStretch gap>
				<FormDependencies targets={["schema"]} memo={[fields]}>
					{({ schema }) => {
						const highlight = Object.values(
							(schema as IMappingSchema) ?? {},
						).reduce(
							(acc, val) => [
								...acc,
								...Object.values(val?.singleInputs ?? {}),
								...Object.values(val?.arrayInputs ?? {}).reduce(
									(a, v) => [...a, ...v],
									[],
								),
							],
							[],
						)

						return (
							<FakeForm values={{ fields }}>
								<FFreeTags
									name="fields"
									label="Champs"
									highlight={highlight}
									readOnly
								/>
							</FakeForm>
						)
					}}
				</FormDependencies>
				<FMapping
					example={csvData}
					name="schema"
					label="Schema"
					properties={jsonSchemaSelected.template.properties}
					fields={fields}
					withKey={false}
				/>
				<Flex flexEnd>
					<PrimaryButton
						onClick={methods.handleSubmit((values) => {
							setMappingSchema(values.schema)
							setCurrentStep(currentStep + 1)
						})}
						endIcon={<NavigateNext />}
					>
						Suivant
					</PrimaryButton>
				</Flex>
			</Flex>
		</FormProvider>
	)
}
export default MappingCreator
