import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import { Chip, IconButton } from "@mui/material"
import React from "react"
import { DragDropContext } from "react-beautiful-dnd"
import { FormProvider, useForm } from "react-hook-form"
import FPointProperty from "utils/components/jsonSchema/form/FPointProperty"
import { PrimaryButton } from "utils/components/style/button"
import {
	AmberChip,
	BlueChip,
	GreenChip,
	RedChip,
	YellowChip,
} from "utils/components/style/chip"
import { Flex } from "utils/components/style/flex"
import SuperDraggable from "utils/dnd/SuperDraggable"
import SuperDragHandle from "utils/dnd/SuperDragHandle"
import SuperDroppable from "utils/dnd/SuperDroppable"
import { JsonSchemaPropertiesType } from "@/utils/types/IJsonSchema"

const defaultValues = {
	[JsonSchemaPropertiesType.TEXT]: "Un texte",
	[JsonSchemaPropertiesType.TEXTAREA]:
		"Une zone de texte\nsur plusieurs lignes",
	[JsonSchemaPropertiesType.PHONE]: [
		{ Type: "Perso", Numéro: "01 23 45 67 89" },
	],
	[JsonSchemaPropertiesType.GEOLOC]: {
		Adresse: "1 Rue Alexander Fleming, 17000 La Rochelle, France",
		coo: { lat: 46.1486892, lng: -1.1555022 },
	},
}

const EditeurTemplateFields = ({
	onDragEnd,
	deleteField,
	editField,
	properties,
	disabled = false
}) => {
	const methods = useForm({
		defaultValues,
	})

	return (
		<>
			<Flex flexEnd fullWidth>
				<PrimaryButton
					startIcon={<AddIcon />}
					onClick={() => editField(undefined)}
					disabled={disabled}
				>
					Ajouter un champ
				</PrimaryButton>
			</Flex>
			<DragDropContext onDragEnd={onDragEnd}>
				<FormProvider {...methods}>
					<SuperDroppable component={Flex} directionColumn fullWidth>
						{properties.map((field, index) => {
							const {
								type,
								label,
								isColumn,
								isPcs,
								isIdentifiant,
								isRequired,
								isPublic,
								defaultValue,
							} = field

							return (
								<SuperDraggable
									component={Flex}
									key={label}
									fullWidth
									gap="5px"
									id={label}
									index={index}
									fullHandle={false}
									style={{
										backgroundColor: "white",
										marginBottom: "1rem",
									}}
								>
									{!disabled && <SuperDragHandle />}
									<FPointProperty property={field} name={type} disabled />
									{isColumn && <BlueChip label="Colonne" />}
									{isPcs && <AmberChip label="PCS" />}
									{isIdentifiant && <GreenChip label="ID" />}
									{isRequired && <RedChip label="Requis" />}
									{defaultValue && <Chip label="Valeur par défaut" />}
									{isPublic && <YellowChip label="Public" />}

									<IconButton tabIndex={-1} onClick={() => editField(field)} disabled={disabled}>
										<EditIcon
											style={{
												color: disabled ? "var(--grey100)" : "var(--orange500)",
											}}
										/>
									</IconButton>
									<IconButton tabIndex={-1} onClick={() => deleteField(label)} disabled={disabled}>
										<DeleteIcon style={{ color: disabled ? "var(--grey100)" : "var(--red500)" }} />
									</IconButton>
								</SuperDraggable>
							)
						})}
					</SuperDroppable>
				</FormProvider>
			</DragDropContext>
		</>
	)
}

export default EditeurTemplateFields
