import React from "react"
import AddCircleOutline from "@mui/icons-material/AddCircleOutline"
import ClearIcon from "@mui/icons-material/Clear"
import { IconButton, TextField } from "@mui/material"
import { DragDropContext } from "react-beautiful-dnd"
import { useController, useFormContext, useWatch } from "react-hook-form"
import styled from "styled-components"
import { Flex, FlexHeader } from "utils/components/style/flex"
import { H4 } from "utils/components/style/header"
import SuperDraggable from "utils/dnd/SuperDraggable"
import SuperDragHandle from "utils/dnd/SuperDragHandle"
import SuperDroppable from "utils/dnd/SuperDroppable"
import FDraggableList from "./FDraggableList"

const SFlex = styled(Flex)`
    width: 100%;
    margin-bottom: 0.5rem;
`

const SFDraggableList = styled(FDraggableList)`
    margin-left: 2rem !important;
`

const SDiv = styled.div`
    margin-left: 0.66rem;
    padding-top: 1rem;
    padding-left: 0.5rem;
    border-left: solid 3px black;
`

const SubsectionDraggable = ({ index, counselsPeriod, onClickDelete }) => {
	const { control } = useFormContext()

	const {
		field: { value: subSection, onChange: onSubSectionChange },
	} = useController({
		name: `${counselsPeriod}[${index}]`, //name of array in conseils (eg. Avant:[])
		control,
	})

	const watchedSubsection = useWatch({
		control,
		name: `${counselsPeriod}[${index}]`,
	})

	const onHeaderChange = (event) => {
		subSection.header = event.target.value
		onSubSectionChange(subSection)
	}

	return (
		<SuperDraggable
			component={SFlex}
			key={index}
			index={index}
			id={`${index}`}
			directionColumn
			alignItemsStretch
			fullHandle={false}
		>
			<Flex>
				<SuperDragHandle />
				<TextField
					value={watchedSubsection.header}
					onChange={(event) => onHeaderChange(event)}
					fullWidth
				/>
				<IconButton
					onClick={() => {
						onClickDelete(index)
					}}
				>
					<ClearIcon />
				</IconButton>
			</Flex>

			<SDiv>
				<FDraggableList name={`${counselsPeriod}[${index}].content`} />
			</SDiv>
		</SuperDraggable>
	)
}

export default SubsectionDraggable
