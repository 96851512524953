import { Alert } from "@mui/material"
import React from "react"
import Dropzone from "react-dropzone"
import { useController, useFormContext } from "react-hook-form"
import styled from "styled-components"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import ErrorService from "@/services/ErrorService"
import { Flex } from "@/utils/components/style/flex"
import { PrimaryButton } from "@/utils/components/style/button"
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile"
import EditIcon from "@mui/icons-material/Edit"
import { useDispatch } from "react-redux"

const B = styled.b`
    color: var(--primary500);
`
const SCloudUploadIcon = styled(CloudUploadIcon)`
    color: var(--neutral400);
`
const F12 = styled.div`
    font-size: var(--font-size-small);
    color: var(--primary800);
`
const SAlert = styled(Alert)`
	width: 100%;
`
const SFlex = styled(Flex)`
    border-style: dashed;
    border-color: var(--primary800);
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
`

const FUploadAdmin = ({ name, label }) => {
	const {
		control,
		formState: { errors },
	} = useFormContext()

	const dispatch = useDispatch()

	const {
		field: { ref, value = [], onChange, ...inputProps },
	} = useController({
		name,
		control,
		defaultValue: "",
	})
	return (
		<>
			<Dropzone
				onDrop={async (files: any) => {
					if (files.length > 1) {
						ErrorService.error({
							component: "FormikUpload:onDrop",
							message: "un seul fichier autorisé",
							dispatch,
						})
						return
					}
					onChange(files[0])
				}}
			>
				{({ getRootProps, getInputProps }) => (
					<SFlex {...getRootProps()}>
						<SCloudUploadIcon />
						<input {...getInputProps()} />
						<div>
							<F12>
								Faire glisser les fichiers à téléverser ou{" "}
								<B>Parcourir vos fichiers</B>(png, pdf, jpeg ...)
							</F12>
						</div>
					</SFlex>
				)}
			</Dropzone>
			{value && (
				<Flex fullWidth gap="3rem">
					<SAlert severity="success">
						<InsertDriveFileIcon /> Fichier uploadé
					</SAlert>
					<PrimaryButton
						startIcon={<EditIcon />}
						onClick={() => onChange([])}
					>
						Modifier
					</PrimaryButton>
				</Flex>
			)}
		</>
	)
}

export default FUploadAdmin
