import { Checkbox } from "@mui/material"
import { ColumnDef } from "@tanstack/react-table"
import useJsonSchemas from "@/hooks/useJsonSchemas"
import {
	getAdminJsonSchemas,
	updateJsonSchema,
} from "@/redux-toolkit/data/jsonSchema/resources"
import _ from "lodash"
import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { PrimaryButton } from "utils/components/style/button"
import { StyledModalHeader } from "utils/components/style/header"
import { SModalFooter, StyledModal } from "utils/components/style/modal"
import SuperReactTable from "utils/components/tables/SuperReactTable"
import { width50 } from "utils/components/tables/widthProps"
import getImage from "@/utils/getImage"
import ModalBodyForTable from "utils/modals/ModalBodyForTable"
import Modal from "@/styles/organisms/Modal"
import { ModalContent } from "@nextui-org/react"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"

const PicrimJsonSchemasModal = ({
	commune,
	isOpen,
	onClose,
	setCurrentCommune,
}) => {
	const dispatch = useDispatch()
	const jsonSchemas = useJsonSchemas()
	const communes = useSelector((state: any) => state?.admin?.users?.communes)

	useEffect(() => {
		dispatch(getAdminJsonSchemas())
	}, [commune])

	const jsonSchemasArray = useMemo(() => {
		if (!commune) return []
		return commune.jsonSchemaIds.map((id) => jsonSchemas[id])
	}, [jsonSchemas, commune])

	const CheckboxCell = (props) => {
		const jsonSchemaId = props?.row?.original?.id

		return (
			<Checkbox
				checked={props?.value}
				onClick={() => handleCheckboxClick(jsonSchemaId)}
			/>
		)
	}

	const handleCheckboxClick = (jsonSchemaId) => {
		const clonedJsonSchema = _.cloneDeep(jsonSchemas[jsonSchemaId])

		_.set(
			clonedJsonSchema,
			`communes.${commune.id}.allowPublicMapView`,
			!_.get(clonedJsonSchema, `communes.${commune.id}.allowPublicMapView`),
		)

		dispatch(updateJsonSchema(clonedJsonSchema))
	}

	const columns = useMemo(
		() =>
			[
				{
					header: "Image",
					...width50,
					accessorKey: "image",
					cell: (props) => (
						<>
							<img width={50} src={getImage(props.row.original.imgId)} />
						</>
					),
				},
				{
					header: "Titre",
					accessorKey: "title",
				},
				{
					header: "Catégorie",
					accessorKey: "main_category",
				},
				{
					header: "Sous-catégorie",
					accessorKey: "sub_category",
				},
				{
					header: "Public",
					accessorKey: `communes[${commune?.id}].allowPublicMapView`,
					enableSorting: false,
					isAction: true,
					meta: {
						isAction: true,
					},
					cell: CheckboxCell,
				},
			] as ColumnDef<any>[],
		[commune, communes, jsonSchemas],
	)

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			backdrop="static"
			keyboard={false}
			onClosed={() => {
				setCurrentCommune(undefined)
			}}
		>
			<ModalContent>
				<ModalHeader>JsonSchemas Picrim - {commune?.name}</ModalHeader>
				<ModalBodyForTable>
					<SuperReactTable
						data={jsonSchemasArray}
						columns={columns}
						isEditable={false}
						isDeletable={false}
						selectable={false}
					/>
				</ModalBodyForTable>
				<ModalFooter>
					<PrimaryButton
						onClick={() => {
							setCurrentCommune(undefined)
							onClose()
						}}
					>
						Sauvegarder
					</PrimaryButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default PicrimJsonSchemasModal
