import { Alert, CircularProgress, TableCell } from "@mui/material"
import usePoints from "@/hooks/usePoints"
import { useAllTags } from "@/hooks/useTags"
import React, { useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import styled from "styled-components"
import FPointProperty from "utils/components/jsonSchema/form/FPointProperty"
import PointPropertyValue from "utils/components/jsonSchema/properties/PointPropertyValue"
import { Flex } from "utils/components/style/flex"
import FakeForm from "utils/form/FakeForm"
import IMappingSchema from "utils/types/IMapping/IMappingSchema"
import MappingSchemaRunner from "../core/MappingSchemaRunner"
import { mappingToolInstanceValidator } from "../mappingTools/mappingTools"
import { useDispatch } from "react-redux"
const SPre = styled.pre`
    margin: 0 !important;
    white-space: pre-wrap;
`

const PreviewCell = ({ exampleRow, property, name, mode }) => {
	const { watch } = useFormContext<IMappingSchema>()
	const dispatch = useDispatch()
	const mappingSchemaItem = watch(name)

	const points = usePoints()
	const tags = useAllTags()

	const [mappingRunner, setMappingRunner] = useState(undefined)

	const [value, setValue] = useState<{
		status: "accepted" | "rejected" | "loading" | "empty"
		value: unknown
		error?: string
	}>({ status: "empty", value: undefined })

	useEffect(() => {
		if (!mappingSchemaItem?.converter) return setMappingRunner(undefined)

		mappingToolInstanceValidator
			.validate(mappingSchemaItem)
			.then(() =>
				setMappingRunner(
					new MappingSchemaRunner(
						{ [property.label]: mappingSchemaItem },
						[property],
						points,
						tags,
						dispatch,
					),
				),
			)
			.catch(() => setMappingRunner(undefined))
	}, [mappingSchemaItem])

	useEffect(() => {
		if (!mappingRunner || !exampleRow)
			return setValue({
				value: undefined,
				status: "empty",
			})

		setValue({
			status: "loading",
			value: undefined,
		})

		mappingRunner.convertRow(exampleRow, 0).then((result) => {
			if (result.errors[property.label]) {
				setValue({
					status: "rejected",
					error: result.errors[property.label].message,
					value: result.errors[property.label].value,
				})
			} else {
				setValue({
					status: "accepted",
					value: result.data[property.label],
				})
			}
		})
	}, [mappingRunner, exampleRow])

	return (
		<TableCell>
			<Flex gap directionColumn alignItemsStretch>
				{value.status === "loading" && <CircularProgress size={20} />}
				{value.status === "rejected" && (
					<Alert severity="warning">{value.error}</Alert>
				)}

				{value.value && (
					<Flex flex={1}>
						{mode === "raw" && (
							<SPre>{JSON.stringify(value.value, undefined, 4)}</SPre>
						)}
						{mode === "form" && (
							<FakeForm values={{ value: value.value }}>
								<FPointProperty property={property} name={"value"} disabled />
							</FakeForm>
						)}
						{mode === "normal" && (
							<PointPropertyValue value={value.value} property={property} />
						)}
					</Flex>
				)}
			</Flex>
		</TableCell>
	)
}

export default PreviewCell
