import { Autocomplete, TextField } from "@mui/material"
import { ColumnDef } from "@tanstack/react-table"
import useAdminCommunes from "@/hooks/admin/useAdminCommunes"
import useJsonSchemas from "@/hooks/useJsonSchemas"
import {
	getAdminJsonSchemas,
	getJsonSchemas,
} from "@/redux-toolkit/data/jsonSchema/resources"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { RedButton } from "utils/components/style/button"
import { Flex, FlexHeader } from "utils/components/style/flex"
import { H2 } from "utils/components/style/header"
import SuperReactTable from "utils/components/tables/SuperReactTable"
import { width80 } from "utils/components/tables/widthProps"
import getImage from "@/utils/getImage"
import IJsonSchema from "@/utils/types/IJsonSchema"
import PicrimPublicInfosModal from "./PicrimPublicInfosModal"

const AdminPicrimPublicInformation = () => {
	const history = useHistory()
	const jsonSchemas = useJsonSchemas()
	const dispatch = useDispatch()
	const communes = useAdminCommunes()
	const [isPublicInfosModalOpen, setIsPublicInfosModalOpen] =
		useState(undefined)
	const [jsonSchemaToEdit, setJsonSchemaToEdit] =
		useState<IJsonSchema>(undefined)

	const [selectedCommuneId, setSelectedCommuneId] = useState<string>(undefined)

	useEffect(() => {
		dispatch(getAdminJsonSchemas())
	})

	const jsonSchemasArray = useMemo(
		() =>
			selectedCommuneId
				? communes[selectedCommuneId].jsonSchemaIds.map((id) => jsonSchemas[id])
				: Object.values(jsonSchemas),
		[jsonSchemas, selectedCommuneId],
	)

	const communeOptions = useMemo(() => {
		return Object.keys(communes)
	}, [communes])

	const columns = useMemo(
		() =>
			[
				{
					header: "Image",
					...width80,
					accessorKey: "image",
					cell: ({ row }) => (
						<>
							<img width={50} src={getImage(row.original.imgId)} />
						</>
					),
				},
				{
					header: "Id",
					accessorKey: "id",
				},

				{
					header: "Titre",
					accessorKey: "title",
				},
				{
					header: "Catégorie",
					accessorKey: "main_category",
				},
				{
					header: "Sous-catégorie",
					accessorKey: "sub_category",
				},
				{
					header: "Communes",
					accessorKey: "communes",
					cell: ({ row }) => {
						const schemaId = row.original.id
						const numberOfCommunes = Object.values(communes).filter(
							(commune) => commune.jsonSchemaIds.includes(schemaId),
						).length
						return <>{numberOfCommunes}</>
					},
				},
			] as ColumnDef<any>[],
		[],
	)

	return (
		<>
			<FlexHeader>
				<H2>Cartographie Picrim - Informations Publiques</H2>
				<Flex gap="1rem">
					<RedButton onClick={history.goBack}>Fermer</RedButton>
				</Flex>
			</FlexHeader>
			<Autocomplete
				fullWidth
				options={communeOptions}
				renderOption={(props, option) => {
					const commune = communes[option]
					return (
						<li {...props} key={commune.id}>
							{commune.name}
						</li>
					)
				}}
				getOptionLabel={(option) => communes[option].name}
				renderInput={(params) => (
					<TextField {...params} label="Trier par commune" />
				)}
				onChange={(event, value) => {
					setSelectedCommuneId(value)
				}}
			/>
			<SuperReactTable
				data={jsonSchemasArray}
				columns={columns}
				isDeletable={false}
				onEditClick={(jsonSchema) => {
					console.log("jsonSchema to edit", jsonSchema)
					setJsonSchemaToEdit(jsonSchema)
					setIsPublicInfosModalOpen(true)
				}}
				selectable={false}
			/>
			<PicrimPublicInfosModal
				isOpen={isPublicInfosModalOpen}
				setIsOpen={setIsPublicInfosModalOpen}
				jsonSchema={jsonSchemaToEdit}
			/>
		</>
	)
}

export default AdminPicrimPublicInformation
