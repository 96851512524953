import React from "react"
import { useController, useFormContext } from "react-hook-form"
import { DragDropContext } from "react-beautiful-dnd"
import SuperDroppable from "utils/dnd/SuperDroppable"
import SuperDraggable from "utils/dnd/SuperDraggable"
import SuperDragHandle from "utils/dnd/SuperDragHandle"
import {
	Card,
	CardContent,
	IconButton,
	CardHeader,
	Container,
} from "@mui/material"
import FTextArea from "utils/form/FTextArea"
import { TextField } from "@mui/material"
import { Flex } from "utils/components/style/flex"
import AddCircleOutline from "@mui/icons-material/AddCircleOutline"
import ClearIcon from "@mui/icons-material/Clear"
import styled from "styled-components"
import { H4 } from "utils/components/style/header"
import { FlexHeader } from "utils/components/style/flex"
import FDraggableList from "./FDraggableList"

const SCard = styled(Card)`
    width: 33%;
    padding: 1rem;
`

const FInfoLinks = ({ linksName, linksMessageName }) => {
	const { control } = useFormContext()

	const {
		field: { value: linksMessage, onChange: onLinksMessageChange },
		fieldState: { error: linksMessageError },
	} = useController({
		name: linksName, //name of array in conseils (eg. Avant:[])
		control,
	})

	// onLinksChange(value) -> set value of (name) to value

	return (
		<>
			<>
				<SCard>
					<Flex directionColumn gap={"1rem"} alignItemsStart>
						<FDraggableList name={linksName} header={"Lien d'informations"} />
						<FTextArea
							name={linksMessageName}
							label={"Message à afficher (facultatif)"}
							placeholder={"Message à afficher"}
						/>
					</Flex>
				</SCard>
			</>
		</>
	)
}

export default FInfoLinks
