import React, { useMemo, useState } from "react"
import SuperReactTable from "@/utils/components/tables/SuperReactTable/SuperReactTable"
import AdminModalEditingCommunes from "./AdminModalEditingCommunes/AdminModalEditingCommunes"
import { ColumnDef } from "@tanstack/react-table"

const TableCommunes = ({ communes }) => {
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [editingCommune, setEditingCommune] = useState(null)

	if (!communes) {
		return null
	}

	const columns = useMemo(
		() =>
			[
				{
					header: "Nom",
					accessorKey: "name",
				},

				{
					header: "Communauté de commune",
					accessorKey: "cdc",
				},
				{
					header: "Risques",
					accessorKey: "risks",
					cell: ({ getValue }) => <>{(getValue() ?? []).join(" / ")}</>,
				},
				{
					header: "Référent PCS",
					accessorKey: "referentNumerisk",
				},
				{
					header: "Date de fin de contrat",
					accessorKey: "dateContrat",
				},
			] as ColumnDef<any, any>[],
		[],
	)

	return (
		<div>
			<SuperReactTable
				data={Object.values(communes)}
				columns={columns}
				selectable={false}
				isDeletable={false}
				onEditClick={(row) => {
					setIsOpenModal(true)
					setEditingCommune(row)
				}}
				disabledSearchProperties={[
					"geojson",
					"cdc_geojson",
					"jsonSchemaIds",
					"risks",
				]}
			/>
			<AdminModalEditingCommunes
				isOpen={isOpenModal}
				commune={editingCommune}
				onClose={() => setIsOpenModal(false)}
			/>
		</div>
	)
}

export default TableCommunes
