import { Autocomplete, createFilterOptions, TextField } from "@mui/material"
import React from "react"
import { useController, useFormContext } from "react-hook-form"
import styled from "styled-components"
import { menuTreeItems } from "utils/menuTab"

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    gap: 10px;
    width: 100%;
    & > * {
        flex: 1;
    }
`

const filter = createFilterOptions()

const FMenuTreeLocation = ({ mainName, subName, disabled = false }) => {
	const { control } = useFormContext()

	const {
		field: { value: mainValue, onChange: onMainChange },
		fieldState: { error: mainError },
	} = useController({
		name: mainName,
		control,
		rules: { required: true },
		defaultValue: "",
	})

	const {
		field: { value: subValue, onChange: onSubChange },
		fieldState: { error: subError },
	} = useController({
		name: subName,
		control,
		rules: { required: true },
		defaultValue: "",
	})

	return (
		<Wrapper>
			<Autocomplete
				fullWidth
				renderInput={(params) => (
					<TextField
						{...params}
						label={"Catégorie principale"}
						error={Boolean(mainError)}
						helperText={mainError?.message}
					/>
				)}
				options={Object.keys(menuTreeItems)}
				value={mainValue}
				onChange={(event, newMain) => onMainChange(newMain)}
				disabled={disabled}
			/>
			<Autocomplete
				fullWidth
				renderInput={(params) => (
					<TextField
						{...params}
						label={"Sous-catégorie"}
						error={Boolean(subError)}
						helperText={subError?.message}
					/>
				)}
				options={[...(menuTreeItems[mainValue] ?? [])]}
				value={subValue}
				filterOptions={(options, params) => {
					const filtered = filter(options, params)

					if (
						params.inputValue !== "" &&
						!options.includes(params.inputValue)
					) {
						filtered.push({ add: params.inputValue })
					}

					return filtered
				}}
				getOptionLabel={(option) => {
					if (typeof option === "string") {
						return option
					}
					return `Ajouter "${option.add}"`
				}}
				onChange={(event, newSub) => {
					if (typeof newSub === "string") {
						return onSubChange(newSub)
					}
					onSubChange(newSub.add)
				}}
				selectOnFocus
				clearOnBlur
				handleHomeEndKeys
				freeSolo
				disabled={disabled}
			/>
		</Wrapper>
	)
}

export default FMenuTreeLocation
