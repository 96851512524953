import { setCurrentPoint } from "@/redux-toolkit/edit/reducer"
import Moment from "moment"
import React, { useMemo, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { AmberChip, GreenChip, LightRedChip, RedChip } from "@/utils/components/style/chip"
import SuperReactTable from "@/utils/components/tables/SuperReactTable/SuperReactTable"
import IPoint from "@/utils/types/IPoint"
import { ColumnDef } from "@tanstack/react-table"
import ModalDetailsUsers from "@/pages/admin/ModalDetailsUsers"
import _ from "lodash"

const TableUsers = ({ users }) => {
	const [currentUser, setCurrentUser] = useState(undefined)
	const [isOpenModalUser, setIsOpenModalUser] = useState(false)

	if (!users) {
		return null
	}

	const usersList = useMemo(
		() => Object.keys(users).map((id) => users[id]),
		[users],
	)

	const columns = useMemo(
		() =>
			[
				{
					header: "Role",
					accessorKey: "role",
					size: 90,
					cell: ({ getValue }) => {
						const crownSVG = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12px" fill="#eba100"><title>crown-circle</title><path d="M12 2C6.47 2 2 6.5 2 12C2 17.5 6.5 22 12 22S22 17.5 22 12 17.5 2 12 2M16 15.44C16 15.78 15.78 16 15.44 16H8.56C8.22 16 8 15.78 8 15.44V15H16V15.44M16 14H8L7 8L10 10L12 7L14 10L17 8L16 14Z" /></svg>
						return _.isEqual(getValue(), "super-admin")
							? <RedChip avatar={crownSVG} label="Super Admin" />
							: _.isEqual(getValue(), "admin")
								? <LightRedChip label="Admin" />
								: null
					}
				},

				{
					header: "Nom de la commune",
					accessorKey: "commune_name", // String-based value accessors!
				},
				{
					header: "Email",
					accessorKey: "email", // String-based value accessors!
				},
				{
					header: "current_sign_in_at",
					accessorKey: "current_sign_in_at",
					cell: ({ getValue }) => {
						const value = getValue()
						if (!value) {
							return null
						}
						const date = Moment(value)
						let tag = <GreenChip label={date.fromNow()} />
						if (date.fromNow().includes("mois")) {
							tag = <AmberChip label={date.fromNow()} />
						}
						if (date.fromNow().includes("ans")) {
							tag = <RedChip label={date.fromNow()} />
						}
						return (
							<>
								{tag} {value && Moment(value).format("DD/MM/YYYY")}
							</>
						)
					},
				},
			] as ColumnDef<IPoint, any>[],
		[],
	)

	return (
		<div>
			<SuperReactTable
				data={usersList}
				columns={columns}
				initialSortBy={[{ id: "role", desc: true }]}
				onEditClick={(original) => {
					setCurrentUser(original)
					setIsOpenModalUser(true)
				}}
				isDeletable={false}
				selectable={false}
			/>
			<ModalDetailsUsers
				user={currentUser}
				isOpen={isOpenModalUser}
				onClose={() => setIsOpenModalUser(!isOpenModalUser)}
			/>
		</div>
	)
}
const mapStateToProps = (state) => {
	return {
		users: state.admin?.users?.users,
	}
}
const mapDispatchToProps = (dispatch) => ({
	setCurrentPoint: (human: IPoint) => dispatch(setCurrentPoint(human)),
})
/**
 * Hello
 */
const modifiedTableUser = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(TableUsers),
)
/**
 * Hello 2
 */
export default modifiedTableUser
