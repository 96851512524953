import { yupResolver } from "@hookform/resolvers/yup"
import useJsonSchemas from "@/hooks/useJsonSchemas"
import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { Flex } from "@/utils/components/style/flex"
import FSelectCommune from "@/utils/form/FSelectCommune"
import FSwitch from "@/utils/form/FSwitch"
import FText from "@/utils/form/FText"
import FImageMarkerPicker from "@/utils/form/admin/FImageMarkerPicker"
import FMenuTreeLocation from "@/utils/form/admin/FMenuTreeLocation"
import SuperModal from "@/utils/modals/SuperModal"
import Yup from "@/utils/yup"
import EditeurTemplateCheckRisks from "./EditeurTemplateCheckRisks"
import EditeurTemplateFields from "./EditeurTemplateFields"
import EditeurTemplateSortBy from "./EditeurTemplateSortBy"
import PropertiesEdition from "./PropertiesEdition"
import ICommune from "@/utils/types/ICommune"
import {
	createJsonSchema,
	updateJsonSchema,
} from "@/redux-toolkit/data/jsonSchema/resources"
import { updateCommune } from "@/redux-toolkit/commune/ressources"
import Axios from "axios"
import { v4 as uuidv4 } from "uuid"
import { getCommunes } from "@/redux-toolkit/admin/accounts/resources"
import useAdminCommunes from "@/hooks/admin/useAdminCommunes"
import _ from "lodash"
import useAdminRestrictions from "@/hooks/useAdminRestrictions"
import { Alert } from "@mui/material"

const schema = Yup.object().shape({
	id: Yup.string().uuid().default(undefined),
	title: Yup.string()
		.required()
		.default("")
		.test(
			"no-slash",
			"Le titre ne peut pas contenir de /",
			(value) => !value.includes("/"),
		),
	is_standard: Yup.boolean().required().default(false),
	is_telealert: Yup.boolean().required().default(false),
	main_category: Yup.string().nullable().required().default(null),
	sub_category: Yup.string().nullable().required().default(null),
	communes: Yup.object().default({}),
	template: Yup.object({ properties: Yup.array().default([]) }),
	sort_by: Yup.array(Yup.mixed()).ensure().default([]),
	imgId: Yup.string().uuid().default(undefined).required(),
})

const EditeurTemplate = ({
	isEditeurOpen,
	toggleEditeur,
	selectedSchemaId,
	initialValues = undefined,
}: {
	isEditeurOpen: boolean
	toggleEditeur: () => void
	selectedSchemaId?: string
	initialValues?: any
}) => {
	const communes = useSelector(
		(state: any) => state.admin?.users?.communes,
	) as Record<string, ICommune>
	const jsonSchemas = useJsonSchemas()
	const dispatch = useDispatch()
	const adminRestrictions = useAdminRestrictions()

	const methods = useForm({
		defaultValues: schema.getDefault(),
		resolver: yupResolver(schema),
	})

	const onSubmit = async (values) => {
		const { communes: communeValue, ...rest } = values
		const communeValueKey = Object.keys(communeValue)
		let jsonSchemaId = null
		if (selectedSchemaId) {
			dispatch(updateJsonSchema(rest))
		} else {
			jsonSchemaId = uuidv4()
			dispatch(createJsonSchema({ ...rest, id: jsonSchemaId }))
		}
		toggleEditeur()
		const oldRes = {}
		Object.values(communes).forEach((commune: ICommune) => {
			if (commune.jsonSchemaIds.includes(selectedSchemaId)) {
				oldRes[commune.id] = {}
			}
		})
		const usedJsonSchemaId = selectedSchemaId || jsonSchemaId
		const oldArray = Object.keys(oldRes)
		if (oldArray.filter((id) => !communeValueKey.includes(id)).length > 0) {
			// some commune has been removed
			const promises = oldArray
				.filter((id) => !communeValueKey.includes(id))
				.map(async (id) => {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					const { [usedJsonSchemaId]: _rest, ...publicJsonSchemaIds } =
						communes[id].publicJsonSchemaIds
					await Axios.put(`/api/update_communes_admin/${id}`, {
						data: {
							id,
							publicJsonSchemaIds,
							jsonSchemaIds: communes[id].jsonSchemaIds.filter(
								(id) => id !== usedJsonSchemaId,
							),
						},
					})
				})

			await Promise.all(promises)
		}
		if (communeValueKey.filter((id) => !oldArray.includes(id)).length > 0) {
			// some communes has been added
			const promises = communeValueKey
				.filter((id) => !oldArray.includes(id))
				.map(async (id) => {
					await Axios.put(`/api/update_communes_admin/${id}`, {
						data: {
							id,
							jsonSchemaIds:
								communes[id].jsonSchemaIds.concat(usedJsonSchemaId),
						},
					})
				})
			await Promise.all(promises)
		}
		dispatch(getCommunes())
	}

	useEffect(() => {
		if (isEditeurOpen) {
			if (selectedSchemaId) {
				// @ts-ignore
				const res = {}
				Object.values(communes).forEach((commune) => {
					if (commune.jsonSchemaIds.includes(selectedSchemaId)) {
						res[commune.id] = {}
					}
				})
				// @ts-ignore
				methods.reset({
					...jsonSchemas[selectedSchemaId],
					communes: res,
				})
			} else {
				methods.reset(initialValues ?? schema.getDefault())
			}
		}
	}, [isEditeurOpen])

	const onRenameField = ({ oldLabel, newLabel }) => {
		// @ts-ignore
		const sort_by = methods.getValues("sort_by")
		// @ts-ignore
		const new_sort_by = sort_by.map((item) =>
			item.property && item.property === oldLabel
				? { ...item, property: newLabel }
				: item,
		)
		// @ts-ignore
		methods.setValue("sort_by", new_sort_by)
	}
	const onDeleteField = (label) => {
		// @ts-ignore
		const sort_by = methods.getValues("sort_by")
		// @ts-ignore
		const new_sort_by = sort_by.filter((item) => item.property !== label)
		// @ts-ignore
		methods.setValue("sort_by", new_sort_by)
	}

	// disabled if user is admin && if form is own too by at least one unauthorized commune
	const isRestricted =
		adminRestrictions.isRestricted &&
		!!Object.keys(methods.getValues("communes")).filter(
			(comm_id) => !adminRestrictions.allowedCommunes.includes(comm_id),
		).length

	return (
		<FormProvider {...methods}>
			<SuperModal
				isNew={selectedSchemaId === null}
				title={
					selectedSchemaId === null
						? "Création de template"
						: "Edition de template"
				}
				onClick={methods.handleSubmit(onSubmit)}
				onClose={toggleEditeur}
				isOpen={isEditeurOpen}
				size="5xl"
			>
				{isRestricted && (
					<Alert severity="info">
						Actions sur le formulaire restreintes car celui-ci est rattaché à
						d'autres communes. Pour l'éditer pleinement, dupliquez le.
					</Alert>
				)}
				<Flex directionColumn gap="1rem">
					<Flex fullWidth gap="1rem">
						<FImageMarkerPicker name="imgId" disabled={isRestricted} />
						<FText
							name="title"
							label="Titre du schéma JSON"
							disabled={isRestricted}
						/>
					</Flex>
					<Flex fullWidth gap="1rem">
						<FSwitch
							name="is_standard"
							label="Formulaire standard"
							onCheckChange={(checked) => {
								if (checked) {
									const communeIds = Object.keys(communes)
									const communesValue = {}
									communeIds.forEach(
										(communeId) =>
										(communesValue[`${communeId}`] = {
											isPublic: false,
											isShowOnPicrimMap: false,
											allowPublicMapView: false,
										}),
									)
									// @ts-ignore
									methods.setValue("communes", communesValue)
								} else {
									// @ts-ignore
									methods.setValue("communes", [])
								}
							}}
							disabled={isRestricted}
						/>
						<FSwitch
							name="is_telealert"
							label="Formulaire téléalerte"
							disabled={isRestricted}
						/>
					</Flex>

					<FMenuTreeLocation
						mainName="main_category"
						subName="sub_category"
						disabled={isRestricted}
					/>
					<FSelectCommune name="communes" label="Communes" isJsonSchemaEdit />

					<EditeurTemplateCheckRisks disabled={isRestricted} />
					<EditeurTemplateSortBy disabled={isRestricted} />

					<PropertiesEdition
						name={"template.properties"}
						onRenameField={onRenameField}
						onDeleteField={onDeleteField}
					>
						{({ onDragEnd, deleteField, editField, properties }) => (
							<EditeurTemplateFields
								onDragEnd={onDragEnd}
								deleteField={deleteField}
								editField={editField}
								properties={properties}
								disabled={isRestricted}
							/>
						)}
					</PropertiesEdition>
				</Flex>
			</SuperModal>
		</FormProvider>
	)
}

export default EditeurTemplate
