import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Debug from "debug"
import styled, { css } from "styled-components"
import Moment from "moment"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import Axios from "axios"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import useAdminCommunes from "@/hooks/admin/useAdminCommunes"
import AdminTopBar from "./AdminTopBar"
import { Alert } from "@mui/material"
// app:javascript:components:admin:AdminEvents.tsx
const debug = Debug("app:javascript:components:admin:AdminEvents")
debug.log = console.log.bind(console)

const AdminEvents = () => {
	// * state
	//const [state, setState] = useState({})
	const [events, setEvents] = useState([])
	// * selector
	const communes = useAdminCommunes()
	// const selector = useSelector(state => state)

	// * dispatch
	// const dispatch = useDispatch()

	// * history
	// const history = useHistory()

	// * useEffect
	useEffect(() => {
		const fetchData = async () => {
			const response = await Axios.get("/api/events_admin")
			setEvents(response.data.events)
		}
		fetchData()
	}, [])

	const columns = [
		{
			header: "Nom",
			accessorKey: "name",
		},
		{
			header: "Date",
			accessorKey: "startDate",
			cell: (row) => {
				return Moment(row.value).format("DD/MM/YYYY")
			},
		},
		{
			header: "Commune",
			accessorKey: "commune_id",
			cell: ({ row }) => {
				return communes[row.original.commune_id]?.name
			},
		},
	]

	return (
		<>
			<AdminTopBar title="Gestionnaire des événements" />
			<Alert severity="info">Fonctionnalité partiellement fonctionnelle...</Alert>
			<SuperReactTable
				data={events}
				columns={columns}
				initialSortBy={[{ id: "startDate", desc: true }]}
			/>
		</>
	)
}

export default AdminEvents
