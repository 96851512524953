import UploadFileIcon from "@mui/icons-material/UploadFile"
import {
	Alert,
	Button,
	Checkbox,
	FormControlLabel,
	Popover,
} from "@mui/material"
import _ from "lodash"
import Papa from "papaparse"
import React, { useState } from "react"
import { useController, useFormContext } from "react-hook-form"
import {
	PrimaryButton,
	PrimaryOutlinedButton,
	PrimaryTextButton,
} from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import FileDropzone from "../../../telechargement/Upload/component/FileDropzone"

const CsvExampleLoader = ({ setExample, name }) => {
	const { control } = useFormContext()

	const {
		field: { value, onChange },
	} = useController({
		name,
		control,
	})

	const [file, setFile] = useState<File>(undefined)
	const [fileError, setFileError] = useState("")
	const [changeHeaders, setChangeHeaders] = useState(false)

	const [anchorEl, setAnchorEl] = useState(null)
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
		setFile(undefined)
		setFileError("")
		setChangeHeaders(false)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}
	const open = Boolean(anchorEl)

	const importCsv = () => {
		Papa.parse(file, {
			encoding: 'ISO-8859-1',
			header: true,
			transform: (value) => value.replace(/^="((.|\n)*)"$/m, "$1"),
			complete: (results) => {
				if (changeHeaders) {
					onChange(results.meta.fields)
					setExample(results.data)
					handleClose()
				} else {
					const diff = _.difference(value, results.meta.fields)
					if (diff.length === 0) {
						setExample(results.data)
						handleClose()
					} else {
						setFileError("Les en-têtes ne correspondent pas à celles attendues")
					}
				}
			},
		})
	}

	return (
		<>
			<PrimaryOutlinedButton
				onClick={handleClick}
				startIcon={<UploadFileIcon />}
			>
				Téléverser un fichier de test
			</PrimaryOutlinedButton>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
			>
				<Flex padding directionColumn alignItemsStretch gap={10}>
					{file ? (
						<Alert
							severity={fileError ? "error" : "success"}
							action={
								<PrimaryTextButton
									onClick={() => setFile(undefined)}
									color="inherit"
									size="small"
								>
									Changer
								</PrimaryTextButton>
							}
						>
							{file.name}
							{fileError && (
								<>
									<br />
									<b>{fileError}</b>
								</>
							)}
						</Alert>
					) : (
						<FileDropzone allowedExtension={[".csv"]} onFileUpload={setFile} />
					)}
					<FormControlLabel
						control={
							<Checkbox
								checked={changeHeaders}
								onChange={(event) => setChangeHeaders(event.target.checked)}
							/>
						}
						label="Mettre à jour les champs"
					/>
					<PrimaryButton disabled={!file} onClick={importCsv}>
						Importer
					</PrimaryButton>
				</Flex>
			</Popover>
		</>
	)
}

export default CsvExampleLoader
