import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { DragDropContext } from "react-beautiful-dnd"
import { useFormContext } from "react-hook-form"
import { PrimaryOutlinedButton } from "utils/components/style/button"
import {
	AmberChip,
	BlueChip,
	GreenChip,
	RedChip,
} from "utils/components/style/chip"
import { Flex } from "utils/components/style/flex"
import SuperDraggable from "utils/dnd/SuperDraggable"
import SuperDragHandle from "utils/dnd/SuperDragHandle"
import SuperDroppable from "utils/dnd/SuperDroppable"
import NewStyledInputLayout from "utils/form/formUtils/NewStyledInputLayout"
import { IJsonSchemaProperty } from "@/utils/types/IJsonSchema"
import { availableTypes } from "../formulaire_dynamique/EditeurTemplateFieldEdit"
import { IRow } from "./core/MappingSchemaRunner"
import FMappingFieldEditor from "./FMappingFieldEditor"
import PreviewCell from "./components/PreviewCell"
import PreviewController from "./components/PreviewController"
import IMappingSchema from "utils/types/IMapping/IMappingSchema"
import _ from "lodash"

const FMapping = ({
	label,
	name,
	properties,
	editProperties,
	fields,
	example,
	withKey = true,
}: {
	label: string
	name: string
	properties: IJsonSchemaProperty[]
	editProperties?: any
	fields: string[]
	example?: IRow[]
	withKey?: boolean
}) => {
	const {
		getValues,
		setValue,
		formState: { errors },
	} = useFormContext()

	const [mode, setMode] = useState("normal")
	const [rowNumber, setRowNumber] = useState(0)
	const [oldSchema, setOldSchema] = useState(null)

	useEffect(() => {
		const schema = getValues(name) as IMappingSchema
		const newSchema = Object.fromEntries(
			Object.entries(schema).filter(([id]) => {
				const prop = properties.some((p) => p.label === id)
				return Boolean(prop)
			}),
		)
		if (!_.isEqual(oldSchema, newSchema)) {
			setOldSchema(newSchema)
			setValue(name, newSchema)
		}
	}, [properties])

	return (
		<NewStyledInputLayout
			label={label}
			error={name in errors}
			helperText={errors?.[name]?.message}
			empty={false}
		>
			<Table tabIndex={0}>
				<TableHead>
					<TableRow>
						{editProperties && <TableCell />}
						<TableCell width="20%">Nom</TableCell>
						<TableCell width="20%">Type</TableCell>
						<TableCell>Mapping</TableCell>
						<TableCell width="60%">
							<PreviewController
								example={example}
								rowNumber={rowNumber}
								setRowNumber={setRowNumber}
								mode={mode}
								setMode={setMode}
							/>
						</TableCell>
						{editProperties && <TableCell />}
					</TableRow>
				</TableHead>

				<DragDropContext onDragEnd={editProperties?.onDragEnd ?? (() => null)}>
					<SuperDroppable component={TableBody}>
						{properties.map((field: IJsonSchemaProperty, index: number) => {
							const { isColumn, isPcs, isIdentifiant, isRequired } = field

							return (
								<SuperDraggable
									component={TableRow}
									key={field.label}
									id={field.label}
									index={index}
									fullHandle={false}
									style={{
										backgroundColor: "white",
									}}
									disabled={!editProperties}
								>
									{editProperties && (
										<TableCell>
											<SuperDragHandle />
										</TableCell>
									)}
									<TableCell>
										<Flex gap={4} directionColumn alignItemsStretch>
											<div>{field.label}</div>
											<Flex $wrap gap={4}>
												{isColumn && <BlueChip size="small" label="Colonne" />}
												{isPcs && <AmberChip size="small" label="PCS" />}
												{isIdentifiant && <GreenChip size="small" label="ID" />}
												{isRequired && <RedChip size="small" label="Requis" />}
											</Flex>
										</Flex>
									</TableCell>
									<TableCell>{availableTypes[field.type]}</TableCell>

									<TableCell>
										<FMappingFieldEditor
											name={`${name}.${field.label}`}
											property={field}
											fields={fields}
											withKey={withKey}
										/>
									</TableCell>

									<PreviewCell
										exampleRow={example?.[rowNumber]}
										property={field}
										name={`${name}.${field.label}`}
										mode={mode}
									/>

									{editProperties && (
										<TableCell>
											<Flex>
												<IconButton
													tabIndex={-1}
													onClick={() => editProperties.editField(field)}
												>
													<EditIcon />
												</IconButton>
												<IconButton
													tabIndex={-1}
													onClick={() =>
														editProperties.deleteField(field.label)
													}
												>
													<DeleteIcon />
												</IconButton>
											</Flex>
										</TableCell>
									)}
								</SuperDraggable>
							)
						})}
					</SuperDroppable>
				</DragDropContext>
			</Table>
			{editProperties && (
				<Flex alignItemsStretch directionColumn padding={"10px"}>
					<PrimaryOutlinedButton
						onClick={() => editProperties.editField(undefined)}
					>
						Ajouter un champs
					</PrimaryOutlinedButton>
				</Flex>
			)}
		</NewStyledInputLayout>
	)
}

export default FMapping
