import GlobalStyle from "@/pages/app/GlobalStyle"
import { store } from "@/redux-toolkit/store"
import { createBrowserHistory } from "history"
import React from "react"
import { Provider } from "react-redux"
import { Redirect, Route, Router } from "react-router-dom"
import AccessRights from "@/pages/admin/AccessRights"
import Admin from "./Admin"
import AdminArretes from "./AdminArretes"
import AdminContainer from "./AdminContainer"
import AdminError from "./AdminError"
import AdminEvents from "./AdminEvents"
import AdminImport from "./adminimport/AdminImport"
import AdminMapping from "./AdminMapping"
import AdminPicrimCarto from "./picrim/AdminPicrimCarto"
import AdminTags from "./AdminTags"
import AdminTools from "./AdminTools"
import AdminUpdates from "./AdminUpdates"
import JsonSchemas from "./formulaire_dynamique/JsonSchemas"
import AdminMultiPolygons from "./multipolygons/AdminMultiPolygons"
import AdminPicrimPublicInformation from "./picrim/AdminPicrimPublicInformation"
import AdminDicrim from "./picrim/AdminDicrim"
import AdminAddImage from "./AdminAddImage"
import Accounts from "./formulaire_dynamique/accounts/Accounts"
import axios from "axios"
import useIsAdminNumerisk from "@/hooks/useIsAdminNumerisk"

const history = createBrowserHistory()

function getCookie(name) {
	const value = `; ${document.cookie}`
	const parts = value.split(`; ${name}=`)
	if (parts.length === 2) return parts.pop().split(";").shift()
}

const App = () => {
	if (
		document.cookie.includes("access-token") &&
		document.cookie.includes("client") &&
		document.cookie.includes("uid") &&
		!localStorage.getItem("access-token")
	) {
		// retrieve access-token from cookie
		const accessToken = getCookie("access-token")
		const client = getCookie("client")
		const uid = getCookie("uid")
		localStorage.setItem("access-token", accessToken)
		localStorage.setItem("client", client)
		localStorage.setItem("uid", uid)
		axios.defaults.headers.common["access-token"] =
			localStorage.getItem("access-token")
		axios.defaults.headers.common["client"] = localStorage.getItem("client")
		axios.defaults.headers.common["uid"] = localStorage.getItem("uid")
	}
	return (
		<Provider store={store}>
			<Router history={history}>
				<AdminContainer>
					<GlobalStyle />
					<Route exact path="/admin">
						<Admin />
					</Route>
					<Route exact path="/admin/accounts">
						<Accounts />
					</Route>
					<Route exact path="/admin/accessRights">
						<AccessRights />
					</Route>
					<Route exact path="/admin/tools">
						<AdminTools />
					</Route>
					<Route exact path="/admin/import">
						<AdminImport />
					</Route>
					<Route exact path="/admin/mapping">
						<AdminMapping />
					</Route>
					<Route exact path="/admin/errors">
						<AdminError />
					</Route>
					<Route exact path="/admin/admin_events">
						<AdminEvents />
					</Route>
					<Route exact path="/admin/updates">
						<AdminUpdates />
					</Route>
					<Route exact path="/admin/multiPolygons">
						<AdminMultiPolygons />
					</Route>
					<Route exact path="/admin/customdata/json_schemas">
						<JsonSchemas />
					</Route>
					<Route exact path="/admin/tags_manager">
						<AdminTags />
					</Route>
					<Route exact path="/admin/arretes">
						<AdminArretes />
					</Route>

					<Route exact path="/admin/picrim_carto">
						<AdminPicrimCarto />
					</Route>
					<Route exact path="/admin/picrim_jsonschema_attributes">
						<AdminPicrimPublicInformation />
					</Route>
					<Route exact path="/admin/dicrim_numérique">
						<AdminDicrim />
					</Route>
					<Route exact path="/admin/add_image">
						<AdminAddImage />
					</Route>
					<Redirect exact from="/" to="/admin" />
				</AdminContainer>
			</Router>
		</Provider>
	)
}
export default (props) => <App {...props} />
