import { yupResolver } from "@hookform/resolvers/yup"
import Debug from "debug"
import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { Flex } from "utils/components/style/flex"
import FImageMarkerPicker from "utils/form/admin/FImageMarkerPicker"
import FText from "utils/form/FText"
import SuperModal from "utils/modals/SuperModal"
import Yup from "utils/yup"

// app:javascript:utils:form:FSelectWithImage:admin:FOptionsWithImage:FOptionsWithImageModal.tsx
const debug = Debug(
	"app:javascript:utils:form:FSelectWithImage:admin:FOptionsWithImage:FOptionsWithImageModal",
)
debug.log = console.log.bind(console)

interface Props {
	isOpen: boolean
	onClose: () => void
	onSubmit: (data: any) => void
	editingData?: {
		label: string
		imgId: string
	}
}

const schema = Yup.object().shape({
	label: Yup.string().default("").required(),
	imgId: Yup.string().default("").required(),
})

const FOptionsWithImageModal = ({
	isOpen,
	onClose,
	onSubmit,
	editingData,
}: Props) => {
	const methods = useForm({
		resolver: yupResolver(schema),
	})

	useEffect(() => {
		if (editingData) {
			methods.reset(editingData)
		} else {
			methods.reset()
		}
	}, [editingData, isOpen])

	return (
		<FormProvider {...methods}>
			<SuperModal
				isOpen={isOpen}
				onClose={onClose}
				title="Ajouter une option"
				small
				onClick={methods.handleSubmit(onSubmit)}
			>
				<Flex directionColumn gap="1rem">
					<FImageMarkerPicker name="imgId" />
					<FText name="label" label="Label" />
				</Flex>
			</SuperModal>
		</FormProvider>
	)
}

export default FOptionsWithImageModal
