import { Chip } from "@mui/material"
import Debug from "debug"
import React from "react"
import { DragDropContext } from "react-beautiful-dnd"
import colored from "utils/colored"
import { NeutralChip, OrangeOutlinedChip } from "utils/components/style/chip"
import { Flex } from "utils/components/style/flex"
import SuperDraggable from "utils/dnd/SuperDraggable"
import SuperDroppable from "utils/dnd/SuperDroppable"
// app:javascript:utils:form:FSelectHumans:DraggableHumanTags.tsx
const debug = Debug(
	"app:javascript:utils:form:FSelectHumans:DraggableHumanTags.tsx",
)
debug.log = console.log.bind(console)

const DraggableFreeTagsChips = ({
	getTagProps,
	value,
	onDragEnd,
	readOnly,
	count,
}) => {
	return (
		<>
			<DragDropContext onDragEnd={onDragEnd}>
				<SuperDroppable
					component={Flex}
					droppableProps={{
						direction: "horizontal",
					}}
					$wrap
					gap="5px"
				>
					{value.map((option: string, index: number) => (
						<SuperDraggable
							key={option}
							id={option}
							index={index}
							disabled={readOnly}
						>
							<NeutralChip
								{...getTagProps({ index })}
								key={option}
								$backgroundColor={
									count[option]
										? count[option] > 1
											? "var(--orange100)"
											: "var(--green100)"
										: undefined
								}
								label={option}
								{...(readOnly
									? {
											onDelete: undefined,
									  }
									: {
											style: {
												cursor: "grab",
												backgroundColor: "white",
											},
									  })}
							/>
						</SuperDraggable>
					))}
				</SuperDroppable>
			</DragDropContext>
		</>
	)
}

export default DraggableFreeTagsChips
