import { yupResolver } from "@hookform/resolvers/yup"
import NavigateNext from "@mui/icons-material/NavigateNext"
import useAdminCommunes from "@/hooks/admin/useAdminCommunes"
import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { PrimaryButton } from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import FAdminCommunes from "@/utils/form/FAdminCommunes"
import FAdminJsonSchemas from "@/utils/form/FAdminJsonSchemas"
import { FormDependencies } from "@/utils/form/FormDependencies"
import { FormWarning } from "@/utils/form/FormWarning"
import FSwitch from "@/utils/form/FSwitch"
import StaticFormsService from "@/utils/services/StaticFormsService"
import Yup from "@/utils/yup"

const schema = Yup.object({
	commune_id: Yup.string().required(),
	existing_jsonschema: Yup.boolean().default(false),
	jsonschema_id: Yup.string().when("existing_jsonschema", {
		is: false,
		then: (s) => s.transform(() => undefined),
		otherwise: (s) => s.required(),
	}),
})

const CommuneAndJsonSchemaSelector = ({
	setCommuneSelectedId,
	setJsonSchemaSelected,
	currentStep,
	setCurrentStep,
}) => {
	const methods = useForm({
		defaultValues: schema.getDefault(),
		resolver: yupResolver(schema),
	})
	const jsonSchemas = useJsonSchemasWithStatics()
	const communes = useAdminCommunes()
	const onSubmit = (values) => {
		setCommuneSelectedId(values.commune_id)
		setJsonSchemaSelected(jsonSchemas[values.jsonschema_id])
		setCurrentStep(currentStep + 1)
	}

	return (
		<FormProvider {...methods}>
			<Flex directionColumn alignItemsStretch gap>
				<FSwitch
					name="existing_jsonschema"
					label="Utiliser un formulaire existant"
					labelRight
				/>

				<FAdminCommunes name="commune_id" label="Commune" />

				<FormDependencies targets={["existing_jsonschema", "commune_id"]}>
					{({ existing_jsonschema, commune_id }) =>
						existing_jsonschema ? (
							<FAdminJsonSchemas
								name="jsonschema_id"
								label="Formulaire"
								commune_id={commune_id}
							/>
						) : null
					}
				</FormDependencies>

				<FormWarning
					targets={["commune_id", "jsonschema_id"]}
					condition={(communeId, jsonSchemaId) => {
						const allowedFormIds = Object.keys(jsonSchemas).filter((key) => {
							if (StaticFormsService.isStaticForm(key)) {
								return true
							}
							return (communes[communeId]?.jsonSchemaIds ?? []).includes(key)
						})

						return (
							communeId &&
							jsonSchemaId &&
							!allowedFormIds?.includes(jsonSchemaId)
						)
					}}
				>
					Ce formulaire n&apos;est pas associé à la commune
				</FormWarning>
				<Flex flexEnd>
					<PrimaryButton
						onClick={methods.handleSubmit(onSubmit)}
						endIcon={<NavigateNext />}
					>
						Suivant
					</PrimaryButton>
				</Flex>
			</Flex>
		</FormProvider>
	)
}
export default CommuneAndJsonSchemaSelector
