import { Alert } from "@mui/material"
import React from "react"
import { useFormContext } from "react-hook-form"

export const FormWarning = ({ targets, condition, children }) => {
	const methods = useFormContext()
	const values = methods.watch(targets)

	return condition(...values) ? (
		<Alert severity="warning">{children}</Alert>
	) : null
}
