import { FormControlLabel, Switch } from "@mui/material"
import React from "react"
import { useController, useFormContext } from "react-hook-form"

const FInlineSwitch = ({ name, label = undefined, disabled = false }) => {
	const {
		control,
		formState: { errors },
	} = useFormContext()

	const {
		field: { value, onChange, ...inputProps },
	} = useController({
		name,
		control,
		defaultValue: false,
	})

	return (
		<FormControlLabel
			style={{
				width: "100%",
				margin: 0,
			}}
			control={
				<Switch
					checked={value}
					onChange={(event) => onChange(event.target.checked)}
				/>
			}
			label={label}
			disabled={disabled}
		/>
	)
}

export default FInlineSwitch
