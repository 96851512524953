import Modal from "@/styles/organisms/Modal"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import {
	Checkbox,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@mui/material"
import { updateJsonSchema } from "@/redux-toolkit/data/jsonSchema/resources"
import _ from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { PrimaryButton } from "utils/components/style/button"

const PicrimPublicInfosModal = ({ isOpen, setIsOpen, jsonSchema }) => {
	const dispatch = useDispatch()
	const onClose = () => setIsOpen(!isOpen)

	// debugger

	const schemaProperties = useMemo(
		() => jsonSchema?.template?.properties,
		[jsonSchema],
	)

	const [newProperties, setNewProperties] = useState(schemaProperties)

	useEffect(() => {
		setNewProperties(schemaProperties)
	}, [jsonSchema])

	const handleChange = (propertyToEdit, value) => {
		const clonedProperties = _.cloneDeep(newProperties)
		_.set(
			clonedProperties[newProperties.indexOf(propertyToEdit)],
			"isPublic",
			value,
		)
		setNewProperties(clonedProperties)
	}

	const handleSave = () => {
		const jsonSchemaWithNewProps = {
			...jsonSchema,
			template: {
				properties: newProperties,
			},
		}

		dispatch(updateJsonSchema(jsonSchemaWithNewProps))
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} backdrop="static">
			<ModalHeader>Informations publiques - {jsonSchema?.title}</ModalHeader>
			<ModalBody>
				<List>
					<Divider />
					{newProperties?.map((property) => (
						<>
							<ListItem key={property.name}>
								<ListItemText primary={property.name} />
								<ListItemIcon>
									<Checkbox
										edge="end"
										checked={property.isPublic ?? false}
										onChange={(event, checked) => {
											handleChange(property, checked)
										}}
									/>
								</ListItemIcon>
							</ListItem>
							<Divider />
						</>
					))}
				</List>
			</ModalBody>
			<ModalFooter>
				<PrimaryButton
					onClick={() => {
						handleSave()
						onClose()
					}}
				>
					Sauvegarder
				</PrimaryButton>
			</ModalFooter>
		</Modal>
	)
}

export default PicrimPublicInfosModal
