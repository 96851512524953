import { Card, CardActions, CardContent, Grid } from "@mui/material"
import {
	getCdcs,
	getCommunes,
	getUsers,
} from "@/redux-toolkit/admin/accounts/resources"
import { getAdminJsonSchemas } from "@/redux-toolkit/data/jsonSchema/resources"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import styled from "styled-components"
import {
	GreenButton,
	OrangeButton,
	PrimaryButton,
	PurpleButton,
	RedButton,
	YellowButton,
} from "@/utils/components/style/button"
import { useDispatch } from "react-redux"
import { getAdminMappings } from "@/redux-toolkit/data/mappings/ressources"
import { fetchTags } from "@/redux-toolkit/common/resources"
import Axios from "axios"
import BoardWidget from "@/utils/components/widget/BoardWidget"
import { Flex } from "@/utils/components/style/flex"
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import DescriptionIcon from '@mui/icons-material/Description'
import SettingsIcon from '@mui/icons-material/Settings'
import LayersIcon from '@mui/icons-material/Layers'
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial'
import WarningIcon from '@mui/icons-material/Warning'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import Button from "@/styles/atoms/Button"
import { H2 } from "@/utils/components/style/header"
import { Chip } from "@nextui-org/react"

const FlexContainer = styled(Flex)`
	width: max-content;
`
const SManageAccountsIcon = styled(ManageAccountsIcon)`
    color: white;
` as any
const SDescriptionIcon = styled(DescriptionIcon)`
    color: white;
` as any
const SSettingsIcon = styled(SettingsIcon)`
    color: white;
` as any
const SLayersIcon = styled(LayersIcon)`
    color: white;
` as any
const SFolderSpecialIcon = styled(FolderSpecialIcon)`
    color: white;
` as any
const SWarningIcon = styled(WarningIcon)`
    color: white;
` as any
const SAddPhotoAlternateIcon = styled(AddPhotoAlternateIcon)`
    color: white;
` as any

const csrfToken = document
	.querySelector("meta[name=csrf-token]")
	.getAttribute("content")
console.log("csrfToken", csrfToken)
Axios.defaults.headers.common["X-CSRF-Token"] = csrfToken
/* button as link needs ts-ignore : https://github.com/mui-org/material-ui/issues/16846 */

const Admin = ({ userSettings }) => {
	const dispatch = useDispatch()
	const history = useHistory()

	useEffect(() => {
		dispatch(getAdminJsonSchemas())
		dispatch(getUsers())
		dispatch(getCommunes())
		dispatch(getCdcs())
		dispatch(getAdminMappings())
		dispatch(fetchTags)
	}, [])

	return (
		<Flex fullWidth directionColumn gap=".25em">
			<Flex fullWidth spaceBetween>
				<Flex gap=".5em">
					<H2>Numerisk Admin -</H2>
					<Chip variant="bordered">{userSettings.email}</Chip>
				</Flex>
				<Button color="primary" variant="bordered" onClick={() =>
					window.location.href = "/home"
				}>Retour à Numérisk</Button>
			</Flex>
			<Grid container spacing={2}>
				<Grid item xs={3}>
					{/* =-=-=-=-=-=-=-=-= USERS MANAGER =-=-=-=-=-=-=-=-= */}
					<BoardWidget
						title="Gestionnaire des utilisateurs"
						icon={<SManageAccountsIcon fontSize="large" />}
						colorLight="var(--orange300)"
						colorDark="var(--orange500)"
						responsiveHeight={false}
						fullHeight
					>
						<Flex justifyCenter>
							<FlexContainer directionColumn gap="1rem">
								<Button fullWidth $backgroundColor="var(--orange400)" $textColor="white" onClick={() => history.push("/admin/accounts")}>
									Gérer les comptes
								</Button>
								<Button fullWidth $backgroundColor="var(--orange400)" $textColor="white" onClick={() => history.push("/admin/accessRights")}>
									Attribuer les permissions
								</Button>
							</FlexContainer>
						</Flex>
					</BoardWidget>
				</Grid>
				<Grid item xs={3}>
					{/* =-=-=-=-=-=-=-=-= DYNAMICS FILES MANAGER =-=-=-=-=-=-=-=-= */}
					<BoardWidget
						title="Gestionnaire des formulaires"
						icon={<SDescriptionIcon fontSize="large" />}
						fullHeight
					>
						<Flex justifyCenter>
							<FlexContainer directionColumn gap="1rem">
								<Button fullWidth color="primary" onClick={() => history.push("/admin/customdata/json_schemas")}>
									Gérer les formulaires dynamiques
								</Button>
								<Button fullWidth color="primary" onClick={() => history.push("/admin/import")}>
									Importer des données
								</Button>
								<Button fullWidth color="primary" onClick={() => history.push("/admin/mapping")}>
									Gérer les mapping de données
								</Button>
								<Button fullWidth color="primary" onClick={() => history.push("/admin/tags_manager")}>
									Gérer les tags
								</Button>
							</FlexContainer>
						</Flex>
					</BoardWidget>
				</Grid>
				<Grid item xs={3}>
					{/* =-=-=-=-=-=-=-=-= APPLICATION INFOS =-=-=-=-=-=-=-=-= */}
					<BoardWidget
						title="Infos de l'application"
						icon={<SSettingsIcon fontSize="large" />}
						colorLight="var(--red500)"
						colorDark="var(--red700)"
						fullHeight
					>
						<Flex justifyCenter>
							<FlexContainer directionColumn gap="1rem">
								<Button fullWidth $backgroundColor="var(--red500)" $textColor="white" onClick={() => history.push("/admin/errors")}>
									Consulter le journal des erreurs
								</Button>
								<Button fullWidth $backgroundColor="var(--red500)" $textColor="white" onClick={() => history.push("/admin/updates")}>
									Gérer les annonces de MàJ
								</Button>
							</FlexContainer>
						</Flex>
					</BoardWidget>
				</Grid>
				<Grid item xs={3}>
					{/* =-=-=-=-=-=-=-=-= MULTIPOLYGONS =-=-=-=-=-=-=-=-= */}
					<BoardWidget
						title="MultiPolygons"
						icon={<SLayersIcon fontSize="large" />}
						colorLight="var(--purple200)"
						colorDark="var(--purple400)"
						fullHeight
					>
						<Flex justifyCenter>
							<FlexContainer directionColumn gap="1rem">
								<Button fullWidth $backgroundColor="var(--purple300)" $textColor="white" onClick={() => history.push("/admin/multiPolygons")}>
									Gérer les MultiPolygons
								</Button>
							</FlexContainer>
						</Flex>
					</BoardWidget>
				</Grid>
				<Grid item xs={3}>
					{/* =-=-=-=-=-=-=-=-= SCENARIOS FILES =-=-=-=-=-=-=-=-= */}
					<BoardWidget
						title="Fiches Scénarios"
						icon={<SFolderSpecialIcon fontSize="large" />}
						colorLight="var(--cyan300)"
						colorDark="var(--cyan500)"
						fullHeight
					>
						<Flex justifyCenter>
							<FlexContainer directionColumn gap="1rem">
								<Button fullWidth $backgroundColor="var(--cyan400)" $textColor="white" onClick={() => history.push("/admin/arretes")}>
									Transférer des fiches scénarios
								</Button>
							</FlexContainer>
						</Flex>
					</BoardWidget>
				</Grid>
				<Grid item xs={3}>
					{/* =-=-=-=-=-=-=-=-= EVENTS MANAGER =-=-=-=-=-=-=-=-= */}
					<BoardWidget
						title="Gestionnaire des crises"
						icon={<SWarningIcon fontSize="large" />}
						colorLight="var(--grey400)"
						colorDark="var(--grey600)"
						fullHeight
					>
						<Flex justifyCenter>
							<FlexContainer directionColumn gap="1rem">
								<Button fullWidth $backgroundColor="var(--grey500)" $textColor="white" onClick={() => history.push("/admin/admin_events")}>
									Gérer les événements
								</Button>
							</FlexContainer>
						</Flex>
					</BoardWidget>
				</Grid>
				<Grid item xs={3}>
					{/* =-=-=-=-=-=-=-=-= ICONS IMPORT =-=-=-=-=-=-=-=-= */}
					<BoardWidget
						title="Importation d'icônes"
						icon={<SAddPhotoAlternateIcon fontSize="large" />}
						colorLight="var(--yellow400)"
						colorDark="var(--yellow600)"
						fullHeight
					>
						<Flex justifyCenter>
							<FlexContainer directionColumn gap="1rem">
								<Button fullWidth $backgroundColor="var(--yellow500)" $textColor="white" onClick={() => history.push("/admin/add_image")}>
									Importer un icône (Carto - Form dynamique)
								</Button>
							</FlexContainer>
						</Flex>
					</BoardWidget>
				</Grid>
			</Grid>
		</Flex>
	)

	// !  Already desactivated code, still keeping it for later
	{/* <Card>
		<CardContent>Cartographie PICRIM</CardContent>
		<CardActions>
			<YellowButton //@ts-ignore
				component={Link}
				to="/admin/picrim_carto"
				disabled
			>
				Affichage éléments
			</YellowButton>
			<YellowButton //@ts-ignore
				component={Link}
				disabled
				to="/admin/picrim_jsonschema_attributes"
			>
				Informations publiques
			</YellowButton>
		</CardActions>
	</Card>
	<Card>
		<CardContent>DICRIM Numérique</CardContent>
		<CardActions>
			<YellowButton //@ts-ignore
				component={Link}
				disabled
				to="/admin/dicrim_numérique"
			>
				DICRIM Numérique
			</YellowButton>
		</CardActions>
	</Card> */}
}

const mapStateToProps = (state) => ({
	userSettings: state.userSettings,
})

export default connect(mapStateToProps, null)(Admin)