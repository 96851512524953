import React from 'react'
import Button from '@/styles/atoms/Button'
import { Flex } from '@/utils/components/style/flex'
import { useHistory } from 'react-router-dom'
import { H2 } from '@/utils/components/style/header'
import styled from 'styled-components'

const FlexContainer = styled(Flex)`
    padding-bottom: .5em;
`

const AdminTopBar = ({ title, children = null }) => {
    const history = useHistory()

    return (
        <FlexContainer fullWidth directionColumn alignItemsStart gap="0.25rem">
            <H2>{title}</H2>
            <Flex fullWidth spaceBetween>
                <Button color="primary" variant="bordered" onClick={() => history.push("/admin")}>Retour au menu</Button>
                <Flex gap="0.5rem">
                    {children}
                </Flex>
            </Flex>
        </FlexContainer>
    )
}
export default AdminTopBar