import React, { useMemo, useState, useEffect } from "react"
import Axios from "axios"
import { PrimaryButton } from "utils/components/style/button"
import { FlexButtons, FlexHeader } from "utils/components/style/flex"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import ErrorService from "@/services/ErrorService"
import EditeurTag from "./formulaire_dynamique/EditeurTag"
import { useDispatch } from "react-redux"
import AdminTopBar from "./AdminTopBar"
import Button from "@/styles/atoms/Button"
const AdminTags = () => {
	const [isOpen, setIsOpen] = useState(false)
	const [selectedTag, setSelectedTag] = useState(undefined)
	const [isNew, setIsNew] = useState(false)
	const [tags, setTags] = useState([])
	const dispatch = useDispatch()
	const toggleEditeur = () => {
		setIsOpen(!isOpen)
	}

	const fetchTags = async () => {
		try {
			const response = await Axios.get("/api/tag")
			setTags(response.data)
		} catch (error) {
			const errorMessage = `Echec lors de la récupération des tags : ${error}`
			ErrorService.error({
				component: "AdminTags:fetchTags",
				message: errorMessage,
				dispatch,
			})
		}
	}

	useEffect(() => {
		fetchTags()
	}, [])

	const deleteTag = async (tagId) => {
		try {
			await Axios.delete(`/api/tag/${tagId}`)
			const res = await Axios.get("/api/tag")
			setTags(res.data)
		} catch (error) {
			const errorMessage = `Echec de la tentative de suppréssion du tag : ${error}`
			ErrorService.error({
				component: "AdminTags:deleteTag",
				message: errorMessage,
				dispatch,
			})
		}
	}

	const columns = useMemo(
		() => [
			{
				header: "Id",
				accessorKey: "id",
			},

			{
				header: "Type",
				accessorKey: "category",
			},
			{
				header: "Nom",
				accessorKey: "label",
			},
		],
		[],
	)

	return (
		<>
			<AdminTopBar title="Gestionnaire des tags">
				<Button color="primary" onClick={() => {
					setSelectedTag(null)
					setIsNew(true)
					toggleEditeur()
				}}>
					Ajouter un nouveau tag
				</Button>
			</AdminTopBar>
			<SuperReactTable
				data={tags}
				columns={columns}
				onEditClick={(original: any) => {
					setSelectedTag(original)
					setIsNew(false)
					toggleEditeur()
				}}
				onDeleteClick={(original: any) => {
					deleteTag(original.id)
				}}
			/>

			<EditeurTag
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				toggleEditeur={toggleEditeur}
				selectedTag={selectedTag}
				fetchTags={fetchTags}
				isNew={isNew}
			/>
		</>
	)
}

export default AdminTags
