import React from "react"
import { Box, Card } from "@mui/material"
import styled from "styled-components"
import { useFormContext, useWatch } from "react-hook-form"
import { H4 } from "utils/components/style/header"

const SCard = styled(Card)`
    padding: 1rem;
`

const SBox = styled(Box)<{ colour: string }>`
    background-color: ${(props) => props.colour};
    min-height: 50px;
    min-width: 50px;
`

const ColourPreview = () => {
	const { getValues, control } = useFormContext()

	const primaryColour = useWatch({ control, name: "colours.primary" })
	const secondaryColour = useWatch({ control, name: "colours.secondary" })

	return (
		<>
			<SCard>
				<H4>Aperçu des couleurs</H4>
				<SBox colour={primaryColour} />
				<SBox colour={secondaryColour} />
			</SCard>
		</>
	)
}

export default ColourPreview
