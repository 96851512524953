import Axios from "axios"
import PicrimInfosModal from "@/pages/admin/picrim/PicrimInfosModal"
import React, { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { PrimaryButton } from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import SuperReactTable from "@/utils/components/tables/SuperReactTable"
import FDate from "@/utils/form/FDate"
import FSelect from "@/utils/form/FSelect"
import SuperModal from "@/utils/modals/SuperModal"
import Yup from "@/utils/yup"
import { useInternalColumns } from "./AdminModalEditingCommunes.utils"
import { yupResolver } from "@hookform/resolvers/yup"
import FAdminJsonSchemas from "@/utils/form/FAdminJsonSchemas"
import _ from "lodash"

const schema = Yup.object({
	dateContrat: Yup.date().required("Date de fin de contrat est obligatoire"),
	publicJsonSchemaIds: Yup.object().shape({}).default({}),
	// "tempPublicIds" value must be, and is it, filtered before updating commune
	tempPublicIds: Yup.array(Yup.string()),
})

const AdminModalEditingCommunes = ({
	isOpen,
	commune = undefined,
	onClose,
}) => {
	const [isOpenModal, setIsOpenModal] = useState(false)

	const methods = useForm({
		defaultValues: commune
			? { ...commune, tempPublicIds: Object.keys(commune.publicJsonSchemaIds) }
			: schema.getDefault(),
		resolver: yupResolver(schema),
	})
	const columns = useInternalColumns({ commune, methods })

	useEffect(() => {
		methods.reset(commune ? { ...commune, tempPublicIds: Object.keys(commune.publicJsonSchemaIds) } : schema.getDefault())
	}, [isOpen, commune])

	const onSubmit = (values) => {
		const { tempPublicIds, ...vals } = values
		const clonedCommune = { ...commune, ...vals }
		delete clonedCommune.cdc
		delete clonedCommune.cdc_geojson
		delete clonedCommune.twitter_tags
		const updateCommunes = async () => {
			Axios.put(`/api/update_communes_admin/${vals.id}`, {
				data: clonedCommune,
			})
		}
		updateCommunes()
		onClose()
		window.location.reload()
	}

	const onChange = () => {
		const selectedIds = methods.getValues("tempPublicIds")
		const previousPublicForms = methods.getValues("publicJsonSchemaIds")
		if (previousPublicForms) {
			const newDatas = _.cloneDeep(previousPublicForms)
			const concernedId =
				_.difference(selectedIds, Object.keys(previousPublicForms))[0] ??
				_.difference(Object.keys(previousPublicForms), selectedIds)[0]

			if (Object.keys(previousPublicForms).length < selectedIds.length) {
				// ? Adding
				newDatas[concernedId] = { allowPublicRegistration: false, allowPublicMapView: false }
			} else {
				// ? Removing
				delete newDatas[concernedId]
			}
			methods.setValue("publicJsonSchemaIds", newDatas)
		}
	}

	return (
		<>
			<FormProvider {...methods}>
				<SuperModal
					isOpen={isOpen}
					onClose={onClose}
					title={"Editer une commune : " + commune?.name}
					onClick={methods.handleSubmit(onSubmit)}
					size="4xl"
				>
					<Flex gap="1rem" directionColumn>
						<FDate name="dateContrat" label="Date de fin de contrat" />
						<PrimaryButton onClick={() => setIsOpenModal(true)}>
							Éditer les infos PICRIM
						</PrimaryButton>
						<FAdminJsonSchemas
							name="tempPublicIds"
							label="Formulaires partagés dans le PICRIM"
							commune_id={commune?.id}
							multiple
							onItemClickCallback={onChange}
						/>
						<SuperReactTable
							data={methods.getValues("tempPublicIds") ?? []}
							columns={columns}
							isEditable={false}
							isDeletable={false}
						/>
					</Flex>
				</SuperModal>
			</FormProvider>
			<PicrimInfosModal
				commune={commune}
				setCurrentCommune={() => { }}
				onClose={() => setIsOpenModal(false)}
				isOpen={isOpenModal}
			/>
		</>
	)
}

export default AdminModalEditingCommunes
