import React, { useState } from "react"
import { Input, Card, CardContent } from "@mui/material"
import Axios from "axios"
import { toast } from "react-toastify"
import { GreenButton, RedButton } from "utils/components/style/button"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
const Container = styled.div`
    padding: 1em;
`
const AdminTools = ({ history }) => {
	const [codeInsee, setCodeInsee] = useState("17300")
	const [mail, setMail] = useState("paul.heymes@gmail.com")

	const sendRapport = () => {
		Axios.get(`/sendmail?code_insee=${codeInsee}&mail=${mail}`)
		toast.success("rapport envoyé")
	}

	return (
		<Container>
			{" "}
			<RedButton onClick={history.goBack}>Retour</RedButton>
			<Card style={{ width: "300px" }}>
				<CardContent>
					<>
						<Input
							onChange={(e) => setCodeInsee(e.target.value)}
							value={codeInsee}
						/>
						<Input onChange={(e) => setMail(e.target.value)} value={mail} />
						<GreenButton onClick={sendRapport}>Rapport commune</GreenButton>
					</>
				</CardContent>
			</Card>
		</Container>
	)
}

export default withRouter(AdminTools)
