import { yupResolver } from "@hookform/resolvers/yup"
import NavigateNext from "@mui/icons-material/NavigateNext"
import EditeurTemplateCheckRisks from "@/pages/admin/formulaire_dynamique/EditeurTemplateCheckRisks"
import EditeurTemplateSortBy from "@/pages/admin/formulaire_dynamique/EditeurTemplateSortBy"
import PropertiesEdition from "@/pages/admin/formulaire_dynamique/PropertiesEdition"
import FMapping from "@/pages/admin/mapping/FMapping"
import { mappingToolInstanceValidator } from "@/pages/admin/mapping/mappingTools/mappingTools"
import React, { useMemo } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { PrimaryButton } from "utils/components/style/button"
import { Flex } from "utils/components/style/flex"
import FMenuTreeLocation from "utils/form/admin/FMenuTreeLocation"
import FakeForm from "utils/form/FakeForm"
import FFreeTags from "utils/form/FFreeTags/FFreeTags"
import { FormDependencies } from "utils/form/FormDependencies"
import FText from "utils/form/FText"
import IJsonSchema, {
	IJsonSchemaProperty,
	JsonSchemaPropertiesType,
} from "@/utils/types/IJsonSchema"
import IMappingSchema from "utils/types/IMapping/IMappingSchema"
import IMappingSchemaItem from "utils/types/IMapping/IMappingSchemaItem"
import Yup from "utils/yup"
import { v4 as uuidv4 } from "uuid"
import { ObjectSchema } from "yup"

const MappingAndJsonSchemaCreator = ({
	communeSelectedId,
	csvData,
	setJsonSchemaSelected,
	setCurrentStep,
	currentStep,
	toggleNewJsonSchema,
	setMappingSchema,
}) => {
	const defaultProperties = useMemo(
		() =>
			Object.keys(csvData[0]).map(
				(item): IJsonSchemaProperty => ({
					isColumn: true,
					isIdentifiant: false,
					isPcs: false,
					isRequired: false,
					isPublic: false,
					label: item,
					name: "",
					type: JsonSchemaPropertiesType.TEXT,
				}),
			),
		[],
	)
	const defaultMapping = useMemo(
		() =>
			Object.fromEntries(
				Object.keys(csvData[0]).map((item): [string, IMappingSchemaItem] => [
					item,
					{
						converter: "textFromText",
						singleInputs: { field: item },
					},
				]),
			),
		[],
	)

	const schema = useMemo(
		() =>
			Yup.object().shape({
				jsonSchema: Yup.object({
					id: Yup.string()
						.uuid()
						.default(() => uuidv4()),
					title: Yup.string()
						.required()
						.default("")
						.test(
							"no-slash",
							"Le titre ne peut pas contenir de /",
							(value) => !value.includes("/"),
						),
					is_standard: Yup.boolean().required().default(false),
					main_category: Yup.string().nullable().required().default(null),
					sub_category: Yup.string().nullable().required().default(null),
					communes: Yup.object().default({
						[`${communeSelectedId}`]: {
							isPublic: false,
						},
					}),
					template: Yup.object({
						properties: Yup.array().default(defaultProperties),
					}),
					sort_by: Yup.array(Yup.mixed()).ensure().default([]),
				}).required(),
				mappingSchema: Yup.object()
					.when(
						"jsonSchema",
						(jsonSchema: IJsonSchema, schema: ObjectSchema<never>) => {
							const properties = jsonSchema?.template?.properties
							if (!properties) return schema

							return schema
								.shape(
									Object.fromEntries(
										properties.map((prop) => [
											prop.label,
											prop.isRequired
												? mappingToolInstanceValidator.required()
												: mappingToolInstanceValidator,
										]),
									),
								)
								.noUnknown()
								.required()
						},
					)
					.default(defaultMapping),
			}),
		[],
	)

	const methods = useForm({
		defaultValues: schema.getDefault(),
		resolver: yupResolver(schema),
	})

	const onRenameField = ({ oldLabel, newLabel }) => {
		// @ts-ignore
		const sort_by = methods.getValues("jsonSchema.sort_by")
		methods.setValue(
			// @ts-ignore
			"jsonSchema.sort_by",
			// @ts-ignore
			sort_by.map((item) =>
				item.property && item.property === oldLabel
					? { ...item, property: newLabel }
					: item,
			),
		)
	}
	const onDeleteField = (label) => {
		// @ts-ignore
		const sort_by = methods.getValues("jsonSchema.sort_by")
		methods.setValue(
			// @ts-ignore
			"jsonSchema.sort_by",
			// @ts-ignore
			sort_by.filter((item) => item.property !== label),
		)
	}

	const fields = useMemo(() => Object.keys(csvData[0]), [csvData])

	return (
		<FormProvider {...methods}>
			<Flex directionColumn alignItemsStretch gap>
				<FText name="jsonSchema.title" label="Titre du schéma JSON" />

				<FMenuTreeLocation
					mainName="jsonSchema.main_category"
					subName="jsonSchema.sub_category"
				/>

				<EditeurTemplateCheckRisks
					dependencies={[
						"jsonSchema.communes",
						"jsonSchema.main_category",
						"jsonSchema.sub_category",
					]}
				/>
				<EditeurTemplateSortBy
					name="jsonSchema.sort_by"
					dependencie="jsonSchema.template.properties"
				/>
				<FormDependencies targets={["mappingSchema"]} memo={[fields]}>
					{({ mappingSchema }) => {
						const highlight = Object.values(
							(mappingSchema as IMappingSchema) ?? {},
						).reduce(
							(acc, val) => [
								...acc,
								...Object.values(val?.singleInputs ?? {}),
								...Object.values(val?.arrayInputs ?? {}).reduce(
									(a, v) => [...a, ...v],
									[],
								),
							],
							[],
						)

						return (
							<FakeForm values={{ fields }}>
								<FFreeTags
									name="fields"
									label="Champs"
									highlight={highlight}
									readOnly
								/>
							</FakeForm>
						)
					}}
				</FormDependencies>

				<PropertiesEdition
					name={"jsonSchema.template.properties"}
					onRenameField={onRenameField}
					onDeleteField={onDeleteField}
				>
					{({ properties, ...editProperties }) => (
						<FMapping
							label="Mapping"
							name="mappingSchema"
							properties={properties}
							editProperties={editProperties}
							fields={fields}
							example={csvData}
							withKey={false}
						/>
					)}
				</PropertiesEdition>

				<Flex flexEnd>
					<PrimaryButton
						onClick={methods.handleSubmit(({ jsonSchema, mappingSchema }) => {
							setJsonSchemaSelected(jsonSchema)
							setMappingSchema(mappingSchema)
							setCurrentStep(currentStep + 1)
							toggleNewJsonSchema()
						})}
						endIcon={<NavigateNext />}
					>
						Suivant
					</PrimaryButton>
				</Flex>
			</Flex>
		</FormProvider>
	)
}
export default MappingAndJsonSchemaCreator
