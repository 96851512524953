export enum PERMISSION_CATEGORY {
	ACCOUNT_CONFIG = "ACCOUNT_CONFIG",
	GESTION_CRISE = "GESTION_CRISE",
	SUIVI_CRISE = "SUIVI_CRISE",
	COOPERATION = "COOPERATION",
	CUSTOM_DATA = "CUSTOM_DATA",
	DOCUMENTS = "DOCUMENTS",
	JSONSCHEMAS = "JSONSCHEMAS",
}

export interface IPermissionAdminElement {
	category: PERMISSION_CATEGORY
	title: string
	path: string[]
	readPermissionPath?: string
	writePermissionPath?: string[]
	childrenPaths?: string[]
	parentPath?: string[]
}

const configPermissionsAdmin: IPermissionAdminElement[] = [
	{
		category: PERMISSION_CATEGORY.ACCOUNT_CONFIG,
		title: "Accès à la configuration du compte",
		path: [
			"accessRights.tools.CONFIGURATION.read",
			"accessRights.tools.CONFIGURATION.write",
		],
	},
	{
		category: PERMISSION_CATEGORY.GESTION_CRISE,
		title: "Accès à l'outil de gestion de crise",
		path: ["accessRights.tools.GESTION_CRISE.read"],
		childrenPaths: [
			"accessRights.tools.GESTION_CRISE.write",
			"accessRights.tools.GESTION_CRISE.canDeleteCrisis",
			"accessRights.tools.GESTION_CRISE.canLockCrisis",
			"accessRights.tools.GESTION_CRISE.CREATION_CRISE.read",
			"accessRights.tools.GESTION_CRISE.CREATION_CRISE.write",
			"accessRights.tools.GESTION_CRISE.DASHBOARD.read",
			"accessRights.tools.GESTION_CRISE.DASHBOARD.write",
			"accessRights.tools.GESTION_CRISE.CARTOGRAPHY.read",
			"accessRights.tools.GESTION_CRISE.CARTOGRAPHY.write",
			"accessRights.tools.GESTION_CRISE.ORGA.read",
			"accessRights.tools.GESTION_CRISE.ORGA.write",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.ALERTE.read",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.ALERTE.write",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.read",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INCIDENT.read",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INCIDENT.write",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INCIDENT.canDeleteDatas",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INTERVENTION.read",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INTERVENTION.write",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INTERVENTION.canDeleteDatas",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.CARE.read",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.CARE.write",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.CARE.canDeleteDatas",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.FICHE.read",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.FICHE.write",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.ARRETE.read",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.ARRETE.write",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.ARRETE.canDeleteDatas",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.SUIVI_PCS.read",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.SUIVI_PCS.write",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.SUIVI_PCS.canDeleteDatas",
		],
	},
	{
		category: PERMISSION_CATEGORY.GESTION_CRISE,
		title: "Droit d'édition d'une crise",
		path: ["accessRights.tools.GESTION_CRISE.write"],
		parentPath: ["accessRights.tools.GESTION_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.GESTION_CRISE,
		title: "Droit de suppression d'une crise",
		path: ["accessRights.tools.GESTION_CRISE.canDeleteCrisis"],
		parentPath: ["accessRights.tools.GESTION_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.GESTION_CRISE,
		title: "Droit de verrouillage d'une crise",
		path: ["accessRights.tools.GESTION_CRISE.canLockCrisis"],
		parentPath: ["accessRights.tools.GESTION_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.GESTION_CRISE,
		title: "Droit de création d'une crise",
		path: [
			"accessRights.tools.GESTION_CRISE.CREATION_CRISE.read",
			"accessRights.tools.GESTION_CRISE.CREATION_CRISE.write",
		],
		parentPath: ["accessRights.tools.GESTION_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.GESTION_CRISE,
		title: "Accès au tableau de bord de gestion de crise",
		path: [
			"accessRights.tools.GESTION_CRISE.DASHBOARD.read",
			"accessRights.tools.GESTION_CRISE.DASHBOARD.write",
		],
		parentPath: ["accessRights.tools.GESTION_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.GESTION_CRISE,
		title: "Accès à l'organigramme de crise",
		path: [
			"accessRights.tools.GESTION_CRISE.ORGA.read",
			"accessRights.tools.GESTION_CRISE.ORGA.write",
		],
		parentPath: ["accessRights.tools.GESTION_CRISE.read"],
	},
	// ! PATH WILL NEED TO BE EDITED, Télé-Alerte is no longer into SUIVI_CRISE
	{
		category: PERMISSION_CATEGORY.GESTION_CRISE,
		title: "Accès à l'outil de télé-alerte",
		path: [
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.ALERTE.write",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.ALERTE.read",
		],
		parentPath: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.GESTION_CRISE,
		title: "Accès à la cartographie de gestion de crise",
		path: ["accessRights.tools.GESTION_CRISE.CARTOGRAPHY.read"],
		writePermissionPath: ["accessRights.tools.GESTION_CRISE.CARTOGRAPHY.write"],
		parentPath: ["accessRights.tools.GESTION_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.GESTION_CRISE,
		title: "Droit d'édition sur la cartographie de gestion de crise",
		path: ["accessRights.tools.GESTION_CRISE.CARTOGRAPHY.write"],
		readPermissionPath: "accessRights.tools.GESTION_CRISE.CARTOGRAPHY.read",
		parentPath: ["accessRights.tools.GESTION_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.SUIVI_CRISE,
		title: "Accès à la main courante",
		path: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.read"],
		childrenPaths: [
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INCIDENT.read",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INCIDENT.write",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INCIDENT.canDeleteDatas",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INTERVENTION.read",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INTERVENTION.write",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INTERVENTION.canDeleteDatas",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.CARE.read",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.CARE.write",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.CARE.canDeleteDatas",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.FICHE.read",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.FICHE.write",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.ARRETE.read",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.ARRETE.write",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.ARRETE.canDeleteDatas",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.SUIVI_PCS.read",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.SUIVI_PCS.write",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.SUIVI_PCS.canDeleteDatas",
		],
		parentPath: ["accessRights.tools.GESTION_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.SUIVI_CRISE,
		title: "Accès aux fiches scénarios d'une crise",
		path: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.FICHE.read"],
		writePermissionPath: [
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.FICHE.write",
		],
		parentPath: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.SUIVI_CRISE,
		title: "Droit de sélection des fiches scénarios pour une crise",
		path: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.FICHE.write"],
		readPermissionPath:
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.FICHE.read",
		parentPath: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.SUIVI_CRISE,
		title: "Accès aux événements d'une crise",
		path: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INCIDENT.read"],
		writePermissionPath: [
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INCIDENT.write",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INCIDENT.canDeleteDatas",
		],
		parentPath: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.SUIVI_CRISE,
		title: "Droit d'édition des événements d'une crise",
		path: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INCIDENT.write"],
		readPermissionPath:
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INCIDENT.read",
		parentPath: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.SUIVI_CRISE,
		title: "Droit de suppression des événements",
		path: [
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INCIDENT.canDeleteDatas",
		],
		readPermissionPath:
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INCIDENT.read",
		parentPath: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.SUIVI_CRISE,
		title: "Accès aux interventions d'une crise",
		path: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INTERVENTION.read"],
		writePermissionPath: [
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INTERVENTION.write",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INTERVENTION.canDeleteDatas",
		],

		parentPath: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.SUIVI_CRISE,
		title: "Droit d'édition des interventions d'une crise",
		path: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INTERVENTION.write"],
		readPermissionPath:
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INTERVENTION.read",
		parentPath: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.SUIVI_CRISE,
		title: "Droit de suppression des interventions",
		path: [
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INTERVENTION.canDeleteDatas",
		],
		readPermissionPath:
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.INTERVENTION.read",
		parentPath: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.SUIVI_CRISE,
		title: "Accès aux centres d'accueil d'une crise",
		path: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.CARE.read"],
		writePermissionPath: [
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.CARE.write",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.CARE.canDeleteDatas",
		],
		parentPath: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.SUIVI_CRISE,
		title: "Droit d'édition des centres d'accueil d'une crise",
		path: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.CARE.write"],
		readPermissionPath:
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.CARE.read",
		parentPath: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.SUIVI_CRISE,
		title: "Droit de suppression des centres d'accueil",
		path: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.CARE.canDeleteDatas"],
		readPermissionPath:
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.CARE.read",
		parentPath: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.SUIVI_CRISE,
		title: "Accès aux arrêtés municipaux d'une crise",
		path: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.ARRETE.read"],
		writePermissionPath: [
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.ARRETE.write",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.ARRETE.canDeleteDatas",
		],
		parentPath: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.SUIVI_CRISE,
		title: "Droit de création d'arrêtés municipaux pour une crise",
		path: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.ARRETE.write"],
		readPermissionPath:
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.ARRETE.read",
		parentPath: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.SUIVI_CRISE,
		title: "Droit de suppression des arrêtés municipaux",
		path: [
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.ARRETE.canDeleteDatas",
		],
		readPermissionPath:
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.ARRETE.read",
		parentPath: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.SUIVI_CRISE,
		title: "Accès aux données d'aide aux populations d'une crise",
		path: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.SUIVI_PCS.read"],
		writePermissionPath: [
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.SUIVI_PCS.write",
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.SUIVI_PCS.canDeleteDatas",
		],
		parentPath: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.SUIVI_CRISE,
		title: "Droit d'édition des données d'aide aux populations d'une crise",
		path: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.SUIVI_PCS.write"],
		readPermissionPath:
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.SUIVI_PCS.read",
		parentPath: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.SUIVI_CRISE,
		title: "Droit de suppression des données d'aide aux populations",
		path: [
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.SUIVI_PCS.canDeleteDatas",
		],
		readPermissionPath:
			"accessRights.tools.GESTION_CRISE.SUIVI_CRISE.SUIVI_PCS.read",
		parentPath: ["accessRights.tools.GESTION_CRISE.SUIVI_CRISE.read"],
	},
	{
		category: PERMISSION_CATEGORY.CUSTOM_DATA,
		title: "Accès aux données PCS/PICS",
		path: ["accessRights.tools.CUSTOMDATA.read"],
		childrenPaths: [
			"accessRights.tools.CUSTOMDATA.DASHBOARD.read",
			"accessRights.tools.CUSTOMDATA.DASHBOARD.write",
			"accessRights.tools.CUSTOMDATA.DONNEES_COMMUNALES.read",
			"accessRights.tools.CUSTOMDATA.ORGA.read",
			"accessRights.tools.CUSTOMDATA.ORGA.write",
			"accessRights.tools.CUSTOMDATA.FICHES.read",
			"accessRights.tools.CUSTOMDATA.FICHES.FICHES_ACTIONS.read",
			"accessRights.tools.CUSTOMDATA.FICHES.FICHES_ACTIONS.write",
			"accessRights.tools.CUSTOMDATA.FICHES.FICHES_SCENARIOS.read",
			"accessRights.tools.CUSTOMDATA.FICHES.FICHES_SCENARIOS.write",
			"accessRights.tools.CUSTOMDATA.CUSTOM_PCS.read",
			"accessRights.tools.CUSTOMDATA.CUSTOM_PCS.write",
			"accessRights.tools.CUSTOMDATA.ARRETES_TEMPLATES.read",
			"accessRights.tools.CUSTOMDATA.ARRETES_TEMPLATES.write",
			"accessRights.tools.CUSTOMDATA.MESSAGE_ALERTE_TEMPLATE.read",
			"accessRights.tools.CUSTOMDATA.MESSAGE_ALERTE_TEMPLATE.write",
			"accessRights.tools.JSONSCHEMAS.readAll",
			"accessRights.tools.JSONSCHEMAS.accessAll",
		],
	},
	{
		category: PERMISSION_CATEGORY.CUSTOM_DATA,
		title: "Accès au tableau de bord des données PCS/PICS",
		path: ["accessRights.tools.CUSTOMDATA.DASHBOARD.read"],
		writePermissionPath: ["accessRights.tools.CUSTOMDATA.DASHBOARD.write"],
		parentPath: ["accessRights.tools.CUSTOMDATA.read"],
	},
	{
		category: PERMISSION_CATEGORY.CUSTOM_DATA,
		title: "Droit d'édition du responsable des données PCS/PICS",
		path: ["accessRights.tools.CUSTOMDATA.DASHBOARD.write"],
		readPermissionPath: "accessRights.tools.CUSTOMDATA.DASHBOARD.read",
		parentPath: ["accessRights.tools.CUSTOMDATA.read"],
	},
	{
		category: PERMISSION_CATEGORY.CUSTOM_DATA,
		title: `Afficher la fonctionnalité "Données (inter)communales"`,
		path: ["accessRights.tools.CUSTOMDATA.DONNEES_COMMUNALES.read"],
		parentPath: ["accessRights.tools.CUSTOMDATA.read"],
	},
	{
		category: PERMISSION_CATEGORY.CUSTOM_DATA,
		title: "Accès à la cartographie des données PCS/PICS",
		path: ["accessRights.tools.CUSTOMDATA.CARTOGRAPHY.read"],
		writePermissionPath: ["accessRights.tools.CUSTOMDATA.CARTOGRAPHY.write"],
	},
	{
		category: PERMISSION_CATEGORY.CUSTOM_DATA,
		title: "Droit d'édition sur la cartographie des données PCS/PICS",
		path: ["accessRights.tools.CUSTOMDATA.CARTOGRAPHY.write"],
		readPermissionPath: "accessRights.tools.CUSTOMDATA.CARTOGRAPHY.read",
	},
	{
		category: PERMISSION_CATEGORY.CUSTOM_DATA,
		title: "Accès à l'organigramme",
		path: ["accessRights.tools.CUSTOMDATA.ORGA.read"],
		writePermissionPath: ["accessRights.tools.CUSTOMDATA.ORGA.write"],
		parentPath: ["accessRights.tools.CUSTOMDATA.read"],
	},
	{
		category: PERMISSION_CATEGORY.CUSTOM_DATA,
		title: "Droit d'édition de l'organigramme",
		path: ["accessRights.tools.CUSTOMDATA.ORGA.write"],
		readPermissionPath: "accessRights.tools.CUSTOMDATA.ORGA.read",
		parentPath: ["accessRights.tools.CUSTOMDATA.read"],
	},
	{
		category: PERMISSION_CATEGORY.CUSTOM_DATA,
		title: "Accès aux fiches scénarios et actions",
		path: ["accessRights.tools.CUSTOMDATA.FICHES.read"],
		parentPath: ["accessRights.tools.CUSTOMDATA.read"],
	},
	{
		category: PERMISSION_CATEGORY.CUSTOM_DATA,
		title: "Accès aux fiches actions seulement",
		path: ["accessRights.tools.CUSTOMDATA.FICHES.FICHES_ACTIONS.read"],
		writePermissionPath: [
			"accessRights.tools.CUSTOMDATA.FICHES.FICHES_ACTIONS.write",
		],
		parentPath: ["accessRights.tools.CUSTOMDATA.read"],
	},
	{
		category: PERMISSION_CATEGORY.CUSTOM_DATA,
		title: "Droit d'édition des fiches actions",
		path: ["accessRights.tools.CUSTOMDATA.FICHES.FICHES_ACTIONS.write"],
		readPermissionPath:
			"accessRights.tools.CUSTOMDATA.FICHES.FICHES_ACTIONS.read",
		parentPath: ["accessRights.tools.CUSTOMDATA.read"],
	},
	{
		category: PERMISSION_CATEGORY.CUSTOM_DATA,
		title: "Accès aux fiches scénarios seulement",
		path: ["accessRights.tools.CUSTOMDATA.FICHES.FICHES_SCENARIOS.read"],
		writePermissionPath: [
			"accessRights.tools.CUSTOMDATA.FICHES.FICHES_SCENARIOS.write",
		],
		parentPath: ["accessRights.tools.CUSTOMDATA.read"],
	},
	{
		category: PERMISSION_CATEGORY.CUSTOM_DATA,
		title: "Droit d'édition des fiches scénarios",
		path: ["accessRights.tools.CUSTOMDATA.FICHES.FICHES_SCENARIOS.write"],
		readPermissionPath:
			"accessRights.tools.CUSTOMDATA.FICHES.FICHES_SCENARIOS.read",
		parentPath: ["accessRights.tools.CUSTOMDATA.read"],
	},
	{
		category: PERMISSION_CATEGORY.CUSTOM_DATA,
		title: "Accès au mot du maire/président pour le PCS/PICS",
		path: [
			"accessRights.tools.CUSTOMDATA.CUSTOM_PCS.read",
			"accessRights.tools.CUSTOMDATA.CUSTOM_PCS.write",
		],
		parentPath: ["accessRights.tools.CUSTOMDATA.read"],
	},
	{
		category: PERMISSION_CATEGORY.CUSTOM_DATA,
		title: "Accès aux modèles des arrêtés municipaux",
		path: ["accessRights.tools.CUSTOMDATA.ARRETES_TEMPLATES.read"],
		writePermissionPath: [
			"accessRights.tools.CUSTOMDATA.ARRETES_TEMPLATES.write",
		],
		parentPath: ["accessRights.tools.CUSTOMDATA.read"],
	},
	{
		category: PERMISSION_CATEGORY.CUSTOM_DATA,
		title: "Droit d'édition des modèles des arrêtés municipaux",
		path: ["accessRights.tools.CUSTOMDATA.ARRETES_TEMPLATES.write"],
		readPermissionPath: "accessRights.tools.CUSTOMDATA.ARRETES_TEMPLATES.read",
		parentPath: ["accessRights.tools.CUSTOMDATA.read"],
	},
	{
		category: PERMISSION_CATEGORY.CUSTOM_DATA,
		title: "Accès à l'enregistrement de messages d'alerte",
		path: [
			"accessRights.tools.CUSTOMDATA.MESSAGE_ALERTE_TEMPLATE.read",
			"accessRights.tools.CUSTOMDATA.MESSAGE_ALERTE_TEMPLATE.write",
		],
		parentPath: ["accessRights.tools.CUSTOMDATA.read"],
	},
	{
		category: PERMISSION_CATEGORY.COOPERATION,
		title: "Accès à l'outil de coopération",
		path: [
			"accessRights.tools.COOPERATION.read",
			"accessRights.tools.COOPERATION.write",
		],
	},
	{
		category: PERMISSION_CATEGORY.DOCUMENTS,
		title:
			"Accès aux fonctionnalités de téléchargement et téléversement de documents",
		path: ["accessRights.tools.DOCUMENTS.read"],
		childrenPaths: [
			"accessRights.tools.DOCUMENTS.DOWNLOAD_PCS.read",
			"accessRights.tools.DOCUMENTS.DOWNLOAD_PCS.write",
			"accessRights.tools.DOCUMENTS.UPLOAD.read",
			"accessRights.tools.DOCUMENTS.UPLOAD.write",
		],
	},
	{
		category: PERMISSION_CATEGORY.DOCUMENTS,
		title: "Accès au téléchargement du PCS/PICS",
		path: ["accessRights.tools.DOCUMENTS.DOWNLOAD_PCS.read"],
		writePermissionPath: ["accessRights.tools.DOCUMENTS.DOWNLOAD_PCS.write"],
		parentPath: ["accessRights.tools.DOCUMENTS.read"],
	},
	{
		category: PERMISSION_CATEGORY.DOCUMENTS,
		title: "Droit d'édition des kits PCS/PICS",
		path: ["accessRights.tools.DOCUMENTS.DOWNLOAD_PCS.write"],
		readPermissionPath: "accessRights.tools.DOCUMENTS.DOWNLOAD_PCS.read",
		parentPath: ["accessRights.tools.DOCUMENTS.read"],
	},
	{
		category: PERMISSION_CATEGORY.DOCUMENTS,
		title: "Accès au téléversement de documents",
		path: ["accessRights.tools.DOCUMENTS.UPLOAD.read"],
		writePermissionPath: ["accessRights.tools.DOCUMENTS.UPLOAD.write"],
		parentPath: ["accessRights.tools.DOCUMENTS.read"],
	},
	{
		category: PERMISSION_CATEGORY.DOCUMENTS,
		title: "Droit d'édition des documents téléversés",
		path: ["accessRights.tools.DOCUMENTS.UPLOAD.write"],
		readPermissionPath: "accessRights.tools.DOCUMENTS.UPLOAD.read",
		parentPath: ["accessRights.tools.DOCUMENTS.read"],
	},
	{
		category: PERMISSION_CATEGORY.JSONSCHEMAS,
		title: "Accès aux formulaires de données",
		path: ["accessRights.tools.JSONSCHEMAS.readAll"],
		writePermissionPath: ["accessRights.tools.JSONSCHEMAS.accessAll"],
		parentPath: ["accessRights.tools.CUSTOMDATA.read"],
	},
	{
		category: PERMISSION_CATEGORY.JSONSCHEMAS,
		title: "Droit d'édition sur tout les formulaires de données",
		path: ["accessRights.tools.JSONSCHEMAS.accessAll"],
		readPermissionPath: "accessRights.tools.JSONSCHEMAS.readAll",
		parentPath: ["accessRights.tools.CUSTOMDATA.read"],
	},
]

export default configPermissionsAdmin
