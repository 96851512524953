import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import {
	Collapse,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Switch,
} from "@mui/material"
import _ from "lodash"
import React, { useState } from "react"
import configPermissionsAdmin from "./configPermissionsAdmin"
import styled from "styled-components"

const SList = styled(List)`
    border-left: 2px solid #212529;
    margin-left: 1.7rem !important;
`

const SListItemText = styled(ListItem)`
    margin-left: -0.25rem !important;
`

const SSwitchListItemText = styled(ListItemText)`
    margin-left: 0.5rem !important;
`

const CollapsePermissionsList = ({
	buttonText,
	category,
	handleChange,
	currentSettings,
	ListButtonIcon,
}) => {
	const [open, setOpen] = useState(false)
	const handleClick = () => {
		setOpen(!open)
	}

	return (
		<React.Fragment>
			<ListItemButton onClick={handleClick}>
				<ListButtonIcon />
				<SListItemText>{buttonText}</SListItemText>
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItemButton>
			<Collapse in={open} timeout="auto">
				<SList disablePadding>
					{configPermissionsAdmin
						.filter((permission) => permission.category === category)
						.map((permission) => {
							const isChecked = permission.path.every((permissionPath) => {
								return _.get(currentSettings, permissionPath, false)
							})
							const parentPath = permission.parentPath ?? []
							const isDisabled = !parentPath.every((parentPath) => {
								return _.get(currentSettings, parentPath, true)
							})

							return (
								<React.Fragment key={permission.title}>
									<ListItem>
										<SSwitchListItemText>
											{permission.title}
										</SSwitchListItemText>
										<Switch
											checked={isChecked}
											disabled={isDisabled}
											edge="end"
											onChange={() => handleChange(permission)}
										/>
									</ListItem>
								</React.Fragment>
							)
						})}
				</SList>
			</Collapse>
		</React.Fragment>
	)
}

export default CollapsePermissionsList
