import { yupResolver } from "@hookform/resolvers/yup"
import { Grid, InputLabel, Typography } from "@mui/material"
import {
	createAdminMultiPolygon,
	updateAdminMultiPolygons,
} from "@/redux-toolkit/admin/multiPolygons/resources"
import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { connect } from "react-redux"
import styled from "styled-components"
import { Flex } from "utils/components/style/flex"
import FMenuTreeLocation from "utils/form/admin/FMenuTreeLocation"
import FCheckboxes from "utils/form/FCheckboxes"
import FColor from "utils/form/FColor"
import FSelect from "utils/form/FSelect"
import FSelectCommune from "utils/form/FSelectCommune"
import FSwitch from "utils/form/FSwitch"
import FText from "utils/form/FText"
import FUploadAdmin from "utils/form/upload/FUploadAdmin"
import SuperModal from "utils/modals/SuperModal"
import Yup from "utils/yup"

const SGrid = styled(Grid)`
    padding: 1em;
`

const schema = Yup.object().shape({
	id: Yup.string(),
	nom: Yup.string().required(),
	file: Yup.mixed().when("id", (id, sch) => {
		return !id ? sch.required() : sch
	}),
	mainCategory: Yup.string().required(),
	subCategory: Yup.string().required(),
	//Stroke
	stroke: Yup.array(Yup.string()).ensure(),
	weight: Yup.string().required(),
	opacity: Yup.string().required(),
	//Fill
	fill: Yup.array(Yup.string()).ensure(),
	fillOpacity: Yup.string().required(),
	//Colors
	color: Yup.string().required(),
	fillColor: Yup.string().required(),
	//Others
	priority: Yup.string().nullable().required(),
	communes: Yup.array(Yup.string()),
	secteur: Yup.boolean().default(false),
	details: Yup.string().nullable(),
	forceIndex: Yup.number().nullable(),
})

const priorityOptions = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]

const ModalMultiPolygon = ({
	isOpen,
	onClose,
	updateAdminMultiPolygons,
	createAdminMultiPolygon,
	initialValues,
	defaultValues,
}) => {
	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues,
	})

	const onSubmit = (values) => {
		if (initialValues?.id) {
			updateAdminMultiPolygons(values)
			onClose()
			return
		}
		createAdminMultiPolygon(values)
		onClose()
	}

	useEffect(() => {
		methods.reset({
			...defaultValues,
			...initialValues,
		})
	}, [initialValues])

	return (
		<FormProvider {...methods}>
			<SuperModal
				onClick={methods.handleSubmit(onSubmit)}
				isOpen={isOpen}
				onClose={onClose}
				isNew={!initialValues?.id}
				title={
					initialValues?.id
						? "Modifier le MultiPolygon"
						: "Ajouter un MultiPolygon"
				}
			>
				<Flex directionColumn gap="1rem">
					<Grid container>
						<SGrid item sm={12}>
							<InputLabel>Options</InputLabel>
							<a href="https://leafletjs.com/reference-1.7.1.html#path-option">
								Voir le détail des options
							</a>
						</SGrid>
					</Grid>
					{!initialValues?.id && <FUploadAdmin name="file" label="Geojson" />}

					<FText name="nom" label="Nom de la couche" />

					<FMenuTreeLocation mainName="mainCategory" subName="subCategory" />

					<FSelect name="priority" label="Priority" options={priorityOptions} />
					<FText name="forceIndex" label="Force Index" />
					<FSelectCommune label="Communes" name="communes" />
					<FText name="details" label="Note" multiline />

					{/* === Bordure ===*/}
					<Typography variant="h6" component="div">
						Bordure
					</Typography>

					<FCheckboxes name="stroke" options={["stroke"]} />

					<FText name="weight" label="weight (épaisseur des traits)" />
					<FText name="opacity" label="opacity (opacité)" />
					<FColor name="color" label="Color" />

					{/* === Remplissage ===*/}
					<Typography variant="h6" component="div">
						Remplissage
					</Typography>

					<FCheckboxes name="fill" options={["fill"]} />
					<FText name="fillOpacity" label="fillOpacity" />

					<FColor name="fillColor" label="Fill Color" />
					<Typography variant="h6" component="div">
						Secteur
					</Typography>
					<FSwitch name="secteur" label="Secteur" />
				</Flex>
			</SuperModal>
		</FormProvider>
	)
}

const mapStateToProps = (state) => ({
	communes: state.admin?.users?.communes,
})

const mapDispatchToProps = (dispatch) => ({
	updateAdminMultiPolygons: (multiPolygons) =>
		dispatch(updateAdminMultiPolygons(multiPolygons)),
	createAdminMultiPolygon: (multiPolygons) =>
		dispatch(createAdminMultiPolygon(multiPolygons)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalMultiPolygon)
