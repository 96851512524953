import { createAsyncThunk } from "@reduxjs/toolkit"
import ErrorService from "@/services/ErrorService"
import MultiPolygonsApi from "./MultiPolygonsApi"
import { removeMultiPolygon, setMultipolygons, updateMultiPolygon } from "./reducer"

export const getAdminMultiPolygonGeojson = async (id: string, dispatch: any) => {
	try {
		const res = await MultiPolygonsApi.getAdminMultiPolygonGeojson(id)
		return JSON.stringify(res.data)
	} catch (error) {
		const errorMessage = `Erreur, le téléchargement du geojson à échoué, message d'erreur: ${error}`
		ErrorService.error({
			component: "resources:getAdminMultiPolygonGeojson",
			message: errorMessage,
			dispatch,
		})
	}
}

export const getAdminMultiPolygons = createAsyncThunk(
	"admin/multiPolygon/getAdminMultiPolygons",
	async (args, { dispatch }) => {
		try {
			dispatch(setMultipolygons({}))
			await MultiPolygonsApi.getAdminMultiPolygons(dispatch)
		} catch (error) {
			const errorMessage = `Erreur, les multiPolygons n'ont pas pu être récupéré, message d'erreur: ${error}`
			ErrorService.error({
				component: "resources:getAdminMultiPolygons",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const deleteAdminMultiPolygons = createAsyncThunk(
	"admin/multiPolygon/deleteAdminMultiPolygons",
	async (id: string, { dispatch }) => {
		try {
			await MultiPolygonsApi.deleteAdminMultiPolygons(id)
			dispatch(removeMultiPolygon(id))
		} catch (error) {
			const errorMessage = `Erreur, le multiPolygon n'a pas pu être supprimé, message d'erreur: ${error}`
			ErrorService.error({
				component: "resources:deleteAdminMultiPolygons",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const updateAdminMultiPolygons = createAsyncThunk(
	"admin/multiPolygon/updateAdminMultiPolygons",
	async (multiPolygon: any, { dispatch }) => {
		try {
			await MultiPolygonsApi.updateAdminMultiPolygons(multiPolygon)
			dispatch(updateMultiPolygon(multiPolygon))
		} catch (error) {
			const errorMessage = `Erreur, le multiPolygon n'a pas pu être mis à jour`
			ErrorService.error({
				component: "resources:updateAdminMultiPolygons",
				message: errorMessage,
				dispatch,
			})
		}
	},
)

export const createAdminMultiPolygon = createAsyncThunk(
	"admin/multiPolygon/createAdminMultiPolygon",
	async (multiPolygon: any, { dispatch }) => {
		try {
			await MultiPolygonsApi.createAdminMultiPolygon(multiPolygon)
			const { multiPolygons } = await MultiPolygonsApi.getAdminMultiPolygons()
			dispatch(setMultipolygons(multiPolygons))
		} catch (error) {
			const errorMessage = `Erreur, le multiPolygon n'a pas pu être créé : ${error.message}`
			ErrorService.error({
				component: "resources:createAdminMultiPolygon",
				message: errorMessage,
				dispatch,
			})
		}
	},
)
