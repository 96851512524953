import React from "react"
import Debug from "debug"
import _ from "lodash"
import { useController, useFormContext } from "react-hook-form"
import getImage from "@/utils/getImage"
import { SImage } from "./FImageMarkerPicker.styled"
import ButtonPopover from "@/pages/maincourante/subComponents/ButtonPopover"
import FImageMarkerPickerPopover from "./FImageMarkerPickerPopover"

// app:javascript:utils:form:admin:FImageMarkerPicker:FImageMarkerPicker.tsx
const debug = Debug(
	"app:javascript:utils:form:admin:FImageMarkerPicker:FImageMarkerPicker",
)
debug.log = console.log.bind(console)

interface Props {
	name: string
	disabled?: boolean
}

const FImageMarkerPicker = ({ name, disabled = false }: Props) => {
	const { control } = useFormContext()

	const {
		field: { value, onChange },
	} = useController({
		name,
		control,
		rules: { required: true },
		defaultValue: "",
	})

	return (
		<ButtonPopover
			popoverContent={({ handleClose }) => (
				<FImageMarkerPickerPopover
					handleClose={handleClose}
					onChange={onChange}
					value={value}
				/>
			)}
		>
			{({ handleClick }) => (
				<SImage src={getImage(value)} width={45} onClick={disabled ? null : handleClick} />
			)}
		</ButtonPopover>
	)
}

export default FImageMarkerPicker
