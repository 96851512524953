import styled from "styled-components"

export const SImage = styled.img`
    padding: 0.3rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 1rem;
    width: 60px;
    cursor: pointer;
`
