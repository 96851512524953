import { FormControlLabel, Radio, RadioGroup } from "@mui/material"
import React from "react"
import { useController, useFormContext } from "react-hook-form"
import styled from "styled-components"
import { Flex } from "utils/components/style/flex"
import { Neutral350Text, Small } from "utils/components/style/text"
import StyledInputLayout from "./formUtils/StyledInputLayout"

const SFlex = styled(Flex)`
    margin-left: 1rem;
    margin-top: 0.75rem;
`

const FBoolean = ({
	name,
	label = undefined,
	trueLabel = "Oui",
	falseLabel = "Non",
}) => {
	const {
		control,
		formState: { errors },
	} = useFormContext()

	const {
		field: { ref, value, onChange, ...inputProps },
		fieldState: { invalid, isTouched, isDirty, error },
		formState: { touchedFields, dirtyFields },
	} = useController({
		name,
		control,
		rules: { required: true },
		defaultValue: "",
	})

	return (
		<>
			<StyledInputLayout
				errors={errors}
				value={value}
				name={name}
				label={label}
				isExternalShrinked
			>
				<RadioGroup
					value={value ? "true" : "false"}
					onChange={(ev, newValue) =>
						newValue === "true" ? onChange(true) : onChange(false)
					}
				>
					<SFlex>
						<FormControlLabel
							value={"true"}
							control={<Radio />}
							label={trueLabel}
						/>
						<FormControlLabel
							value={"false"}
							control={<Radio />}
							label={falseLabel}
						/>
					</SFlex>
				</RadioGroup>
			</StyledInputLayout>
		</>
	)
}

export default FBoolean
