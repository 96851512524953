import useJsonSchemasWithStatics, {
	buildMappingProperty,
} from "@/hooks/useJsonSchemasWithStatics"
import useMappings from "@/hooks/useMappings"
import useWhyDidYouUpdate from "@/hooks/useWhyDidYouUpdate"
import {
	createMapping,
	updateMapping,
} from "@/redux-toolkit/data/mappings/ressources"
import SaveButton from "@/styles/atoms/Button/specialized/SaveButton"
import Modal from "@/styles/organisms/Modal"
import ModalBody from "@/styles/organisms/Modal/ModalBody"
import ModalFooter from "@/styles/organisms/Modal/ModalFooter"
import ModalHeader from "@/styles/organisms/Modal/ModalHeader"
import { PrimaryTextButton } from "@/utils/components/style/button"
import { Flex } from "@/utils/components/style/flex"
import FAdminCommunes from "@/utils/form/FAdminCommunes"
import FAdminJsonSchemas from "@/utils/form/FAdminJsonSchemas"
import FFreeTags from "@/utils/form/FFreeTags/FFreeTags"
import FText from "@/utils/form/FText"
import { FormDependencies } from "@/utils/form/FormDependencies"
import { FormWarning } from "@/utils/form/FormWarning"
import IMapping from "@/utils/types/IMapping/IMapping"
import IMappingSchema from "@/utils/types/IMapping/IMappingSchema"
import IMappingSchemaItem from "@/utils/types/IMapping/IMappingSchemaItem"
import Yup from "@/utils/yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Button } from "@mui/material"
import { ModalContent } from "@nextui-org/react"
import React, { useEffect, useMemo, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { v4 as uuidv4 } from "uuid"
import { ObjectSchema } from "yup"
import FMapping from "./FMapping"
import CsvExampleLoader from "./components/CsvExampleLoader"
import { IRow } from "./core/MappingSchemaRunner"
import { mappingToolInstanceValidator } from "./mappingTools/mappingTools"
import { MAPPING_TOOL_KEY } from "./mappingTools/text/key"
import CancelButton from "@/styles/atoms/Button/specialized/CancelButton"

const MappingFormModal = ({ open, onClose, id }) => {
	const jsonSchemas = useJsonSchemasWithStatics(false)

	const schema = useMemo(
		() =>
			Yup.object().shape({
				id: Yup.string()
					.required()
					.default(() => uuidv4()),
				fields: Yup.array(Yup.string()).min(1).default([]),
				commune_id: Yup.string().required().nullable().default(null),
				jsonschema_id: Yup.string().nullable().default(null),
				add_property: Yup.string()
					.default("")
					.transform((v) => (!v ? undefined : v)),
				schema: Yup.object()
					.when(
						["jsonschema_id", "add_property"],
						//@ts-ignore
						(
							jsonSchemaId: string,
							addProperty: string,
							schema: ObjectSchema<never>,
						) => {
							const properties = jsonSchemas[jsonSchemaId]?.template?.properties
							if (!properties) return schema

							const propertiesWithAdded = addProperty
								? [buildMappingProperty(addProperty), ...properties]
								: properties

							return schema
								.shape(
									Object.fromEntries(
										propertiesWithAdded.map((prop) => [
											prop.name,
											prop.isRequired
												? mappingToolInstanceValidator.required()
												: mappingToolInstanceValidator,
										]),
									),
								)
								.noUnknown()
								.required()
						},
					)
					.test(
						"has-mapping-tool-key",
						"Une clé de mise en relation est nécessaire",
						(value) =>
							Object.values(value).some(
								(mpi: IMappingSchemaItem | undefined) =>
									mpi?.converter === MAPPING_TOOL_KEY,
							),
					),
			}),
		[jsonSchemas],
	)

	const methods = useForm<IMapping>({ resolver: yupResolver(schema) })
	const dispatch = useDispatch()
	const mappings = useMappings()

	const onSubmit = (values) => {
		if (id) {
			dispatch(updateMapping(values))
		} else {
			dispatch(createMapping(values))
		}
		onClose()
	}

	const [example, setExample] = useState<IRow[]>(undefined)

	useEffect(() => {
		if (open && !example) {
			if (id) methods.reset(schema.cast(mappings[id]))
			else methods.reset(schema.getDefault())
			setExample(undefined)
		}
	}, [open, example])

	return (
		<Modal size="2xl" isOpen={open} onClose={onClose}>
			<ModalContent>
				<ModalHeader>
					{id ? "Editer un mapping" : "Créer un mapping"}
				</ModalHeader>
				<ModalBody>
					<FormProvider {...methods}>
						<Flex directionColumn gap alignItemsStretch>
							<FAdminCommunes
								name="commune_id"
								label="Commune"
								disabled={Boolean(id)}
							/>
							<FormDependencies targets={["commune_id"]}>
								{({ commune_id }) =>
									commune_id ? (
										<FAdminJsonSchemas
											name="jsonschema_id"
											label="Formulaire"
											commune_id={commune_id}
											disabled={Boolean(id)}
										/>
									) : null
								}
							</FormDependencies>

							<FormWarning
								targets={["commune_id", "jsonschema_id"]}
								condition={(communeId, jsonSchemaId) =>
									communeId &&
									jsonSchemaId &&
									!(jsonSchemas[jsonSchemaId]?.communes
										? Object.keys(
												jsonSchemas[jsonSchemaId]?.communes,
										  )?.includes(communeId)
										: true)
								}
							>
								Ce formulaire n&apos;est pas associé à la commune
							</FormWarning>
							<FText
								name="add_property"
								label="Ajouter une propriété au formulaire"
								disabled={Boolean(id)}
							/>
							<FormDependencies targets={["schema"]}>
								{({ schema }) => {
									const highlight = Object.values(
										(schema as IMappingSchema) ?? {},
									).reduce(
										(acc, val) => [
											...acc,
											...Object.values(val?.singleInputs ?? {}),
											...Object.values(val?.arrayInputs ?? {}).reduce(
												(a, v) => [...a, ...v],
												[],
											),
										],
										[],
									)

									return (
										<FFreeTags
											name="fields"
											label="Champs"
											highlight={highlight}
										/>
									)
								}}
							</FormDependencies>

							{example ? (
								<Alert
									severity="success"
									action={
										<PrimaryTextButton
											onClick={() => setExample(undefined)}
											color="inherit"
											size="small"
										>
											Supprimer
										</PrimaryTextButton>
									}
								>
									Un fichier de test est chargé. Il contient {example.length}{" "}
									données.
								</Alert>
							) : (
								<CsvExampleLoader setExample={setExample} name="fields" />
							)}
							<FormDependencies
								targets={["jsonschema_id", "fields", "add_property"]}
								memo={[example]}
							>
								{({ add_property, fields, jsonschema_id }) => {
									const properties =
										jsonSchemas[jsonschema_id]?.template.properties

									if (!properties) return null

									const propertiesWithAdded = add_property
										? [buildMappingProperty(add_property), ...properties]
										: properties

									return (
										<FMapping
											example={example}
											name="schema"
											label="Schema"
											properties={propertiesWithAdded}
											fields={fields}
										/>
									)
								}}
							</FormDependencies>
						</Flex>
					</FormProvider>
				</ModalBody>
				<ModalFooter>
					<CancelButton onClick={onClose} />
					<SaveButton onClick={methods.handleSubmit(onSubmit)} />
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default MappingFormModal
