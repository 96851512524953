import { Chip } from "@mui/material"
import { ColumnDef } from "@tanstack/react-table"
import {
	getAdminMultiPolygonGeojson,
	getAdminMultiPolygons,
	deleteAdminMultiPolygons,
} from "@/redux-toolkit/admin/multiPolygons/resources"
import _ from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import AddBar from "utils/components/AddBar"
import { GreenChip } from "utils/components/style/chip"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"
import formatNfdLowerCase from "utils/formatNfdLowerCase"
import { RISQUES } from "utils/menuTab"
import ModalMultiPolygon from "./ModalMultiPolygon"
import CustomTooltip from "utils/components/style/CustomTooltip"
import { RowIconButton } from "utils/components/tables/SuperReactTable/SuperReactTable.styled"
import GetApp from "@mui/icons-material/GetApp"
import { width30 } from "utils/components/tables/widthProps"
import useAdminMultiPolygons from "@/hooks/admin/useAdminMultiPolygons"
import useAdminCommunes from "@/hooks/admin/useAdminCommunes"
import useAdminCdcs from "@/hooks/admin/useAdminCdcs"
import { FormProvider, useForm } from "react-hook-form"
import FSelectCommune from "utils/form/FSelectCommune"
import useAdminRestrictions from "@/hooks/useAdminRestrictions"
import AdminTopBar from "../AdminTopBar"
import Button from "@/styles/atoms/Button"

const INITIAL_MULTIPOLYGON_VALUES = {
	nom: "",
	subCategory: "",
	mainCategory: RISQUES,
	priority: "10",
	stroke: ["stroke"],
	fill: ["fill"],
	weight: "3",
	opacity: "1.0",
	color: "#ffffff",
	fillColor: "#ffffff",
	fillOpacity: "0.2",
	communes: [],
	secteur: false,
	details: "",
	forceIndex: null,
} as any

const AdminMultiPolygons = () => {
	const multiPolygons = useAdminMultiPolygons()
	const communes = useAdminCommunes()
	const cdcs = useAdminCdcs()
	const dispatch = useDispatch()
	const adminRestrictions = useAdminRestrictions()

	const [isOpenModalMultiPolygons, setIsOpenModalMultiPolygons] =
		useState(false)
	const [initialMultiPolygonValues, setInitialMultiPolygonValues] = useState(
		INITIAL_MULTIPOLYGON_VALUES,
	)

	const CustomFilter = ({ setGlobalFilter, state }) => {
		const methods = useForm({
			defaultValues: {
				commune: "",
			},
		})
		const commune = methods.watch("commune")

		useEffect(() => {
			setGlobalFilter(commune)
		}, [commune])
		return (
			<FormProvider {...methods}>
				<FSelectCommune
					label="Filtrer par Commune"
					name="commune"
					multiple={false}
				/>
			</FormProvider>
		)
	}

	const columns = useMemo(() => {
		return [
			{
				header: "Nom",
				accessorKey: "name",
			},
			{
				header: "Communes",
				accessorKey: "commune_ids",
				filterFn: (row, columnId, value) => {
					const rowValue = communes?.[row.original.commune_ids]?.name
					return !formatNfdLowerCase(rowValue).includes(
						formatNfdLowerCase(value),
					)
				},
				meta: {
					canBeFiltered: true,
					Filter: CustomFilter,
				},
				cell: ({ getValue }) => (
					<>
						<ul>
							{(getValue() ?? []).map((commune_id) => (
								<li key={commune_id}>{communes?.[commune_id]?.name}</li>
							))}
						</ul>
					</>
				),
			},
			{
				header: "Secteur",
				accessorKey: "custom_props.secteur",
				cell: ({ getValue }) => (
					<>{getValue() ? <GreenChip label="oui" /> : <Chip label="non" />}</>
				),
			},
			{
				header: "Cdcs",
				accessorKey: "cdc_ids",
				cell: ({ getValue }) => (
					<>
						<ul>
							{(getValue() ?? []).map((cdc_id) => (
								<li key={cdc_id}>{cdcs?.[cdc_id]?.name}</li>
							))}
						</ul>
					</>
				),
			},
			{
				header: "",
				accessorKey: "download",
				cell: ({ row }) => (
					<CustomTooltip title="Télécharger">
						<RowIconButton
							onClick={async () => {
								const geojson = await getAdminMultiPolygonGeojson(
									row.original.id,
									dispatch,
								)
								const filename = row.original.name
								const file = new File([geojson], filename)
								window.saveAs(file, filename + ".geojson")
							}}
						>
							<GetApp />
						</RowIconButton>
					</CustomTooltip>
				),
				enableSorting: false,
				...width30,
			},
		] as ColumnDef<any, any>[]
	}, [multiPolygons])

	useEffect(() => {
		dispatch(getAdminMultiPolygons())
	}, [])

	const data = useMemo(() =>
		adminRestrictions.isRestricted
			? Object.values(multiPolygons).filter((mp) => !!mp.commune_ids.filter((comm_id) =>
				_.includes(adminRestrictions.allowedCommunes, comm_id)
			).length)
			: Object.values(multiPolygons)
		, [multiPolygons])

	if (_.isEmpty(multiPolygons)) {
		return null
	}

	return (
		<div>
			<AdminTopBar title="Gestionnaire des MultiPolygons">
				<Button $backgroundColor="var(--green500)" $textColor="white" onClick={() => {
					setInitialMultiPolygonValues(INITIAL_MULTIPOLYGON_VALUES)
					setIsOpenModalMultiPolygons(true)
				}}>
					Ajouter un MultiPolygon
				</Button>
			</AdminTopBar>
			<SuperReactTable
				data={data}
				columns={columns}
				onEditClick={(original: any) => {
					setInitialMultiPolygonValues({
						priority: original?.custom_props?.priority ?? 10,
						stroke: _.isEqual(original?.custom_props?.style?.stroke, false)
							? []
							: ["stroke"],
						fill: _.isEqual(original?.custom_props?.style?.fill, false)
							? []
							: ["fill"],
						weight: original?.custom_props?.style?.weight ?? 3,
						opacity: original?.custom_props?.style?.opacity ?? "1.0",
						color: original?.custom_props?.style?.color,
						fillColor:
							original?.custom_props?.style?.fillColor ??
							original?.custom_props?.style?.color,
						fillOpacity: original?.custom_props?.style?.fillOpacity ?? "0.2",
						nom: original?.name,
						id: original?.id,
						subCategory: original?.custom_props?.path?.subCategory,
						mainCategory: original?.custom_props?.path?.mainCategory,
						communes: original?.commune_ids,
						secteur: original?.custom_props?.secteur ?? false,
						details: original?.custom_props?.details ?? "",
						forceIndex: original?.forceIndex ?? null,
					})
					setIsOpenModalMultiPolygons(true)
				}}
				onDeleteClick={(original: any) => {
					dispatch(deleteAdminMultiPolygons(original.id))
				}}
			/>
			<ModalMultiPolygon
				isOpen={isOpenModalMultiPolygons}
				onClose={() => {
					setIsOpenModalMultiPolygons(!isOpenModalMultiPolygons)
				}}
				initialValues={initialMultiPolygonValues}
				defaultValues={INITIAL_MULTIPOLYGON_VALUES}
			/>
		</div>
	)
}

export default AdminMultiPolygons
