import useJsonSchemasWithStatics from "@/hooks/useJsonSchemasWithStatics"
import _ from "lodash"
import React, { useMemo } from "react"
import SuperReactTable from "utils/components/tables/SuperReactTable/SuperReactTable"

const BlacklistTable = ({ currentBlacklist }) => {
	const jsonSchemas = useJsonSchemasWithStatics()

	const blacklist = useMemo(() => {
		if (!currentBlacklist) {
			return null
		}
		return currentBlacklist
			.map((entry) => ({
				name: jsonSchemas[entry]?.title ?? "notFound",
				id: entry,
			}))
			.filter((entry) => entry.name !== "notFound")
	}, [currentBlacklist, jsonSchemas])

	const columns = useMemo(
		() => [
			{
				header: "ID du fomulaire",
				accessorKey: "id",
			},
			{
				header: "Nom du formulaire",
				accessorKey: "name",
			},
		],
		[],
	)

	return (
		<SuperReactTable
			data={blacklist}
			columns={columns}
			isEditable={false}
			isDeletable={false}
			selectable={false}
		/>
	)
}

export default BlacklistTable
